import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { configStepAdapter } from "./adapters/ConfigStepAdapter";
import { LabelsParams } from "./adapters/LabelsParamsAdapter";
import { workflow } from "./models/Workflow";
import { isArray, isFunction, minBy, uniqBy } from "lodash";
import { Parameter } from "./models/Parameter";
import { insuranceActions } from "../redux/slices/insurance";
import { frameToInsuranceFrameAdapter } from "./adapters/FrameToInsuranceFrameAdapter";
import { configActions } from "../redux/slices/config";
import { modalsActions } from "../redux/slices/modals";
import { ParameterToParameterWithTitleAdapter } from "./adapters/ParameterToParameterWithTitleAdapter";
import { StepBadgeType } from "../components/StepBadge/types/StepBadgeType";
import { useCurrentPackages } from "./hooks/useCurrentPackages";
import { FullPrescription } from "src/types";
import { Step } from "./models/Step";
import { labelsParamsAdapters } from "../configurator/adapters/LabelsParamsAdapter";
import { workflowActions } from "../redux/slices/workflow";
export const STEP_PARAM_NAME = "rxcnav";

// const configPackages = useSelector(
//   (state: any) => state.config?.lensesData?.packages
// );
// const configData = useSelector((state: any) => state.config?.data);

export const cleanRule = (lensPackage: any) =>
  (!lensPackage.color || !lensPackage.color.length) &&
  (!lensPackage.blueLight || !lensPackage.blueLight.length) &&
  (!lensPackage.transition || !lensPackage.transition.length) &&
  (!lensPackage.colorCategory || !lensPackage.colorCategory.length);

export const blueLightRule = (lensPackage: any) =>
  lensPackage.blueLight &&
  lensPackage.blueLight.length &&
  (!lensPackage.transition || !lensPackage.transition.length);

export const transitionRule = (lensPackage: any) =>
  lensPackage.transition && lensPackage.transition.length;

export const sunRule = (lensPackage: any) =>
  lensPackage?.color &&
  lensPackage?.color.length &&
  (!lensPackage.transition || !lensPackage.transition.length);

const isFrameOnlySelected = (configPackages, configData) => {
  let configFrameOnly = getFrameOnlyKey(configPackages, configData);
  let typeSelection = workflow
    .getAllCurrentParameterStep()
    .find((par) => par.key === "type")?.value;
  return typeSelection === configFrameOnly;
};
export const getCorrectKey = (
  key: string,
  isEyeglasses: boolean,
  configPackages,
  configData
) => {
  let ret = null;
  switch (key) {
    case "Type":
      ret = "type";
      break;
    case "AdvancedPrescription":
      ret = "prescription";
      break;
    case "Brand":
      ret = "brand";
      break;
    case "LensBundle":
      ret = "bundles";
      break;
    case "Thickness":
      ret = "thickness";
      break;
    case "DesignType":
      ret = "design-type";
      break;
    case "LensColor":
      ret = isEyeglasses ? "sun-step" : "lens-color";
      break;
    case "TreatmentsFamily":
      ret = "treatment-need";
      break;
    case "GVPTreatment":
      ret = "gvp-selection";
      break;
    case "ProtectionPlan":
      ret = "warrantyOptions";
      break;
    case "AddOns":
      ret = "treatment-addon";
      break;
    case "Review":
      ret = isFrameOnlySelected(configPackages, configData)
        ? "review-frameOnly"
        : "review";
      break;
  }
  return ret;
};
export const loadBrandConfig = (brand?: string, type?: string) => {
  if (!type || type === "EYEGLASSES") {
    return import("./json/" + brand + ".config")
      .then((comp) => ({ default: comp.configuration }))
      .catch(() =>
        import("./json/config").then((comp) => ({
          default: comp.configuration,
        }))
      );
  } else {
    return import("./json/sun." + brand + ".config")
      .then((comp) => ({ default: comp.configuration }))
      .catch(() =>
        import("./json/sun.config").then((comp) => ({
          default: comp.configuration,
        }))
      );
  }
};

export const checkGVP = (packages: any[]) => {
  return packages.some((p) => p?.frame?.promoType === "GVP");
};

export const checkGVPForType = (packages: any[], type: string) => {
  return packages.some(
    (p) => p.frame.promoType === "GVP" && p.lensPackage.type === type
  );
};

export const checkLayoutConfigDesignType = (config: any): boolean => {
  return config?.layoutSettings?.enableDesignTypeStep;
};

export const checkTileTitlePrefix = (config: any, stepKey: string): boolean => {
  let requiredStepsWithPrefix =
    config?.layoutSettings?.addTilesTitlePrefix?.map((el) => el.toLowerCase());
  return requiredStepsWithPrefix?.includes(stepKey.toLowerCase());
};
export const isBundlePriceEnabled = (config: any) => {
  if (config.layoutSettings?.showFramePlusLensesPrice) {
    return false;
  }
  return false; // returns undefined (falsy) in case is not specified
};
export const mapConfigStepToStep = (source: string[]) => {
  if (source) {
    const configStep = source.map((s, index) => ({
      key: s,
      progressive: index + 1,
    }));
    let steps = configStepAdapter.adaptList(configStep);
    steps = steps.map((step) => {
      const labels = getStepLabels(step.key);
      step.params = [
        /* ...labelsParamsAdapters.adapt(labels) */
      ];
      step.navigated = false;
      return step;
    });
    return steps;
  }
  return null;
};

export const getFrameOnlyKey = (packages: any[], configData: any) => {
  if (packages && packages.length && configData) {
    let frameOnlyPackage = packages.find(
      (pkg) =>
        pkg.lensPackage.productUPC &&
        pkg.lensPackage.productUPC.toLowerCase() === "plano" &&
        pkg.lensPackage.upc === configData.frameOnlyLensUPC
    );
    if (frameOnlyPackage) {
      return frameOnlyPackage.lensPackage.type;
    }
  }
};

export const getStepLabels: (stepName: string) => LabelsParams = (
  stepName: string
) => {
  return {
    title: "steps." + stepName.toLowerCase() + ".title",
    subtitle: "steps." + stepName.toLowerCase() + ".subtitle",
    confirmedTitle: "steps." + stepName.toLowerCase() + ".confirmedTitle",
  };
};

export const filterExistingProperties = (
  obj: Record<string, any>,
  filters: Record<string, any>,
  isGVP?: boolean
): boolean => {
  let ret = true;

  if (
    isGVP &&
    filters &&
    Object.keys(filters).length &&
    (Object.keys(filters)[0] === "blueLight" ||
      Object.keys(filters)[0] === "antiReflective")
  ) {
    //gvp treatments are found under treatment in the lensPackage
    filters = {
      treatment: filters[Object.keys(filters)[0]],
    };
  }

  if (filters && Object.keys(filters).length) {
    Object.entries(filters).forEach(([key, value]) => {
      if (key === "familyFilter") {
        let hasRequired = value.required.every((val) => obj[val]);
        let doesntHaveExcluded = value.excluded.every(
          (val) => !obj[val] || obj[val].toString().length === 0
        );

        ret = ret && hasRequired && doesntHaveExcluded;
      } else {
        if (Object.keys(obj).some((o) => o === key)) {
          ret = ret && obj[key] === value;
        }
      }
    });
  }
  return ret;
};

export const getCombinedPower = (prescription: FullPrescription) => {
  let A = parseFloat(String(prescription.SPH?.OD || 0));
  A +=
    prescription.rxType === "READING"
      ? parseFloat(String(prescription.ADD?.OD || 0))
      : 0;

  const B =
    A +
    (prescription.CYL?.OD !== undefined && prescription.CYL?.OD !== null
      ? parseFloat(String(prescription.CYL.OD))
      : 0.0);

  let C = parseFloat(String(prescription.SPH?.OS || 0));
  C +=
    prescription.rxType === "READING"
      ? parseFloat(String(prescription.ADD?.OS || 0))
      : 0;

  const D =
    C +
    (prescription.CYL?.OS !== undefined && prescription.CYL?.OS !== null
      ? parseFloat(String(prescription.CYL.OS))
      : 0.0);

  let values = [A, B, C, D];
  let maxIndex = 0;

  values.forEach((value, index) => {
    if (Math.abs(value) > Math.abs(values[maxIndex])) {
      maxIndex = index;
    }
  });

  return values[maxIndex];
};

export const hasValidPrescription = (
  currentPackage,
  prescription: FullPrescription
) => {
  if (!prescription) return true;
  if (
    prescription?.prescriptionFlow &&
    !["manual", "NESTED", "account"].includes(
      prescription.prescriptionFlow?.toString().toLowerCase()
    )
  ) {
    return true;
  }

  const nullValues = [null, undefined, NaN];
  const sphMin = parseFloat(currentPackage.lensPackage.rxValues?.sph?.min);
  const sphMax = parseFloat(currentPackage.lensPackage.rxValues?.sph?.max);
  const cylMin = parseFloat(currentPackage.lensPackage.rxValues?.cyl?.min);
  const cylMax = parseFloat(currentPackage.lensPackage.rxValues?.cyl?.max);
  const addMin = parseFloat(currentPackage.lensPackage.rxValues?.add?.min);
  const addMax = parseFloat(currentPackage.lensPackage.rxValues?.add?.max);

  if (
    nullValues.includes(sphMin) ||
    nullValues.includes(sphMax) ||
    nullValues.includes(cylMin) ||
    nullValues.includes(cylMax)
  ) {
    return false;
  }

  const combinedTotalPower = getCombinedPower(prescription);
  if (
    combinedTotalPower >= sphMin &&
    combinedTotalPower <= sphMax &&
    (!prescription.CYL ||
      (parseFloat(String(prescription.CYL?.OD)) >= cylMin &&
        parseFloat(String(prescription.CYL?.OD)) <= cylMax &&
        parseFloat(String(prescription.CYL?.OS)) >= cylMin &&
        parseFloat(String(prescription.CYL?.OS)) <= cylMax))
  ) {
    // progressive case
    if (
      currentPackage.type === "progressive" &&
      (prescription.ADD?.OD || prescription.ADD?.OS)
    ) {
      if (
        parseFloat(String(prescription.ADD.OD)) >= addMin &&
        parseFloat(String(prescription.ADD.OD)) <= addMax
      ) {
        return true;
      }
    } else {
      return true;
    }
  }
};

export const getCompatibleLensesType = (lensPackages, prescriptionObject) => {
  let compatibleLensesType = null;
  for (let i in lensPackages) {
    if (hasValidPrescription(lensPackages[i], prescriptionObject)) {
      compatibleLensesType = lensPackages[i].lensPackage.type;
      break;
    }
  }
  return compatibleLensesType;
};

export const isFramePrescriptionCompatible = (rxValues, prescriptionObject) => {
  if (rxValues && prescriptionObject?.SPH) {
    const combinedTotalPower = getCombinedPower(prescriptionObject);
    if (
      parseFloat(rxValues.powerCombinedMin) <= combinedTotalPower &&
      parseFloat(rxValues.powerCombinedMax) >= combinedTotalPower
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getStepSelections = (
  alias: (id: string) => string,
  getSkipped?: boolean,
  content?: Record<string, any>,
  all?: boolean
) => {
  let p: any[] = [];
  const stepsNotSkipped = workflow.getPrevAndCurrentChain();
  let prevSteps = stepsNotSkipped;
  if (getSkipped) {
    prevSteps = workflow.getPrevAndCurrentChainIgnoreSkip();
  }
  if (all) {
    prevSteps = workflow.getNextAndCurrentChain();
  }
  const prevStepsKey = prevSteps.map((s) => s.key.toLowerCase());

  const getStepName = (sname: string) => {
    const finded = prevSteps.find(
      (s) => s.key.toLowerCase() === sname.toLowerCase()
    );
    if (finded) {
      return finded.key;
    }
    return sname;
  };
  if (prevStepsKey.includes("mixandmatch")) {
    prevStepsKey[0] = "type";
  }
  prevSteps.forEach((s) => {
    if (s.params) {
      let newParams = s.params.map((param) => {
        if (param?.key?.toLowerCase() === "type") {
          if (param?.optionalAttributes?.value === "Reading") {
            return {
              // ...param,
              key: param?.key,
              value: content?.type?.Reading?.title,
            };
          }
          if (param?.optionalAttributes?.value === "Distance") {
            return {
              // ...param,
              key: param?.key,
              value: content?.type?.Distance?.title,
            };
          }
        }

        if (param.key == "transition" && s.key == "TransitionColor") {
          return {
            ...param,
            key: "treatments",
            value: (param as any).title || param.value,
          };
        }
        if (param.key === "addOns") {
          let ret = new Parameter();
          Object.entries(param).forEach(([key, val]) => {
            if (key === "value") {
              if (val) {
                ret[key] = val.value;
              } else {
                ret[key] = null;
              }
            } else {
              ret[key] = val;
            }
          });
          return ret;
        }
        return param;
      });
      p = [
        ...p,
        ...newParams
          .filter((np) =>
            prevStepsKey.some((psk) => np?.key.toLowerCase() === psk)
          )
          .map((p) => {
            const key = alias(p.key);
            const skipped = !stepsNotSkipped.some(
              (psk) => p.key.toLowerCase() == psk.key.toLowerCase()
            );
            if (skipped) {
              if (!p.attributes) {
                p.attributes = {};
              }
              p.attributes.skipped = skipped;
            }
            return { ...p, key, originalStep: getStepName(p.key) };
          }),
      ];
    }
  });

  if (content) {
    const adapter = new ParameterToParameterWithTitleAdapter(content);
    p = adapter.adaptList(p);
  }

  return uniqBy(p, "key");
};

export const getCheapPackageFromList = (list: any): any =>
  minBy(
    list,
    (l) => {
      //se il package più economico è quello in promo restituisce il pacchetto in promo se no quello minore senza promo
      //quindi fa list package - promo e prende il minore.
      let promoAmout = 0;
      /*if(l.frame.promo){
        Object.keys(l.frame.promo).forEach(k=>{
            if(l.frame.promo[k] && l.frame.promo[k].amount)
            promoAmout+=parseFloat(l.frame.promo[k].amount.toString());
        })
    }*/

      // test comment RXPANEL-3974

      // if (l.lensPackage.promo && !l.lensPackage.lensBundle) {
      //   Object.keys(l.lensPackage.promo).forEach((k) => {
      //     if (l.lensPackage.promo[k] && l.lensPackage.promo[k].amount)
      //       promoAmout += parseFloat(l.lensPackage.promo[k].amount.toString());
      //   });
      // }

      return parseFloat(l.lensPackage.listPrice) - promoAmout;
    } /*['lensPackage.listPrice']*/
  );

export const getPriceForGVPStep = (filters, currentPackages) => {
  const toRet = {
    price: 0,
    discount: 0,
    insPrice: 0,
  };
  if (!currentPackages) return toRet;
  const packages = getCheapestPackages(currentPackages, filters);
  if (!packages) return null;

  const { minPackageFilterPrice } = packages;
  const filteredPackage = minPackageFilterPrice.lensPackage;

  toRet.price = parseFloat(filteredPackage?.listPrice);
  toRet.discount =
    parseFloat(filteredPackage?.listPrice) -
    (parseFloat(filteredPackage?.offerPrice) +
      parseFloat(minPackageFilterPrice.frame.offerPrice));
  toRet.insPrice = parseFloat(filteredPackage?.insPrice);

  return toRet;
};

export const getPriceFromPackageAndFiltered = (
  currentPackage: any[],
  filters?: Record<string, any>,
  isGVP?: boolean,
  configFrame?: any,
  showFramePlusLensesPrice?: boolean
) => {
  const toRet = {
    price: 0,
    discount: 0,
    insPrice: 0,
  };
  if (!currentPackage) return toRet;

  const coloredPackages = currentPackage.filter(
    (pk: any) => pk?.lensPackage?.color && pk?.lensPackage?.color !== ""
  );

  const packages = getCheapestPackages(
    filters?.color ? coloredPackages : currentPackage,
    filters
  );

  if (!packages) return null;

  const { minSelectedPrice, minPackageFilterPrice } = packages;
  const selectedPackage = minSelectedPrice.lensPackage;
  const filteredPackage = minPackageFilterPrice.lensPackage;

  if (
    workflow.willManualSelectionBeDone() ||
    workflow?.currentStep?.params?.find(
      (p) =>
        p?.key === "treatmentsFamily" &&
        (p?.value.toLowerCase() === "sun" || p?.value.toLowerCase() === "sonne")
    )
  ) {
    // steps before treatmentsFamily
    if (showFramePlusLensesPrice) {
      if (
        !filteredPackage?.originalListPrice &&
        !filteredPackage?.originalOfferPrice
      ) {
        // showFramePlusLenses with no bundles
        return {
          price:
            parseFloat(filteredPackage?.listPrice) +
            parseFloat(minPackageFilterPrice?.frame?.offerPrice),
          discount:
            parseFloat(filteredPackage?.listPrice) -
            parseFloat(filteredPackage?.offerPrice),
          insPrice: parseFloat(filteredPackage?.insPrice),
        };
      } else if (
        filteredPackage?.originalListPrice &&
        filteredPackage?.originalOfferPrice &&
        filteredPackage?.listPrice === filteredPackage?.offerPrice
      ) {
        //if no promo applied to bundles
        //(listPrice and offerPrice refer to the bundle price, originalListPrice and originalOfferPrice refer to lensPackage prices )
        return {
          price:
            parseFloat(filteredPackage?.originalListPrice) +
            parseFloat(minPackageFilterPrice?.frame?.offerPrice),
          discount:
            parseFloat(filteredPackage?.originalListPrice) +
            parseFloat(minPackageFilterPrice?.frame?.offerPrice) -
            parseFloat(filteredPackage?.listPrice),
          insPrice: parseFloat(filteredPackage?.insPrice),
        };
      } else {
        return {
          price: parseFloat(filteredPackage?.listPrice),
          discount:
            parseFloat(filteredPackage?.listPrice) -
            parseFloat(filteredPackage?.offerPrice),
          insPrice: parseFloat(filteredPackage?.insPrice),
        };
      }
    } else {
      return {
        price: parseFloat(filteredPackage?.listPrice),
        discount:
          parseFloat(filteredPackage?.listPrice) -
          parseFloat(filteredPackage?.offerPrice),
        insPrice: parseFloat(filteredPackage?.insPrice),
      };
    }
  } else {
    const deltaPrice =
      parseFloat(filteredPackage?.listPrice) -
      parseFloat(selectedPackage?.listPrice);
    const deltaOfferPrice =
      parseFloat(filteredPackage?.offerPrice) +
      (isGVP ? parseFloat(minPackageFilterPrice.frame?.offerPrice) : 0) -
      (parseFloat(selectedPackage?.offerPrice) +
        (isGVP ? parseFloat(minSelectedPrice.frame.offerPrice) : 0));

    if (deltaPrice < 0) {
      return {
        price: parseFloat(selectedPackage?.listPrice),
        discount:
          parseFloat(selectedPackage?.listPrice) -
          parseFloat(selectedPackage?.offerPrice),
        insPrice:
          parseFloat(filteredPackage?.insPrice) -
          parseFloat(selectedPackage?.insPrice),
      };
    } else {
      const price = deltaOfferPrice > deltaPrice ? deltaOfferPrice : deltaPrice;
      const discount =
        deltaOfferPrice > deltaPrice ? 0 : deltaPrice - deltaOfferPrice;

      return {
        price,
        discount,
        insPrice:
          parseFloat(filteredPackage?.insPrice) -
          parseFloat(selectedPackage?.insPrice),
      };
    }
  }
};

export const getCheapestPackages = (packages, filters) => {
  const filtered = packages.filter((s) =>
    filterExistingProperties(
      s.lensPackage,
      filters,
      s?.frame?.promoType === "GVP"
    )
  );
  if (filtered.length === 0) return null;

  const minSelectedPrice = getCheapPackageFromList(packages);
  const minPackageFilterPrice = getCheapPackageFromList(filtered);
  return {
    minSelectedPrice,
    minPackageFilterPrice,
  };
};

export const getPriceFromPackageAndCustomFiltered = (
  currentPackage: any[],
  filtered: any[]
) => {
  if (currentPackage) {
    if (!filtered || !filtered.length) {
      return null;
    }
    const minSelectedPrice = getCheapPackageFromList(currentPackage);
    const minPackageFilterPrice = getCheapPackageFromList(filtered);

    // DAN WIP (this isn't called for some reason)
    if (workflow.willManualSelectionBeDone()) {
      return {
        price: parseFloat(minPackageFilterPrice.lensPackage?.listPrice),
        discount:
          parseFloat(minPackageFilterPrice.lensPackage?.listPrice) -
          parseFloat(minPackageFilterPrice.lensPackage?.offerPrice),
        insPrice:
          minPackageFilterPrice.lensPackage?.insPrice == null
            ? null
            : parseFloat(minPackageFilterPrice.lensPackage?.insPrice),
      };
    }

    const deltaPrice =
      parseFloat(minPackageFilterPrice.lensPackage?.listPrice) -
      parseFloat(minSelectedPrice.lensPackage?.listPrice);
    const deltaOfferPrice =
      parseFloat(minPackageFilterPrice.lensPackage?.offerPrice) -
      minSelectedPrice.lensPackage?.offerPrice;
    if (deltaPrice < 0) {
      return {
        price: parseFloat(minSelectedPrice.lensPackage?.listPrice),
        discount:
          parseFloat(minSelectedPrice.lensPackage?.listPrice) -
          parseFloat(minSelectedPrice.lensPackage?.offerPrice),
        insPrice:
          minPackageFilterPrice.lensPackage?.insPrice == null
            ? null
            : parseFloat(minPackageFilterPrice.lensPackage?.insPrice) -
            parseFloat(minSelectedPrice.lensPackage?.insPrice),
      };
    } else {
      return {
        price: deltaPrice,
        discount: deltaPrice - deltaOfferPrice,
        insPrice:
          minPackageFilterPrice.lensPackage?.insPrice == null
            ? null
            : parseFloat(minPackageFilterPrice.lensPackage?.insPrice) -
            parseFloat(minSelectedPrice.lensPackage?.insPrice),
      };
    }
  }
  return {
    price: 0,
    discount: 0,
    insPrice: 0,
  };
};

export function getCorrectAnalyticsStepContainerName(currentStepName: string) {
  if (!currentStepName) {
    return;
  }

  let aliases = {
    MixAndMatch: "Mixmatch",
    Type: "Type",
    Brand: "Brand",
    LensBundle: "Bundle",
    Thickness: "Material",
    TransitionColor: "Light",
    LensColor: "Colour",
    Treatments: "Treatment",
    GVPTreatment: "TreatmentDetail",
    TreatmentsFamily: "Treatment",
    AddOns: "Addon",
    Review: "Review",
  };
  let ret = aliases[currentStepName];
  return ret ? ret : currentStepName;
}

export function convertAliasStep(
  aliases: Record<string, string>,
  stepKey: string
) {
  let ret = aliases[stepKey];
  if (!ret || !ret.length) ret = stepKey;

  return ret;
}

export function isShowFramePlusLensesPrice() {
  const currentPackages = useCurrentPackages();
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (currentPackages && checkGVP(currentPackages) && workflow.currentStep) {
      if (workflow.currentStep.key == "GVPTreatment") {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else if (
      currentPackages &&
      !checkGVP(currentPackages) &&
      workflow.currentStep
    ) {
      setIsVisible(true);
    }
  }, [currentPackages]);

  return isVisible;
}

export function callInsuranceService(
  packages: any[],
  configInsurance,
  frame,
  dispatch
) {
  if (isFunction(configInsurance.getInsuranceDiscounts)) {
    const frameSelected = frameToInsuranceFrameAdapter.adapt(frame);
    const insurablePackages = packages.filter((currentPackage) => {
      return (
        (currentPackage.lensPackage.insurable === undefined ||
          currentPackage.lensPackage.insurable === null ||
          currentPackage.lensPackage.insurable === true) &&
        currentPackage.lensPackage.insPrice == null
      );
    });
    const errorMessage = "ERROR while retrieving insurance discount";
    if (insurablePackages.length) {
      dispatch(insuranceActions.setInsuranceLoading(true));
      configInsurance
        .getInsuranceDiscounts(frameSelected, insurablePackages)
        .then((result) => {
          if (isArray(result) && configInsurance.isEnabled()) {
            dispatch(
              configActions.setInsurancePackage({
                packages: result,
                frame: frame,
              })
            );
            dispatch(insuranceActions.setInsuranceEnabled(true));
          } else {
            throw new Error(
              errorMessage +
              " no insurable packages found or insuranceModule.isEnabled() failed or not found"
            );
          }
        })
        .catch((error) => {
          console.error(errorMessage, error);

          try {
            //@ts-ignore
            window.tealium_data2track.push({
              id: "Error",
              Error_Code: "RX Configurator: Loading error",
              Error_Details: errorMessage,
              Error_Source: "Client",
            });
          } catch (error) {
            console.error(
              "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
            );
          }
          if (!configInsurance.isLogged()) {
            dispatch(insuranceActions.setInsuranceEnabled(false));
          } else {
            configInsurance.removeInsuranceBenefits() ?? null;
            dispatch(insuranceActions.setInsuranceEnabled(false));
            dispatch(modalsActions.setShowInsuranceErrorPopup(true));
          }
        })
        .finally(() => {
          dispatch(insuranceActions.setInsuranceLoading(false));
        });
    } else {
      dispatch(insuranceActions.setInsuranceEnabled(true));
    }
  }
}

export const getStepBadgeType = (badge: string) => {
  if (!badge) {
    return null;
  }
  switch (badge.toLowerCase()) {
    case "bestseller":
      return StepBadgeType.BEST_SELLER;
    case "best prescription":
      return StepBadgeType.BEST_PRESCRIPTION;
    case "most popular":
      return StepBadgeType.MOST_POPULAR;
    case "new":
      return StepBadgeType.IS_NEW;
    case "recommended":
      return StepBadgeType.RECOMMENDED;
    case "coming soon":
      return StepBadgeType.COMING_SOON;
    default:
      return StepBadgeType.CUSTOM;
  }
};

export function getChipsList(description: string) {
  if (description.includes("<ul>")) {
    let chipsList = description.split("<ul>");
    if (chipsList) {
      return chipsList;
    }
  }
}

export function remapStepChainOnBrand(brandLastStep, reduxDispatch, category) {
  let stepChain = workflow.stepChain;
  if (stepChain && brandLastStep) {
    let brandStep = stepChain.find(
      (step: any) => step?.key?.toLowerCase() === "brand"
    );
    let reviewStep = stepChain.find(
      (step: any) => step?.key?.toLowerCase() === "review"
    );
    stepChain = stepChain.filter(
      (step: any) => step?.key?.toLowerCase() !== "brand"
    );
    stepChain = stepChain.filter(
      (step: any) => step?.key?.toLowerCase() !== "review"
    );
    let eppStep = stepChain.find(
      (step: any) => step?.key?.toLowerCase() === "protectionplan"
    );
    stepChain = stepChain.filter(
      (step: Step) => step?.key?.toLowerCase() !== "protectionplan"
    );
    if (brandStep && category.toLowerCase() !== "sunglasses") {
      stepChain.push(brandStep);
    } else if (category.toLowerCase() === "sunglasses") {
      //@ts-ignore
      let newBrandStep = new Step("Brand", 0, [], false);
      stepChain.push(newBrandStep);
    }
    if (eppStep) {
      stepChain.push(eppStep);
    }
    stepChain.push(reviewStep);
    stepChain.forEach((step: any, index) => {
      step.progressive = index + 1;
    });
    reduxDispatch(configActions.changeSteps({ steps: stepChain }));
  }
}

export function mixAndMatchasFirstStep(mixAndMatchEnabled, reduxDispatch) {
  let stepChain = workflow.stepChain;
  if (stepChain && mixAndMatchEnabled) {
    let newMixAndMatchStep = new Step("MixAndMatch", 1, [], true);
    stepChain = stepChain.filter(
      (step: any) => step?.key?.toLowerCase() !== "MixAndMatch"
    );
    stepChain.shift();
    stepChain.unshift(newMixAndMatchStep);

    reduxDispatch(configActions.changeSteps({ steps: stepChain }));
    reduxDispatch(workflowActions.setCurrentStep(newMixAndMatchStep));
  }
}

export function checkTypeValueSelected(stepsSelection: any) {
  if (stepsSelection?.[1].params?.[0]?.value) {
    return true;
  } else {
    return false;
  }
}

export function getChannelFromBrand(brand) {
  switch (brand) {
    case "arnette":
      return "AN";
    case "clearly":
      return "CL";
    case "costa":
      return "CM";
    case "davidclulow":
      return "DC";
    case "default":
      return "LC";
    case "framesdirect":
      return "FD";
    case "glasses":
      return "GL";
    case "oakley":
      return "OO";
    case "oliverpeoples":
      return "OP";
    case "opsm":
      return "OPSM";
    case "osi":
      return "OSI";
    case "persol":
      return "PO";
    case "rayban":
      return "RB";
    case "sgh":
      return "SGH";
    case "targetoptical":
      return "TO";
    default:
      return "LC";
  }
}

export function sortingGreyOut(options: any) {
  return options.sort((a, b) =>
    a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
  );
}

export const env = () => {
  const scripts = Array.from(document.getElementsByTagName("script"));
  const rxcScript = scripts.find((script) => {
    return script.src.includes("rxc.js");
  });
  const splittedScript = rxcScript?.src.split(/[//]/);
  const env = splittedScript?.[3];
  return env;
};
