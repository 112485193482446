import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfigContent } from "./useConfigContent";
import { useAllCurrentPackages } from "./useAllCurrentPackages";
import { useTransitionSelectedColor } from "./useTransitionSelectedColor";
import getCookieByName from "../utils/getCookieByName";
import { colorActions } from "../../redux/slices/color";
import { usePriceStepItem } from "./usePriceStepItem";
import { virtualMirrorActions } from "../../redux/slices/virtualMirror";

export function useTransitionColors(id) {
  const enableNestedTransitionSwatchCookie = getCookieByName("enableNestedTransitionSwatch", true)
  const enableNestedTransitionSwatchConfig = useSelector(
    (state: any) => state.config.layoutSettings?.enableNestedTransitionSwatch
  );
  const packages = useAllCurrentPackages();
  const configColor = useConfigContent("Color");
  const [colors, setColors] = useState<any>(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const c = usePriceStepItem();
  const reduxDispatch = useDispatch();
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );

  const isNestedTransitionSwatchEnabled = useMemo(() => {
    if (![null, undefined].includes(enableNestedTransitionSwatchCookie)) {
      return enableNestedTransitionSwatchCookie as boolean;
    }
    if (![null, undefined].includes(enableNestedTransitionSwatchConfig)) {
      return enableNestedTransitionSwatchConfig as boolean;
    }
    return false;
  }, [enableNestedTransitionSwatchCookie, enableNestedTransitionSwatchConfig]);

  const currentPackages = useMemo(() => {
    const transitionLenses = packages?.validRXPackages?.filter(p => p.lensPackage.transition?.length)
    const firstValidLensPackageTreatment = transitionLenses?.[0]?.lensPackage?.transition
    let cp = null
    if (!isNestedTransitionSwatchEnabled || !packages?.validRXPackages || id?.split("$")?.[0] !== "transition") {
      return null
    }
    if (id?.split("$")?.[1]) {
      cp = packages?.validRXPackages?.filter(p => p.lensPackage[id.split("$")[0]] === id.split("$")[1])
    } else if (id?.split("$")?.[0] === "transition" &&
      (packages?.validRXPackages?.every(p => p.lensPackage.type === "Non_prescription" ||
        transitionLenses.every(p => p.lensPackage.transition === firstValidLensPackageTreatment))
      )) {
      return packages?.validRXPackages
    }
    return cp
  }, [packages, id])

  useEffect(() => {
    if (configColor && currentPackages) {
      const colors = configColor
        .filter((c) =>
          currentPackages.some(
            (cp) => cp.lensPackage.color === c.id && cp.lensPackage.transition
          )
        )
        .map(color => {
          const colorPrice = c(
            { color: color.id },
            currentPackages
          );
          const pkgs = currentPackages.filter(
            (cp) => cp.lensPackage.color === color.id && cp.lensPackage.transition
          )

          const clen = pkgs.find(({ lensPackage }) => lensPackage.clen)?.lensPackage?.clen

          const withExtension = color?.imageFileName?.indexOf(".") > 0;
          const extension = withExtension ? color?.imageFileName?.split(".")[1] : "png";

          const src = `${configProjectImage}${color?.imageFileName}${withExtension ? "" : `.${extension}`
            }`;

          return {
            ...color,
            ...colorPrice,
            clen: clen,
            swatchImage: src
          };
        })
        .sort((a, b) => a.price - b.price);

      const invalidRXPackagesFilteredOnColors = configColor
        .filter((c) =>
          currentPackages.some(
            (cp) => cp.lensPackage.color === c.id
          )
        )
        .map((color) => {
          return {
            ...color,
            disabled: true,
          };
        });
      const validRXPackagesFilteredOnColors = configColor
        .filter((c) =>
          currentPackages.some(
            (cp) => cp.lensPackage.color === c.id
          )
        )
        .map((color) => {
          return {
            ...color,
            disabled: false,
          };
        });
      const greyOutColors = invalidRXPackagesFilteredOnColors.filter(
        (inv: any) =>
          !validRXPackagesFilteredOnColors.some((val: any) => val.id === inv.id)
      );
      if (greyOutColors.length > 0) {
        let filtered = colors.filter(
          (color: any) =>
            !greyOutColors.some((gcolor: any) => gcolor.id === color.id)
        );
        setColors([...filtered, ...greyOutColors]);
      } else {
        setColors([...colors, ...greyOutColors]);
      }
      // console.log({
      //   [id]: colors
      // })
      if (!selectedColor) {
        setSelectedColor(colors[0]);
      }
    }
  }, [configColor, selectedColor, currentPackages, c]);

  const selectColor = useCallback((color, callback?) => {
    setSelectedColor(color);
    if (color) {
      reduxDispatch(colorActions.selectTransition(color.imageFileName1))
    }
    reduxDispatch(colorActions.selectColor(color));
    if (callback) {
      callback();
    }
    reduxDispatch(virtualMirrorActions.setLensesList({ colors, id }))
    reduxDispatch(virtualMirrorActions.setLens({ color }))
  }, [colors])

  return { colors, selectColor, selectedColor, isNestedTransitionSwatchEnabled }
}
