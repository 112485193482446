import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./default.module.scss";
import NumberFormat from "react-currency-format";
import { TranslateText } from "../common/TranslateText";
import { workflow } from "../../configurator/models/Workflow";
import { checkGVP } from "../../configurator/ConfigLoader";
import { BrandComponent } from "../common/BrandComponent";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { useCurrentPackages, useShowFramePlusLensesPrice } from "@hooks";
export interface StepPriceOpt {
  price: number;
  discount?: number;
  insPrice?: number;
  hideSign?: boolean;
  priceStyle?: Object;
  strikePriceStyle?: Object;
  promo?: number;
  ignorePriceLabels?: boolean;
  hideInsuranceLoader?: boolean;
  ignoreInsurance?: boolean;
  discountLoading?: boolean;
}

/**
 * Component used to display the price in a step. It accepts an input of type ```PriceOpt```
 * with the following parameters:
 *  - ```price```: the full, non-discounted price of the object
 *  - ```discount``` (_optional_): the discount applied to the full price
 *  - ```hideSign``` (_optional_): flag that hides the '+' sign before the price value
 *  - ```priceStyle``` (_optional_): object of style that overrides the style of the price
 *  - ```strikePriceStyle``` (_optional_): object of style that overrides the style of the strike price
 */
export function StepPrice({
  price,
  discount,
  insPrice,
  hideSign,
  priceStyle,
  strikePriceStyle,
  promo,
  ignorePriceLabels,
  hideInsuranceLoader,
  ignoreInsurance,
  discountLoading,
}: StepPriceOpt) {
  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const promoLoading = useSelector((state: any) => state.promo?.loading);

  const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);

  const currentPackages = useCurrentPackages();

  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();

  const currentStep = useSelector((state: any) => state.workflow?.currentStep);

  useEffect(() => {
    setIsInsuranceEnabled(insuranceEnabled);
  }, [insuranceEnabled]);

  const renderPrice = (value: number) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        prefix={currencyFormat.suffix ? "" : currencyFormat.prefix}
        suffix={currencyFormat.suffix}
        decimalSeparator={currencyFormat.decimalSeparator}
        thousandSeparator={currencyFormat.thousandSeparator}
        decimalScale={
          !isNaN(Number(currencyFormat?.decimalScale))
            ? Number(currencyFormat?.decimalScale)
            : 2
        }
        fixedDecimalScale={true}
        renderText={(value: string) => <>{value}</>}
      />
    );
  };

  const getClassNameForPrice = () => {
    if (isInsuranceEnabled && !ignoreInsurance) {
      return (
        "StepPrice__container__inner__price" +
        " " +
        "StepPrice__container__inner__green"
      );
    }
    return "StepPrice__container__inner__price";
  };

  const getClassNameForStrikePrice = () => {
    if (isInsuranceEnabled && !ignoreInsurance) {
      return (
        "StepPrice__container__inner__strikePrice" +
        " " +
        "StepPrice__container__inner__green"
      );
    }
    return "StepPrice__container__inner__strikePrice";
  };

  const getClassNameForFree = () => {
    if (isInsuranceEnabled && !ignoreInsurance) {
      return (
        "StepPrice__container__inner__free" +
        " " +
        "StepPrice__container__inner__green"
      );
    }
    return "StepPrice__container__inner__free";
  };

  const getClassNameForGVPLabel = () => {
    return "StepPrice__container__inner__GVPLabel";
  };

  const getClassNameForStartingAtLabel = () => {
    return "StepPrice__container__inner__startingAtLabel";
  };

  const getPrice = () => price - discount;

  const includedLabel = useTranslate("price.included");

  const isInsuranceLoading = () => {
    return (
      insuranceEnabled &&
      !ignoreInsurance &&
      (insPrice === null || insPrice === undefined || isNaN(insPrice))
    );
  };

  return (
    <div className={"step-button-price " + "StepPrice"}>
      {discountLoading ? (
        <BrandComponent
          componentName="Loader"
          parameter={{ greyLoader: true }}
        />
      ) : promoLoading && !isInsuranceLoading() && !isInsuranceEnabled ? (
        <BrandComponent
          componentName="Loader"
          parameter={{ greyLoader: true }}
        />
      ) : (
        <>
          <div
            className={
              "StepPrice__container" +
              (isInsuranceLoading()
                ? " StepPrice__container__insuranceLoading"
                : "")
            }
          >
            {promo && !isInsuranceLoading() && !isInsuranceEnabled && (
              <BrandComponent
                componentName="StepPriceBadge"
                parameter={{ badge: promo }}
              />
            )}
            {!isInsuranceLoading() && (
              <div className={"StepPrice__container__inner"}>
                {(insPrice || insPrice === 0) &&
                isInsuranceEnabled &&
                !ignoreInsurance ? (
                  checkGVP(currentPackages) && !ignorePriceLabels ? (
                    <div className="StepPrice__container__inner__labelContainer">
                      <span className={getClassNameForGVPLabel()}>
                        <TranslateText label={"price.frameLensStartingAt"} />
                      </span>
                      <div>
                        {/*price > 0 && insPrice !== price && <span className={getClassNameForStrikePrice()} style={strikePriceStyle ? strikePriceStyle : null}>{renderPrice(price)}</span>*/}
                        <span
                          className={getClassNameForPrice()}
                          style={priceStyle ? priceStyle : null}
                        >
                          {renderPrice(insPrice)}
                        </span>
                      </div>
                    </div>
                  ) : workflow.willManualSelectionBeDone() &&
                    !ignorePriceLabels ? (
                    <div className="StepPrice__container__inner__labelContainer">
                      {/* <span className={getClassNameForStartingAtLabel()}><TranslateText label={'price.startingAt'} /></span> */}
                      <div>
                        {price > 0 && insPrice !== price && (
                          <span
                            className={getClassNameForStrikePrice()}
                            style={strikePriceStyle ? strikePriceStyle : null}
                          >
                            {renderPrice(price)}
                          </span>
                        )}
                        <span
                          className={getClassNameForPrice()}
                          style={priceStyle ? priceStyle : null}
                        >
                          {renderPrice(insPrice)}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {(!checkGVP(currentPackages) ||
                        (checkGVP(currentPackages) && workflow.isLastStep())) &&
                        price > 0 &&
                        insPrice !== price && (
                          <span
                            className={getClassNameForStrikePrice()}
                            style={strikePriceStyle ? strikePriceStyle : null}
                          >
                            {workflow.willManualSelectionBeDone() || hideSign
                              ? ""
                              : "+"}
                            {renderPrice(price)}
                          </span>
                        )}
                      <span
                        className={getClassNameForPrice()}
                        style={priceStyle ? priceStyle : null}
                      >
                        {workflow.willManualSelectionBeDone() || hideSign
                          ? ""
                          : "+"}
                        {renderPrice(insPrice)}
                      </span>
                    </>
                  )
                ) : price !== 0 ? (
                  discount ? (
                    checkGVP(currentPackages) && !ignorePriceLabels ? (
                      <div className="StepPrice__container__inner__labelContainer">
                        <span className={getClassNameForGVPLabel()}>
                          <TranslateText label={"price.frameLensStartingAt"} />
                        </span>
                        <div>
                          {/*<span className={getClassNameForStrikePrice()} style={strikePriceStyle ? strikePriceStyle : null}>{renderPrice(price)}</span>*/}
                          <span
                            className={getClassNameForPrice()}
                            style={priceStyle ? priceStyle : null}
                          >
                            {renderPrice(getPrice())}
                          </span>
                        </div>
                      </div>
                    ) : workflow.willManualSelectionBeDone() &&
                      !ignorePriceLabels ? (
                      <div className="StepPrice__container__inner__labelContainer">
                        {/* <span className={getClassNameForStartingAtLabel()}><TranslateText label={'price.startingAt'} /></span> */}
                        <div>
                          {showFramePlusLensesPrice &&
                          currentStep?.key !== "LensBundle" ? (
                            <span className={getClassNameForGVPLabel()}>
                              <TranslateText label="price.bundleFrameLensStartingAt" />
                            </span>
                          ) : (
                            showFramePlusLensesPrice &&
                            currentStep?.key === "LensBundle" && (
                              <span className={getClassNameForGVPLabel()}>
                                <TranslateText label="price.frameLens" />
                              </span>
                            )
                          )}
                          <span
                            className={getClassNameForStrikePrice()}
                            style={strikePriceStyle ? strikePriceStyle : null}
                          >
                            {renderPrice(price)}
                          </span>
                          <span
                            className={getClassNameForPrice()}
                            style={priceStyle ? priceStyle : null}
                          >
                            {renderPrice(getPrice())}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {!checkGVP(currentPackages) && (
                          <span
                            className={getClassNameForStrikePrice()}
                            style={strikePriceStyle ? strikePriceStyle : null}
                          >
                            {workflow.willManualSelectionBeDone() || hideSign
                              ? ""
                              : "+"}
                            {renderPrice(price)}
                          </span>
                        )}
                        <span
                          className={getClassNameForPrice()}
                          style={priceStyle ? priceStyle : null}
                        >
                          {workflow.willManualSelectionBeDone() || hideSign
                            ? ""
                            : "+"}
                          {renderPrice(getPrice())}
                        </span>
                      </>
                    )
                  ) : checkGVP(currentPackages) && !ignorePriceLabels ? (
                    <div className="StepPrice__container__inner__labelContainer">
                      <span className={getClassNameForGVPLabel()}>
                        <TranslateText label={"price.frameLensStartingAt"} />
                      </span>
                      <span
                        className={getClassNameForPrice()}
                        style={priceStyle ? priceStyle : null}
                      >
                        {renderPrice(price)}
                      </span>
                    </div>
                  ) : workflow.willManualSelectionBeDone() &&
                    !ignorePriceLabels ? (
                    <div className="StepPrice__container__inner__labelContainer">
                      {/* <span className={getClassNameForStartingAtLabel()}><TranslateText label={'price.startingAt'} /></span> */}
                      <span
                        className={getClassNameForPrice()}
                        style={priceStyle ? priceStyle : null}
                      >
                        {showFramePlusLensesPrice &&
                        currentStep?.key !== "LensBundle" ? (
                          <span className={getClassNameForGVPLabel()}>
                            <TranslateText label="price.bundleFrameLensStartingAt" />
                          </span>
                        ) : (
                          showFramePlusLensesPrice &&
                          currentStep?.key === "LensBundle" && (
                            <span className={getClassNameForGVPLabel()}>
                              <TranslateText label="price.frameLens" />
                            </span>
                          )
                        )}
                        {workflow.willManualSelectionBeDone() ||
                        hideSign ||
                        checkGVP(currentPackages)
                          ? ""
                          : "+"}
                        {renderPrice(price)}
                      </span>
                    </div>
                  ) : (
                    <span
                      className={getClassNameForPrice()}
                      style={priceStyle ? priceStyle : null}
                    >
                      {workflow.willManualSelectionBeDone() ||
                      hideSign ||
                      checkGVP(currentPackages)
                        ? ""
                        : "+"}
                      {renderPrice(price)}
                    </span>
                  )
                ) : checkGVP(currentPackages) && !ignorePriceLabels ? (
                  <div className="StepPrice__container__inner__labelContainer">
                    <span className={getClassNameForGVPLabel()}>
                      <TranslateText label={"price.frameLens"} />
                    </span>
                    <span className={getClassNameForFree()}>
                      <TranslateText label={"price.included"} />
                    </span>
                  </div>
                ) : workflow.willManualSelectionBeDone() &&
                  !ignorePriceLabels ? (
                  <div className="StepPrice__container__inner__labelContainer">
                    <span className={getClassNameForGVPLabel()}>
                      <TranslateText label={"price.frameLens"} />
                    </span>
                    <span className={getClassNameForFree()}>
                      <TranslateText label={"price.included"} />
                    </span>
                  </div>
                ) : (
                  <span className={getClassNameForFree()}>
                    {showFramePlusLensesPrice ? (
                      <span
                        className={getClassNameForPrice()}
                        style={priceStyle ? priceStyle : null}
                      >
                        {/* {renderPrice(0)} */}
                      </span>
                    ) : (
                      <TranslateText label={"price.included"} />
                    )}
                  </span>
                )}
              </div>
            )}
            {isInsuranceLoading() && (
              <>
                {!hideInsuranceLoader && (
                  <div className={"StepPrice__container__insuranceText"}>
                    <TranslateText label={"applyingInsurance"} />
                  </div>
                )}
                <BrandComponent componentName="Loader" />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
