export default function (brand) {
  return {
    [brand]: {
      en_US: {
        steps: {
          mixAndMatch: {
            title: "Choose your frame and lens color",
            subtitle: "Choose your frame and lens color",
            frame: "Frame",
            lenses: "Lenses",
            polarized: "Polarized",
            prescription: "prescription",
            nonPrescription: "non-prescription",
            bridgeLabel: "Bridge and Nosepads:",
          },
          prescription: {
            title: "Add your prescription values",
            description:
              "Or we will suggest the right lens for your vision need, so you can go hussle free",
            prescriptionValues: "Prescription Values",
            prismValues: "Prism Values",
          },
          advancedPrescription: {
            confirmedTitle: "Prescription:",
            manual: {
              pupillaryDistanceSubtitle:
                'You can select the default settings of 61 for women and 64 for men if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact Pupillary Distance), please <a  data-element-id="X_X_LensPanel_AdvancedPrescription-MeasurePD">measure your Pupillary Distance.</a>',
              review: {
                title: "Entered manually",
              },
              selectNewFrame: "Select new frame",
              title: "Enter your prescription",
              card: {
                title: "Enter it manually",
                description:
                  "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
              },
              subtitle:
                "Insert the parameters you find on your prescription in the table below.",
              rightEye: {
                initials: "OD",
                name: "(Right)",
              },
              leftEye: {
                initials: "OS",
                name: "(Left)",
              },
              sphere: "SPH (Sphere)",
              cylinder: "CYL (Cylinder)",
              axis: "Axis",
              add: "Add",
              addPlaceholder: "None",
              vertical: "Vertical (Δ)",
              baseDirection: "Base Direction",
              horizontal: "Horizontal (Δ)",
              pupillaryDistance: "Pupillary Distance",
              pdLeft: "Left",
              pdRight: "Right",
              iHaveTwoPd: "I have 2 Pupillary Distance numbers",
              commentsTitle: "Comments",
              applyButton: "Continue",
              applyButtonSave: "Save and continue",
              howToRead: "How to read your prescription",
              howToReadModal: {
                title: "How to read your prescription",
                subtitle:
                  "If you have a prescription for eyeglasses, the prescription information will typically include the following:",
                rightEye: {
                  name: " OD (Right Eye)",
                  description:
                    'OD stands for "oculus dexter" which is Latin for "right eye".',
                },
                leftEye: {
                  name: " OS (Left Eye)",
                  description:
                    'OS stands for "oculus sinister" which is Latin for “left eye”.',
                },
                sphere: {
                  name: "Sphere (SPH) - also known as Power (PWR)",
                  description:
                    "They mean the same thing: the strength of your prescription. If you're nearsighted you will have a minus (-) before your values, and if you’re or farsighted you will have a plus (+).",
                },
                cylinder: {
                  name: "Cylinder (CYL)",
                  description:
                    "This is for people with astigmatism. This is when one part of the eye needs more correction than the rest. The Cylinder value is written with a minus (-) sign.",
                },
                axis: {
                  name: "Axis",
                  description:
                    "This is only for people with astigmatism. The axis is a number between 0 and 180 and determines the orientation of the Cylinder (CYL).",
                },
                add: {
                  name: "Add",
                  description:
                    "This indicates the additional magnifying power that is added to the lens to correct presbyopia, a common age-related condition that affects near vision.",
                },
                infoText:
                  "If any of this information isn’t included in your prescription, you can leave the field blank.",
                customerService:
                  "Not sure about something? Call our Customer Service experts <a>XXXXXXXXXX</a>",
              },
              pupillaryDistanceWarningValue:
                "Please input your pupillary distance, if you don’t have it you can use the default from the above or you can ",
              pupillaryDistanceMisurePDAction:
                "measure your Pupillary Distance.",
              pupillaryDistanceMisurePDActionHowToRead: "measure your PD",
              pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
              moreOptions: "More options",
              incompatibleFrame:
                "We're sorry – the frame you’ve chosen isn't compatible with your prescription. Please select another style.\n </br>Have questions? You can <a href='https://www.lenscrafters.com/lc-us/contact-us'>contact our Customer Service</a> team.",
              incompatibleLenses:
                "We're sorry, the prescription you've entered isn't compatible with our lenses offered online. Find a store near you or contact our Customer Service team for more information.",
              incompatibleLensTypeErrorSingle:
                "You selected single vision lenses, but your saved prescription is for progressive lenses. We have updated your prescription below. Please check that it’s still valid.",
              incompatibleLensTypeErrorProgressive:
                "You selected progressive lenses, but your saved prescription is for single vision lenses. We have updated your prescription below. Please check that it’s still valid.",
              clearAll: "Clear all",
              alerts: {
                pdValueWarningDigitalOptometrySmaller:
                  "The selected PD is smaller than average, we suggest double checking your prescription. If you don’t have it, you can add the values written above or you can ",
                pdValueWarningDigitalOptometryLarger:
                  "The selected PD is larger than average, we suggest double checking your prescription. If you don’t have it, you can add the values written above or you can ",
                pdValueWarningSmaller:
                  "<b>The selected Pupillary Distance is smaller than average, we suggest double checking your prescription.</b></br>You can leave the default setting of 63 which is the average measurement for adults.</br>If we need any more information about your prescription, one of our experts will be in touch.",
                pdValueWarningLarger:
                  "<b>The selected Pupillary Distance is larger than average, we suggest double checking your prescription.</b></br>You can leave the default setting of 63 which is the average measurement for adults.</br>If we need any more information about your prescription, one of our experts will be in touch.",
                pdValueWarning2:
                  "The selected PD is smaller than average, we suggest double checking your prescription. If you don’t have it, you can add the values written above or you can ",
                missingBaseDir:
                  "Please choose the base direction for your prism",
                missingPrismValues: "Please choose the prism value",
                missingValues: "Complete the missing values",
                incompatibleWithPrescriptionTitle:
                  "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
                incompatibleWithPackagesTitle:
                  "Sorry! The prescription you've chosen isn't compatible with any of our available lenses for this frame",
                shopCompatible: "Shop compatible frames",
                clearAll: "Clear all",
                agreementTextMobile:
                  "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
              },
              digitalOptometry: {
                title: "How to measure your Pupillary Distance ",
                appSubtitle:
                  "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. It indicates exactly which part of the lens you look through, ensuring optimal comfort and clarity. You can measure with the app or manually.",
                manuallySubtitle:
                  "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. You can measure with the app or manually.",
                toggle: {
                  app: "with the app",
                  manually: "manually",
                },
                opthyDesc:
                  "<b>Get your glasses just right with Opthy.</b><br/><p>Opthy measures your Pupillary Distance, allowing you to find the most comfortable, personalized fit.</p><p>App available on iPhone X and above.</p>",
                manuallyContent:
                  "<div>If you want, you can measure your PD yourself by following these steps:</div><ul><li>Grab a small ruler in one hand, and have your pencil and paper handy.</li><li>Now position yourself approximately 8 inches (20 cm) away from a mirror.</li><li>Close your left eye and align the 0 over the center of your right pupil.</li><li>Measure the distance from your right to your left pupil.</li><li>The number that lines up directly over your left pupil is your PD (an average PD for an adult ranges between 58 and 65).</li></ul>",
              },
              manualPreviewTitle: "Your prescription details",
              seeDetails: {
                sphere: "SPH",
                cylinder: "CYL",
                axis: "Axis",
                add: "ADD",
                pd: "PD",
                od: "OD",
                right: "Right",
                left: "Left",
                os: "OS",
                vertical: "Vertical Prism (Δ)",
                baseDirection: "Base Direction",
                horizontal: "Horizontal Prism (Δ)",
              },
              issueDate: {
                label: "Issue date (MM/YYYY)",
                missingError: "Please insert date",
                formatError: "The date entered is not valid",
                tooltipText:
                  "Before submitting your prescription, please check the expiration date to make sure it is current and valid. We only accept prescriptions that have been issued within the last 2 years. By selecting the tick box and continuing below, you agree to our privacy policy and T&Cs.",
              },
              samePrescriptionBothEyes: "Same prescription for both eyes",
              continueModal: {
                titleNoAdd: "ARE YOU SURE YOU WANT TO CONTINUE?",
                messageNoAdd:
                  "Please make sure to enter the Addition (ADD) value if it is listed in your prescription, otherwise please proceed without.",
                continueNoAdd: "Continue without (add)",
              },
              noPowerModal: {
                title: "continue with non-prescription lenses",
                subtitle:
                  "You have not entered a prescription for your glasses. Would you like to proceed with non-prescription lenses?",
                continue: "yes, continue",
                cancel: "no, cancel",
              },
            },
            upload: {
              title: "Your prescription",
              subtitle:
                "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
              card: {
                title: "Upload it now",
              },
              uploadDifferentFile: "Upload different file",
              changeMethod: "Change sending method",
              somethingWentWrong: "Sorry, something went wrong.",
              fileTooBigErrorTitle: "File is too big",
              fileTooBigErrorDescription:
                "Your file size is too big. Maximum upload ###FILE_SIZE### MB",
              tryAgain: "Please try again",
              upload: "Upload",
              pdForm: {
                pupillaryDistance: "PD (Pupillary distance)",
                whatIsIt: "What is it?",
                pdLeft: "Left",
                pdRight: "Right",
                iHaveTwoPd: "I have 2 PD numbers",
              },
              confirmAndContinue: "Confirm & continue",
              alerts: {
                agreementText:
                  "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
              },
            },
            doctor: {
              title: "Add your doctor details",
              subtitle:
                "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
              searchBy: "Search your eye doctor by",
              card: {
                title: "Call my doctor",
                description:
                  "We'll get your prescription directly from him/her.",
              },
              addNewDoctorModal: {
                title: "Add new doctor",
                clinicOrDoctorName: "Clinic or doctor name",
                phoneNumber: "Phone number",
                addDoctor: "Add doctor",
              },
              slider: {
                phone: "Phone number",
                clinicOrDoctorName: "Clinic or doctor name",
              },
              table: {
                doctorName: "Doctor name",
                clinicName: "Clinic name",
                address: "Address",
                chooseButton: "Choose",
                phone: "Phone",
                fax: "Fax",
                phoneNumber: "Phone number",
                changeButton: "Change",
                wrongDoctor: "Wrong doctor?",
              },
              pdForm: {
                pupillaryDistance: "PD (Pupillary distance)",
                whatIsIt: "What is it?",
                pdLeft: "Left",
                pdRight: "Right",
                iHaveTwoPd: "I have 2 PD numbers",
              },
              addManuallyOption: {
                didntFind: "Didn't find your doctor?",
                addManually: "Add manually",
              },
              missingInfoError: "Complete the missing informations",
              doctorNotFoundBoxMessage: "We couldn't find your doctor.",
              doctorNotFoundBoxMessageAdd: "Please add more details or ",
              doctorNotFoundBoxMessageLink: "add a new doctor.",
              phoneNumber: "Doctor phone number",
              search: "Search",
              clinicOrDoctorName: "Clinic or doctor name",
              city: "City",
              state: "State",
              changeSubmissionMethod: "Change submission method",
              confirmAndContinue: "Confirm & continue",
              selectedDoctorPageTitle: "Your doctor details",
              doctorPreviewTitle: "We'll call your doctor",
            },
            later: {
              card: {
                title: "Send it later",
              },
              review: {
                title: "We'll ask for it after the checkout",
              },
            },
            account: {
              card: {
                title: "Get it from my account",
              },
              review: {
                title: "Added from my account",
              },
            },
            fromMyAccount: {
              card: {
                title: "Get it from my account",
                description: "Log in and select your prescription.",
                descriptionLoggedIn: "Choose one of your saved prescriptions.",
              },
              review: {
                title: "Added from my account",
              },
              title: "Select your prescription",
              subtitle:
                "Choose the preferred prescription and we’ll take care of the rest. \n Can’t find it?",
              subtitleAdd: "Add a new prescription",
              prescriptionName: "Prescription name",
              uploadedOn: "Last updated:",
              today: "Today",
              yesterday: "Yesterday",
              justUpdated: "Just updated",
              selectButton: "Select prescription",
              savePrescriptionInAccount: "Save prescription in My Account",
              newPrescriptionNameError:
                "Prescription name field cannot be empty",
              olderThanYearBanner:
                "This prescription was uploaded 1 year ago. Please check if it is still valid.",
              showPrescription: "show prescription",
              prescriptionDetailModal: {
                title: "Your prescription details",
                edit: "Edit prescription",
              },
              issueDate: "Issue date",
            },
            californian: {
              toggle: "Are you a California resident?",
              info: "Please note that besides adding prescription values manually, it is also required to electronically transmit a valid prescription. Please, upload it to proceed.",
              canadaInfo:
                "Please note that besides adding prescription values manually, it is also required to electronically transmit a valid prescription. Please, upload it to proceed.",
              chooseTitle: "CHOOSE HOW TO SEND YOUR PRESCRIPTION",
              uploadTitle: "Upload your prescription",
              uploadDesc:
                "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
              uploadButton: "Upload",
              callMyDoc: "Call my doctor",
              callMyDocButton: "FIND YOUR DOCTOR",
              yes: "YES",
              no: "NO",
              requiredLabel: "* Required field",
              modalMissingPrescription: {
                title: "Missing prescription values",
                subtitle:
                  "If you don’t fill the values we won’t be able to suggest the right lens for you",
                bottonYes: "YES, CONTINUE",
                bottonNo: "NO, GO BACK",
              },
            },
          },
          type: {
            title: "Choose your vision need",
            confirmedTitle: "Vision need:",
            prescriptionTitle: "Or",
            addToCart: "Add to cart",
            learnMoreCTA: "Learn More about lens difference",
          },
          brand: {
            title: "Choose your lens brand",
            confirmedTitle: "Lens brand:",
          },
          lensBundle: {
            title: "Choose your lens design",
            confirmedTitle: "Lens bundle:",
            subtitle:
              "Choose from a selection of reliable, high-quality lenses designed with decades of experience, available for all prescription types",
          },
          treatmentsFamily: {
            confirmedTitle: "Lens type:",
            title: "Choose your lens type",
            blueLight: {
              title: "Blue-light filtering",
              description:
                "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
              imageFileName: "",
            },
            clear: {
              title: "Clear",
              description:
                "Traditional, transparent lenses perfect for everyday use",
              imageFileName: "",
            },
            sun: {
              title: "Sun",
              description: "Choose from different lens colors and tints.",
              imageFileName1: "rxc-icon-treatments-sun",
              imageFileName2: "rxc-icon-3d-treatments-sun",
            },
          },
          gvpTreatment: {
            title: "Choose your Great Value Package",
            subtitle:
              "Your glasses will come with best in class LensCrafters technology.",
            confirmedTitle: "Lens Package:",
          },
          thickness: {
            confirmedTitle: "Thickness:",
            title: "Choose your lens thickness",
            subtitle:
              "Your glasses will come with best in class LensCrafters technology.",
            designTypeTitle: "Choose your lens design",
            moreInfoStep: "Learn More about lens thickness",
          },
          designType: {
            confirmedTitle: "Design Type:",
          },
          treatments: {
            addToBag: "Add to bag",
            title: "Choose your light responsive treatment",
            confirmedTitle: "Treatment:",
            extraTreatments: {
              antiScratch: {
                name: "Anti-scratch",
                title: "Scratch-resistant treatment",
              },
              antiReflective: {
                value: "Anti-reflective",
              },
              uvProtection: {
                title: "UV protection",
              },
            },
            openButtonLabel: "Try them on",
          },
          transitionColor: {
            title: "TransitionColor",
            indoor: "Indoor",
            outdoor: "Outdoor",
          },
          lensColor: {
            confirmedTitle: "Color:",
            detailBox: {
              fallbackMessage:
                "Your frame will be paired with your selected lens color",
              lightTransmission: "Light Transmission",
              lightConditions: "Light conditions",
              contrast: "Contrast",
              baseLensColor: "Base Lens Color",
              learnMoreCTA: "ciao",
              improvement:
                "improves contrast for better separation of colors and giving golfers depth cues to gauge distance and grass conditions",
            },
            categories: {
              all: {
                title: "All",
              },
              "Ugly category": {
                title: "Ugly Category",
              },
              "Cool category": {
                title: "Cool Category",
              },
            },
          },
          color: {
            confirmedTitle: "Color:",
            title: "Choose your lens color",
            titleMobile: "Choose your lens color",
            titleDesignTypeLayout: "Choose your lens technology",
            titleColorCategoryLayout: "Choose your lens type",
            applyAndContinue: "Apply",
            subtitle: "Enhanced color and contrast so you can see more detail",
            tooltip: {
              switch:
                "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
              antiReflective:
                "Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.",
              uvProtection:
                "Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.",
              antiScratch: "Protects your lens surface from harsh impacts",
            },
          },
          addOns: {
            confirmedTitle: "Finishings:",
            label: "Already included in your lens",
            title: "Complete your lenses",
            reviewTitle: "Finishings:",
          },
          alreadyIncluded: {
            title: "Already Included",
            confirmedTitle: "Already Included:",
          },
          protectionplan: {
            title: "Enhance your coverage plan",
            confirmedTitle: "Protection plan:",
            asIs: "I do not want it",
            whatsCovered: "What's covered",
          },
          review: {
            title: "Review your selections",
            discountTitle:
              "40% off prescription lenses is automatically applied",
            firstLineReview: "Frame:",
            lensRecapTitle: "Lenses:",
            insuranceButtonLabel: "Add insurance benefits",
            insuranceButtonLabelRemove: "Remove insurance benefits",
            oneYearCoverageAdd: "Add",
            seeDetails: "See details",
            paymentInstallments: {
              modal: {
                paypal:
                  "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### easy payments of just ###MONTHLY_PRICE###",
              },
            },
            moneySavingsBadge: "You are saving <u>###AMOUNT###</u>",
          },
        },
        prescription: {
          toggleLabel:
            "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
          prescriptionTitle:
            "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
          rightEye: "(Right eye)",
          rightEyeInitials: "OD",
          leftEye: "(Left eye)",
          leftEyeInitials: "OS",
          readPrescription: "How to read your prescription",
          addLabel: "I also have an ADD value",
          applyButton: "Apply & continue",
          submitButton: "Submit",
          clearButton: "Clear all",
          skipButton: "Skip for now",
          compatibleFramesButton: "Shop compatible frames",
          doctorDetails:
            "We'll ask for a copy of your prescription or doctor's details during or after checkout",
          missingValueError: "Add your values to continue",
          positiveNegativeError:
            "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
          compatibleFrameError:
            "Sorry! The frame you've chosen isn't compatible with your prescription.",
          compatibleFrameSubLabel:
            "But don't worry, compatible frames are available.",
          axisError: "Remember to add your AXIS values",
          axisSubError: "These values must be between 0 and 180.",
          axisSubErrorZero:
            "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
          editLabel: "Edit prescription",
          pdHelp: "What is this?",
          twoPDNumbers: "I have two PD values",
          missingInputMessage:
            "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
          defaultInputMessage:
            "Choose to add your prescription now or upload it later.",
          skipMessage: "",
          readyMessage: "We are now able to suggest the right lens for you!",
          bestPrescription: "Best for your prescription",
          proceedCheckbox:
            "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
          proceedCheckboxError: "Required to proceed",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Frame + lenses price",
            frame: "Frame price",
            total: "Total",
          },
          free: "Free",
          included: "Included",
          frameLensStartingAt: "Frame + Lenses at",
          frameLens: "Frame + Lenses",
          startingAt: "Starting at",
          discountBadgePercentage: "###VALUE######SYMBOL### OFF",
          discountBadgeAmount: "###VALUE######SYMBOL### OFF",
          bundleFrameLensStartingAt: "Frame + Lenses starting at",
        },
        insuranceLegalBanner:
          "Insurance out-of-pocket pricing will be finalized at cart.",
        notAvailable: "Not available for your prescription",
        noTreatment: "No treatment",
        learnMoreMade: "See how it's made",
        learnMoreTreatment: "See the treatment",
        exitYes: "Yes, exit",
        exitContinueEditing: "No, continue",
        exitSave: "Save and continue shopping",
        insuranceButtonLabel: "Add insurance benefits",
        insuranceLabelRemove: "Remove benefits",
        insuranceButtonLabelRemove: "Remove insurance benefits",
        insuranceSyncedLabel: "Insurance is synced",
        applyingInsurance: "Applying insurance benefits",
        fallbackImageMessageTitle: "Lens color preview not available",
        fallbackImageMessageSubtitle:
          "No worries, you will receive your frame with the lens color you selected.",
        insuranceErrorModal: {
          title: "Something went wrong...",
          content:
            "We weren't able to apply your insurance benefits. Your insurance plan will be automatically unsynced in order to continue.",
          confirm: "Confirm",
        },
        proceedAsIs: "Proceed as is",
        grayOutBannerMessage:
          "Some options may be disabled as they are not compatible with your prescription values.",
        editButton: "Edit",
        error: {
          title: "Something went wrong...",
          description:
            "We’re experiencing some technical difficulties, we apologize. In the meantime, if you have any questions or need assistance, feel free to <a href='#'>contact our customer service.</a>",
          buttonLabel: "Back",
        },
        confirmButton: "Continue",
        savings: "You are saving ",
        insuranceBundlesModal: {
          title: "Insurance benefits cannot be applied to this promotion.",
          titleRemove:
            "Are you sure you want to remove your insurance benefits?",
          subtitle:
            "If you proceed with your insurance on, we will apply your benefits to the original prices.",
          subtitleRemove:
            "If you remove your insurance, you will start from scratch and access promotional prices.",
          yesProceedWithIns: "YES, PROCEED WITH INSURANCE",
          noRemove: "NO, REMOVE INSURANCE",
          yesAdd: "YES, ADD INSURANCE BENEFITS",
          noProceedWithoutIns: "NO, PROCEED WITHOUT INSURANCE",
          yesRemove: "YES, REMOVE INSURANCE",
          noProceedWithIns: "NO, PROCEED WITH INSURANCE",
        },
        vmmv: {
          backButton: "Back to product",
        },
      },
      en_GB: {
        steps: {
          prescription: {
            title: "Add your prescription values",
            description:
              "Or we will suggest the right lens for your vision needs, so you can go hassle free",
          },
          mixAndMatch: {
            title: "CHOOSE YOUR FRAME & LENS COLOUR",
            polarized: "Polarised",
          },
          advancedPrescription: {
            title: "Do you have a prescription?",
            manual: {
              card: {
                description:
                  "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
              },
              rightEye: {
                name: "(Right eye)",
              },
              leftEye: {
                name: "(Left eye)",
              },
              alerts: {
                axisRangeError:
                  "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
                axisZeroError:
                  "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
                incompatibleWithPrescriptionBody:
                  "but don’t worry, compatible frames are available",
                agreementText:
                  "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
              },
              continueModal: {
                title: "Send prescription later",
                message:
                  "By clicking continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
                continue: "Yes, continue",
                cancel: "No, go back",
                titleNoAdd: "ARE YOU SURE YOU WANT TO CONTINUE?",
                messageNoAdd:
                  "Please make sure to enter the Addition (ADD) value if it is listed in your prescription, otherwise please proceed without.",
                continueNoAdd: "Continue without (add)",
              },
            },
            account: {
              card: {
                description: "Log in and select your prescription.",
              },
              review: {
                title: "Added from my account",
              },
            },
            upload: {
              title: "Your prescription",
              subtitle:
                "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            },
            doctor: {
              title: "Add your optician details",
              searchBy: "Search your optician by",
              card: {
                title: "Call my optician",
              },
            },
            fromMyAccount: {
              uploadedOn: "Uploaded on ",
              card: {
                title: "Get it from my account",
                description: "Log in and select your prescription.",
              },
              review: {
                title: "Added from my account",
              },
              title: "Select your prescription",
              subtitle:
                "Choose the preferred prescription and we’ll take care of the rest. \n Can’t find it?",
              subtitleAdd: "Add a new prescription",
              prescriptionName: "Prescription name",
              uploadedOn: "Last updated: ",
              selectButton: "Select prescription",
              savePrescriptionInAccount: "Save prescription in My Account",
              newPrescriptionNameError:
                "Prescription name field cannot be empty",
              olderThanYearBanner:
                "This prescription was uploaded 1 year ago. Please check if it is still valid.",
              showPrescription: "show prescription",
              prescriptionDetailModal: {
                title: "Your prescription details",
              },
              issueDate: "Issue date",
            },
          },
          type: {
            title: "Choose your vision need",
            addToCart: "Add to cart",
            moreInfo: "More info",
          },
          brand: {
            title: "Choose your lens brand",
            confirmedTitle: "Lens brand:",
          },
          treatmentsFamily: {
            sun: {
              description: "Choose from different lens colours and tints.",
            },
          },
          gvpTreatment: {
            title: "Choose your Great Value Package",
            subtitle:
              "Your glasses will come with best-in-class LensCrafters technology.",
            confirmedTitle: "Lens Package:",
          },
          thickness: {
            confirmedTitle: "Thickness:",
            title: "Choose your lens thickness",
            subtitle:
              "Your glasses will come with best-in-class LensCrafters technology.",
          },
          treatments: {
            addToBag: "Add to bag",
            title: "Choose your light responsive treatment",
            extraTreatments: {
              antiScratch: {
                title: "Scratch resistant",
              },
              antiReflective: {
                value: "Anti-reflective",
              },
              uvProtection: {
                title: "UV Protection",
              },
            },
          },
          transitionColor: {
            title: "TransitionColour",
          },
          lensColor: {
            confirmedTitle: "Colour:",
            detailBox: {
              lightTransmission: "Light Transmission",
              lightConditions: "Light conditions",
              contrast: "Contrast",
              baseLensColor: "Base Lens Colour",
            },
            categories: {
              "Ugly category": {
                title: "Ugly Category",
              },
              "Cool category": {
                title: "Cool Category",
              },
            },
          },
          color: {
            confirmedTitle: "Colour:",
            title: "Choose your lens colour",
            titleMobile: "Choose your lens colour",
            titleDesignTypeLayout: "Choose your lens technology",
            titleColorCategoryLayout: "Choose your lens type",
            polarized: "Polarised only",
            subtitle: "Enhanced colour and contrast so you can see more detail",
          },
          addOns: {
            reviewTitle: "Finishes",
          },
          review: {
            title: "Review your selections",
            insuranceButtonLabel: "Add insurance benefits",
            insuranceButtonLabelRemove: "Remove insurance benefits",
          },
        },
        prescription: {
          toggleLabel:
            "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
          prescriptionTitle:
            "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
          rightEye: "(Right eye)",
          leftEye: "(Left eye)",
          readPrescription: "How to read your prescription",
          addLabel: "I also have an ADD value",
          applyButton: "Apply & continue",
          submitButton: "Submit",
          clearButton: "Clear all",
          skipButton: "Skip for now",
          compatibleFramesButton: "Shop compatible frames",
          doctorDetails:
            "We'll ask for a copy of your prescription or doctor's details during or after checkout",
          missingValueError: "Add your values to continue",
          positiveNegativeError:
            "You've entered one positive value and one negative value, which is quite rare. We suggest you double check your prescription.",
          compatibleFrameError:
            "SSorry! The frame you've chosen doesn't support your prescription values.",
          compatibleFrameSubLabel:
            "No worries, we've got frames to suit your prescription.",
          axisError: "The AXIS values you've inserted are not correct!",
          axisSubError: "These values must be between 0 and 180.",
          axisSubErrorZero:
            "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
          editLabel: "Edit prescription",
          pdHelp: "What is this?",
          twoPDNumbers: "I have two PD numbers",
          missingInputMessage:
            "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
          defaultInputMessage:
            "Choose to add your prescription now or upload it later.",
          skipMessage: "",
          readyMessage: "We are now able to suggest the right lens for you!",
          bestPrescription: "Best for your prescription",
          proceedCheckboxError: "Required to proceed",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Frame + lenses price",
            frame: "Frame price",
          },
          free: "Free",
          included: "Included",
          frameLensStartingAt: "Frame + Lenses at",
          frameLens: "Frame + Lenses",
          startingAt: "Starting at",
        },
        learnMoreMade: "See how it's made",
        learnMoreTreatment: "See the treatment",
        exitTitle: "Are you sure you want to exit?",
        exitSubtitle: "Your lens selection will not be saved",
        exitContinueEditing: "No, continue",
        exitSave: "Save and continue shopping",
        insuranceButtonLabel: "Add insurance benefits",
        insuranceButtonLabelRemove: "Remove insurance benefits",
        insuranceSyncedLabel: "Insurance is synced",
        applyingInsurance: "Applying insurance benefits",
      },
      en_CH: {
        steps: {
          prescription: {
            title: "Add your prescription values",
            description:
              "Or we will suggest the right lens for your vision needs, so you can go hassle free",
          },
          advancedPrescription: {
            title: "Do you have a prescription?",
            manual: {
              card: {
                description:
                  "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
              },
              rightEye: {
                name: "(Right eye)",
              },
              leftEye: {
                name: "(Left eye)",
              },
              alerts: {
                axisRangeError:
                  "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
                axisZeroError:
                  "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
                incompatibleWithPrescriptionBody:
                  "but don’t worry, compatible frames are available",
                agreementText:
                  "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
              },
              continueModal: {
                title: "Send prescription later",
                message:
                  "By clicking continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
                continue: "Yes, continue",
                cancel: "No, go back",
                titleNoAdd: "ARE YOU SURE YOU WANT TO CONTINUE?",
                messageNoAdd:
                  "Please make sure to enter the Addition (ADD) value if it is listed in your prescription, otherwise please proceed without.",
                continueNoAdd: "Continue without (add)",
              },
            },
            account: {
              card: {
                description: "Log in and select your prescription.",
              },
              review: {
                title: "Added from my account",
              },
            },
            upload: {
              title: "Your prescription",
              subtitle:
                "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            },
            doctor: {
              title: "Add your optician details",
              searchBy: "Search your optician by",
              card: {
                title: "Call my optician",
              },
            },
            fromMyAccount: {
              uploadedOn: "Uploaded on ",
              card: {
                title: "Get it from my account",
                description: "Log in and select your prescription.",
              },
              review: {
                title: "Added from my account",
              },
              title: "Select your prescription",
              subtitle:
                "Choose the preferred prescription and we’ll take care of the rest. \n Can’t find it?",
              subtitleAdd: "Add a new prescription",
              prescriptionName: "Prescription name",
              uploadedOn: "Last updated: ",
              selectButton: "Select prescription",
              savePrescriptionInAccount: "Save prescription in My Account",
              newPrescriptionNameError:
                "Prescription name field cannot be empty",
              olderThanYearBanner:
                "This prescription was uploaded 1 year ago. Please check if it is still valid.",
              showPrescription: "show prescription",
              prescriptionDetailModal: {
                title: "Your prescription details",
              },
              issueDate: "Issue date",
            },
          },
          type: {
            title: "Choose your vision need",
            addToCart: "Add to cart",
            moreInfo: "More info",
          },
          brand: {
            title: "Choose your lens brand",
            confirmedTitle: "Lens brand:",
          },
          treatmentsFamily: {
            sun: {
              description: "Choose from different lens colours and tints.",
            },
          },
          gvpTreatment: {
            title: "Choose your Great Value Package",
            subtitle:
              "Your glasses will come with best-in-class LensCrafters technology.",
            confirmedTitle: "Lens Package:",
          },
          thickness: {
            confirmedTitle: "Thickness:",
            title: "Choose your lens thickness",
            subtitle:
              "Your glasses will come with best-in-class LensCrafters technology.",
          },
          treatments: {
            addToBag: "Add to bag",
            title: "Choose your light responsive treatment",
            extraTreatments: {
              antiScratch: {
                title: "Scratch resistant",
              },
              antiReflective: {
                value: "Anti-reflective",
              },
              uvProtection: {
                title: "UV Protection",
              },
            },
          },
          transitionColor: {
            title: "TransitionColour",
          },
          lensColor: {
            confirmedTitle: "Colour:",
            detailBox: {
              lightTransmission: "Light Transmission",
              lightConditions: "Light conditions",
              contrast: "Contrast",
              baseLensColor: "Base Lens Colour",
            },
            categories: {
              "Ugly category": {
                title: "Ugly Category",
              },
              "Cool category": {
                title: "Cool Category",
              },
            },
          },
          color: {
            confirmedTitle: "Colour:",
            title: "Choose your lens colour",
            titleMobile: "Choose your lens colour",
            titleDesignTypeLayout: "Choose your lens technology",
            titleColorCategoryLayout: "Choose your lens type",
            polarized: "Polarised only",
            subtitle: "Enhanced colour and contrast so you can see more detail",
          },
          addOns: {
            reviewTitle: "Finishes",
          },
          review: {
            title: "Review your selections",
            insuranceButtonLabel: "Add insurance benefits",
            insuranceButtonLabelRemove: "Remove insurance benefits",
          },
        },
        prescription: {
          toggleLabel:
            "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
          prescriptionTitle:
            "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
          rightEye: "(Right eye)",
          leftEye: "(Left eye)",
          readPrescription: "How to read your prescription",
          addLabel: "I also have an ADD value",
          applyButton: "Apply & continue",
          submitButton: "Submit",
          clearButton: "Clear all",
          skipButton: "Skip for now",
          compatibleFramesButton: "Shop compatible frames",
          doctorDetails:
            "We'll ask for a copy of your prescription or doctor's details during or after checkout",
          missingValueError: "Add your values to continue",
          positiveNegativeError:
            "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
          compatibleFrameError:
            "Sorry! The frame you've chosen isn't compatible with your prescription.",
          compatibleFrameSubLabel:
            "But don't worry, compatible frames are available.",
          axisError: "Remember to add your AXIS values",
          axisSubError: "These values must be between 0 and 180.",
          axisSubErrorZero:
            "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
          editLabel: "Edit prescription",
          pdHelp: "What is this?",
          twoPDNumbers: "I have two PD values",
          missingInputMessage:
            "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
          defaultInputMessage:
            "Choose to add your prescription now or upload it later.",
          skipMessage: "",
          readyMessage: "We are now able to suggest the right lens for you!",
          bestPrescription: "Best for your prescription",
          proceedCheckboxError: "Required to proceed",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Frame + lenses price",
            frame: "Frame price",
          },
          free: "Free",
          included: "Included",
          frameLensStartingAt: "Frame + Lenses at",
          frameLens: "Frame + Lenses",
          startingAt: "Starting at",
        },
        learnMoreMade: "See how it's made",
        learnMoreTreatment: "See the treatment",
        exitTitle: "Are you sure you want to exit?",
        exitSubtitle: "Your lens selection will not be saved",
        exitContinueEditing: "No, continue",
        exitSave: "Save and continue shopping",
        insuranceButtonLabel: "Add insurance benefits",
        insuranceButtonLabelRemove: "Remove insurance benefits",
        insuranceSyncedLabel: "Insurance is synced",
        applyingInsurance: "Applying insurance benefits",
      },
      en_MX: {
        steps: {
          prescription: {
            title: "Completa tu receta",
            description:
              "Or we will suggest the right lens for your vision need, so you can go hussle free",
          },
          advancedPrescription: {
            title: "Do you have a prescription?",
            manual: {
              clearAll: "Reiniciar",
              card: {
                description:
                  "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
              },
              rightEye: {
                name: "(Ojo derecho)",
              },
              leftEye: {
                name: "(Ojo izquierdo)",
              },
              whatIsIt: "What is PD (Pupillary Distance)",
              alerts: {
                axisRangeError:
                  "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
                axisZeroError:
                  "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
                incompatibleWithPrescriptionBody:
                  "but don’t worry, compatible frames are available",
                agreementText:
                  "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
              },
              continueModal: {
                title: "Send prescription later",
                message:
                  "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
                continue: "Yes, continue",
                cancel: "No, go back",
                titleNoAdd: "ARE YOU SURE YOU WANT TO CONTINUE?",
                messageNoAdd:
                  "Please make sure to enter the Addition (ADD) value if it is listed in your prescription, otherwise please proceed without.",
                continueNoAdd: "Continue without (add)",
              },
            },
            account: {
              card: {
                description: "Log in and select your prescription.",
              },
              review: {
                title: "Added from my account",
              },
            },
            upload: {
              title: "Your prescription",
              subtitle:
                "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
              card: {
                title: "Upload it now",
              },
            },
            doctor: {
              title: "Add your doctor details",
              searchBy: "Search your eye doctor by",
              card: {
                title: "Call my doctor",
              },
            },
            later: {
              card: {
                description:
                  "We'll ask for it after you order, either by uploading it or having us call your doctor.",
              },
            },
            fromMyAccount: {
              card: {
                title: "Get it from my account",
                description: "Log in and select your prescription.",
              },
              review: {
                title: "Added from my account",
              },
              title: "Select your prescription",
              subtitle:
                "Choose the preferred prescription and we’ll take care of the rest. \n Can’t find it?",
              subtitleAdd: "Add a new prescription",
              prescriptionName: "Prescription name",
              uploadedOn: "Last updated: ",
              selectButton: "Select prescription",
              savePrescriptionInAccount: "Save prescription in My Account",
              newPrescriptionNameError:
                "Prescription name field cannot be empty",
              olderThanYearBanner:
                "This prescription was uploaded 1 year ago. Please check if it is still valid.",
              showPrescription: "show prescription",
              prescriptionDetailModal: {
                title: "Your prescription details",
              },
              issueDate: "Issue date",
            },
          },
          type: {
            title: "Selecciona tus necesidades de visión",
            addToCart: "Add to cart",
            moreInfo: "More info",
          },
          brand: {
            title: "Selecciona la marca de tus cristales",
          },
          treatmentsFamily: {
            sun: {
              description: "Choose from different lens colors and tints.",
            },
          },
          gvpTreatment: {
            title: "Choose your Great Value Package",
            subtitle:
              "Your glasses will come with best in class LensCrafters technology.",
            confirmedTitle: "Lens Package:",
          },
          thickness: {
            confirmedTitle: "Grosor:",
            designTypeTitle: "Choose your lens design",
          },
          treatments: {
            title: "Completa tus cristales",
            extraTreatments: {
              antiScratch: {
                title: "Resistente a rayones",
              },
              antiReflective: {
                value: "Tratamiento antirreflejante",
              },
              uvProtection: {
                title: "Protección UV",
              },
            },
          },
          transitionColor: {
            title: "TransitionColor",
          },
          lensColor: {
            confirmedTitle: "Color:",
            detailBox: {
              lightTransmission: "Light Transmission",
              lightConditions: "Light conditions",
              contrast: "Contrast",
              baseLensColor: "Base Lens Color",
            },
            categories: {
              "Ugly category": {
                title: "Ugly Category",
              },
              "Cool category": {
                title: "Cool Category",
              },
            },
          },
          color: {
            confirmedTitle: "Color:",
            title: "Choose your lens color",
            titleMobile: "Choose your lens color",
            titleDesignTypeLayout: "Choose your lens technology ",
            titleColorCategoryLayout: "Choose your lens type",
            polarized: "Polarizados",
            subtitle: "Enhanced color and contrast so you can see more detail",
          },
          addOns: {
            reviewTitle: "Finishings",
          },
          review: {
            paymentInstallments: {
              modal: {
                klarna:
                  "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              },
            },
            insuranceButtonLabel: "Aplicar seguro",
            insuranceButtonLabelRemove: "Elimina beneficios del seguro",
          },
        },
        prescription: {
          toggleLabel:
            "Agrega tus valores de graduación, te ayudaremos a encontrar los cristales perfectos para satisfacer tus necesidades de visión.",
          prescriptionTitle:
            "Agrega tus valores de graduación, te mostraremos los cristales perfectos para satisfacer tus necesidades de visión.",
          rightEye: "(Ojo derecho)",
          leftEye: "(Ojo izquierdo)",
          readPrescription: "Cómo leer tu receta",
          addLabel: "También tengo un valor de adición",
          applyButton: "Aplicar y continuar",
          submitButton: "Enviar",
          clearButton: "Borrar todo",
          skipButton: "Saltar por ahora",
          compatibleFramesButton: "Comprar armazones compatibles",
          doctorDetails:
            "Te pediremos una copia de tu receta o los detalles de tu doctor durante o después de confirmar la compra",
          missingValueError: "Agrega tus valores para continuar",
          positiveNegativeError:
            "Introdujiste un valor positivo y un valor negativo, lo cual no es muy común. Te sugerimos revisar tu receta.",
          compatibleFrameError:
            "¡Lo sentimos! El armazón que seleccionaste no es compatible con los valores de tu receta.",
          compatibleFrameSubLabel:
            "No te preocupes, tenemos armazones que se adaptan a tu receta.",
          axisError: "¡Los valores de EJE que introdujiste son incorrectos!",
          axisSubError: "Estos deben ser entre 0 y 180.",
          axisSubErrorZero:
            "Si el valor de CIL. es más alto o bajo que 0, los valores de EJE no pueden ser 0.",
          editLabel: "Editar receta",
          pdHelp: "¿Qué es esto?",
          twoPDNumbers: "Tengo dos números de DIP",
          missingInputMessage:
            "Agrega los valores faltantes para completar la carga de tu receta y te recomendaremos los cristales más adecuados.",
          defaultInputMessage:
            "Agrega tus valores de ESF. y CIL. y te recomendaremos los cristales más adecuados para tus necesidades de visión.",
          skipMessage:
            "Más adelante, te pediremos completar o cargar tu receta",
          readyMessage:
            "¡Ya te podemos sugerir los cristales adecuados para ti!",
          bestPrescription: "Los mejores para tu receta",
          proceedCheckboxError: "Necesario para continuar",
        },
        price: {
          totalFrameLens: {
            frame: "Precio del armazón",
          },
          free: "Gratis",
          included: "Incluido",
          frameLensStartingAt: "Armazón + micas en",
          frameLens: "Armazón + micas",
          startingAt: "A partir de",
        },
        learnMoreMade: "Ver cómo se hace",
        learnMoreTreatment: "Ver el tratamiento",
        exitSubtitle: "Tu selección de cristales no se guardará",
        exitContinueEditing: "Seguir editando",
        exitSave: "Guardar y seguir comprando",
        insuranceButtonLabel: "Agregar beneficios del seguro",
        insuranceButtonLabelRemove: "Elimina beneficios del seguro",
        insuranceSyncedLabel: "Se aplicaron los beneficios del seguro",
        applyingInsurance: "Aplicar beneficios del seguro",
      },
      es_MX: {
        steps: {
          prescription: {
            prescriptionValues: "Graduación",
            prismValues: "Valores Prismáticos",
            description:
              "O te sugeriremos la mica adecuada para tus necesidades de vista y puedas ir sin problemas",
          },
          alreadyIncluded: {
            confirmedTitle: "Incluye:",
          },
          type: {
            prescriptionTitle: "o",
            addToCart: "Agrega al carrito",
            moreInfo: "Más información",
          },
          brand: {
            title: "Selecciona la marca de tus cristales",
            confirmedTitle: "Marca de micas",
          },
          thickness: {
            confirmedTitle: "Grosor",
            subtitle:
              "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
          },
          gvpTreatment: {
            subtitle:
              "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
            confirmedTitle: "Paquete de mica",
          },
          treatments: {
            title: "Completa tus cristales",
            confirmedTitle: "Tratamiento",
            extraTreatments: {
              antiScratch: {
                name: "Antirayones",
                title: "Resistente a rayones",
              },
              antiReflective: {
                value: "Tratamiento antirreflejante",
              },
              uvProtection: {
                title: "Protección UV",
              },
            },
          },
          transitionColor: {
            title: "Color de transición",
            indoor: "INTERIORES",
            outdoor: "EXTERIORES",
          },
          lensColor: {
            confirmedTitle: "Color:",
            detailBox: {
              lightTransmission: "Transmisión de luz",
              lightConditions: "Condiciones de luz",
              contrast: "Contraste",
              baseLensColor: "Color de mica de base",
            },
            categories: {
              "Ugly category": {
                title: "Categoría Ugly",
              },
              "Cool category": {
                title: "Categoría Cool",
              },
            },
          },
          color: {
            confirmedTitle: "Color:",
            title: "Selecciona el color de la mica",
            titleMobile: "Selecciona el color de la mica",
            titleDesignTypeLayout: "Selecciona la tecnología de la mica",
            titleColorCategoryLayout: "Selecciona el tipo de la mica",
            polarized: "Solo polarizado",
            applyAndContinue: "Aplicar",
            subtitle:
              "Color y contraste mejorados para que puedas ver más detalles.",
            tooltip: {
              blueLight:
                "Reduce la exposición a la luz azul de las pantallas digitales y los rayos del sol, lo que puede ayudar a reducir la fatiga ocular.",
            },
          },
          review: {
            lensRecapTitle: "Lentes:",
            discountTitle:
              "Se aplica automáticamente un 40 % de descuento en micas graduadas",
            firstLineReview: "Armazón:",
            insuranceButtonLabel: "Aplicar seguro",
            insuranceButtonLabelRemove: "Elimina beneficios del seguro",
            oneYearCoverageAdd: "Agregar",
          },
          advancedPrescription: {
            title: "¿Tienes una graduación?",
            confirmedTitle: "Graduación",
            manual: {
              clearAll: "Reiniciar",
              pupillaryDistanceMisurePDAction: "mide tu Distancia Pupilar.",
              pupillaryDistanceSubtitle:
                "Puedes seleccionar los ajustes predeterminados de <b>63</b> para armazones de adultos y <b>54</b> para armazones de niños.",
              pupillaryDistanceWarningValue:
                "Ingresa tu distancia pupilar; si no la tienes, puedes usar la predeterminada que aparece arriba o puedes",
              addPrismValues: "Agregar valores de prisma",
              addPlaceholder: "Ninguna",
              rightEye: {
                initials: "OD",
                name: "(Ojo derecho)",
              },
              leftEye: {
                initials: "OI",
                name: "(Ojo izquierdo)",
              },
              howToReadModal: {
                title: "Cómo leer tu graduación",
                subtitle:
                  "Si necesitas lentes de vista graduados, la prescripción generalmente incluirá la siguiente información:",
                infoText:
                  "Si alguno de estos datos no está incluido en tu prescripción, puedes dejar el campo en blanco.",
                rightEye: {
                  name: "OD (Ojo derecho)",
                  description:
                    'OD es la abreviatura de "oculus dexter" que significa "ojo derecho" en latín.',
                },
                leftEye: {
                  name: "OI (Ojo izquierdo)",
                  description:
                    'OS es la abreviatura de "oculus sinister" que significa "ojo izquierdo" en latín.',
                },
                sphere: {
                  name: "Esfera (ESF) - también conocido como Potencia (PWR)",
                  description:
                    "Significan lo mismo: los valores de la graduación. Si tienes miopía, habrá un signo menos (-) junto a los valores, y si tienes hipermetropía, habrá un signo más (+).",
                },
                cylinder: {
                  description:
                    "Esto corresponde a las personas con astigmatismo. Esto es cuando una parte del ojo necesita mayor corrección que el resto. El valor de cilindro se escribe con un signo menos (-).",
                },
                axis: {
                  description:
                    "Esto corresponde solo a las personas con astigmatismo. El eje es el número entre 0 y 180 y determina la orientación del cilindro (CYL).",
                },
                add: {
                  description:
                    "Esto indica el poder adicional de aumento que se agrega a la lente para corregir la presbicia, una condición común relacionada con la edad que afecta la visión cercana.",
                },
                customerService:
                  "¿Tienes alguna duda? Llama a nuestros expertos de Atención al Cliente al <a>XXXXXXXXXX</a>",
              },
              manualPreviewTitle: "Datos de tu receta",
              moreOptions: "Más opciones",
              commentsTitle: "Comentarios",
              title: "Ingresa tu graduación",
              subtitle:
                "Agrega los valores de tu graduación y te recomendaremos el mejor tipo de lente para tus necesidades visuales.",
              review: {
                title: "Se ingresan manualmente",
              },
              card: {
                title: "Ingrésala manualmente",
                description:
                  "Este es el método más rápido y popular. Te sugeriremos el mejor tipo de lente para ti.",
              },
              sphere: "Esfera (ESF)",
              cylinder: "Cilindro (CYL)",
              axis: "Eje",
              add: "Adición",
              vertical: "Vertical (Δ)",
              baseDirection: "Dirección base",
              horizontal: "Horizontal (Δ)",
              pupillaryDistance: "Distancia Pupilar",
              pdLeft: "Izquierda",
              pdRight: "Derecha",
              iHaveTwoPd: "Tengo dos dp",
              applyButton: "Continuar",
              howToRead: "Cómo leer tu receta",
              whatIsIt: "¿Qué es DP (Distancia Pupilar)?",
              pupillaryDistanceWarningTooltip:
                "Escribe un texto para esta descripción emergente",
              pupillaryDistanceWarningValue:
                "Ingresa tu distancia pupilar; si no la tienes, puedes usar la predeterminada que aparece arriba o puedes",
              pupillaryDistanceMisurePDAction: "Medir tu DP",
              moreOptions: "Más opciones",
              incompatibleLenses:
                "Lo sentimos, la graduación que ha introducido no es compatible con nuestras lentes ofrecidas en línea. Busca una tienda cerca de ti o ponte en contacto con nuestro equipo de Atención al cliente para obtener más información.",
              alerts: {
                pdValueWarningDigitalOptometrySmaller:
                  "La PD seleccionada es menor que el promedio; sugerimos revisar la prescripción. Si no la tienes, puedes agregar los valores indicados anteriormente o puedes",
                pdValueWarningDigitalOptometryLarger:
                  "La PD seleccionada es mayor que el promedio; sugerimos revisar la prescripción. Si no la tienes, puedes agregar los valores indicados anteriormente o puedes",
                axisRangeError:
                  "Al parecer, los valores de EJE que has introducido son incorrectos. Los valores deberían estar entre 1 y 180.",
                axisZeroError:
                  "Al parecer, los valores de EJE que has introducido son incorrectos. Si el valor CYL es mayor o menor que 0, los valores de EJE no pueden ser 0.",
                missingValues: "Completa los valores que faltan",
                shopCompatible: "Compra los armazones compatibles",
                clearAll: "Borra todo",
                pdValueWarning:
                  "La DP seleccionada supera el intervalo normal, te sugerimos que compruebes dos veces tu graduación. Puedes dejar el ajuste por defecto de 63 para adultos o 54 para niños. Si necesitamos más información, uno de nuestros expertos se pondrá en contacto contigo.",
                pdValueWarning2:
                  "La DP seleccionada es menor que el promedio, te sugerimos que vuelvas a comprobar tu graduación. Si no la tienes, puedes agregar los valores que aparecen arriba o puedes",
                sphPositiveNegativeWarning:
                  "<b>Has ingresado 1 valor positivo y 1 negativo, lo que es muy raro.</b><br>Te sugerimos que vuelvas a comprobarlo con tu graduación antes de continuar.",
                missingBaseDir: "Elige la dirección de la base de tu prisma",
                missingPrismValues: "Elige el valor del prisma",
                requiredToProceed: "Esto es necesario para continuar",
                incompatibleWithPrescriptionTitle:
                  "¡Lo sentimos! Este armazón no es adecuado para tu graduación.",
                incompatibleWithPrescriptionBody:
                  "pero no te preocupes, tenemos disponibles armazones compatibles",
                incompatibleWithPackagesTitle:
                  "Lo sentimos, esta graduación no es compatible con los lentes disponibles para este tipo de armazón.",
                shopCompatible: "Encuentra el armazón adecuado",
                clearAll: "Borrar todo",
                agreementText:
                  "Al hacer clic en esta casilla, confirmo que los valores de graduación introducidos anteriormente proceden de una graduación válida (no caducada) que se me ha expedido, con la firma de un optometrista u oftalmólogo titulado.",
              },
              seeDetails: {
                right: "Derecho",
                left: "Izquierdo",
                axis: "Eje",
                sphere: "SPH",
                cylinder: "CYL",
                add: "ADD",
                pd: "DP",
                od: "OD",
                os: "OS",
                vertical: "Prisma vertical (Δ)",
                baseDirection: "Dirección de la base",
                horizontal: "Prisma horizontal (Δ)",
              },
              samePrescriptionBothEyes:
                "Usar la misma graduación para ambos ojos.",
              continueModal: {
                continue: "Continuar",
                cancel: "Volver atrás",
                titleNoAdd: "¿ESTÁS SEGURO DE QUE QUIERES CONTINUAR?",
                messageNoAdd:
                  "Asegúrate de ingresar el valor de Adición (ADD) si aparece en tu receta, de lo contrario procede sin él.",
                continueNoAdd: "CONTINUAR SIN(ADD)",
                title: "Enviar graduación más tarde",
                message:
                  "Al hacer clic en Continuar, te saltarás el paso de la graduación. Te pediremos que la subas cuando hayas completado tu pedido.",
              },
              digitalOptometry: {
                title: "Cómo medir tu DP",
                appSubtitle:
                  "Tu distancia pupilar, o DP, es la distancia en milímetros (mm) desde el centro de una pupila al centro de la otra. Indica exactamente a través de qué parte del lente miras, lo que garantiza una comodidad y claridad óptimas. Puedes medirla con la aplicación o manualmente.",
                manuallySubtitle:
                  "Tu distancia pupilar, o DP, es la distancia en milímetros (mm) desde el centro de una pupila al centro de la otra. Puedes medirla con la aplicación o de forma manual.",
                toggle: {
                  app: "con la app",
                  manually: "de forma manual",
                },
                opthyDesc:
                  "<b>Logra el ajuste perfecto de tus lentes con Opthy.</b><br/><p>Opthy mide tu Distancia Pupilar, lo que te permite encontrar el ajuste más cómodo y personalizado.</p><p>Aplicación disponible en iPhone X y versiones superiores.</p>",
                manuallyContent:
                  "<div>Si quieres, puedes medir tú mismo tu DP siguiendo estos pasos: </div><ul><li>Agarra una regla pequeña con una mano y ten a la mano lápiz y papel.</li><li>Ahora colócate a unos 20 cm (8 pulgadas) de un espejo. </li><li>Cierra el ojo izquierdo y alinea el 0 sobre el centro de la pupila derecha.</li><li>Mide la distancia entre la pupila derecha y la izquierda.</li><li>El número que se alinee directamente sobre la pupila izquierda es tu DP (la DP promedio de un adulto oscila entre 58 y 65).</li></ul>",
              },
              issueDate: {
                label: "Fecha de emisión (MM/AAAA)",
                missingError: "Ingresa la fecha",
                formatError: "La fecha ingresada no es válida",
                tooltipText:
                  "Antes de enviar tu receta, verifica la fecha de vencimiento para asegurarte de que aún sea actual y válida. Solo aceptamos recetas que hayan sido emitidas en los últimos 2 años. Al marcar la casilla y continuar, aceptas nuestra política de privacidad y condiciones de uso.",
              },
            },
            californian: {
              chooseTitle: "ELIGE UNA MANERA PARA ENVIAR TU PRESCRIPCIÓN",
              info: "Ten en cuenta que al agregar los valores de la graduación manualmente, los residentes de California también deben enviar por medios electrónicos una prescripción válida. Cárgala para continuar.",
              uploadDesc:
                "Aceptamos los siguientes formatos: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (máx. 10 MB) y páginas de IWork.",
              callMyDoc: "Llamar a mi médico",
              requiredLabel: "* Campo obligatorio",
            },
            account: {
              card: {
                title: "Obtenerlo de mi cuenta",
                description: "Inicia sesión y selecciona tu receta",
              },
              review: {
                title: "Cargado desde la cuenta",
              },
            },
            upload: {
              card: {
                description:
                  "Y nosotros nos encargaremos del resto Aceptamos los siguientes formatos: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (10 MB máx.) y iWork Pages",
              },
              alerts: {
                agreementText:
                  "Al hacer clic en esta casilla, confirmo que los valores de graduación introducidos anteriormente proceden de una graduación válida (no caducada) que se me ha expedido, con la firma de un optometrista u oftalmólogo titulado.",
              },
            },
            doctor: {
              title: "Agrega la información de tu doctor",
              subtitle: "Obtendremos los detalles de tu receta con él/ella",
              searchBy: "Busca a tu oculista por",
              card: {
                title: "Llama a mi doctor",
                description: "Obtendremos tu receta con él/ella",
              },
            },
            later: {
              card: {
                title: "Envíala más tarde",
              },
            },
            fromMyAccount: {
              card: {
                title: "Obtenerla de mi cuenta",
                description: "Inicia sesión y selecciona tu receta.",
              },
              review: {
                title: "Se cargó desde la cuenta",
              },
              title: "Selecciona tu receta",
              subtitle:
                "Elige la receta preferida y nosotros nos encargaremos del resto. \n ¿No la puedes encontrar?",
              subtitleAdd: "Agrega una nueva receta",
              prescriptionName: "Nombre de la receta",
              uploadedOn: "Cargada el",
              selectButton: "Seleccionar receta",
              savePrescriptionInAccount: "Guarda la receta en mi cuenta",
              newPrescriptionNameError:
                "El campo de la receta no puede estar vacío",
              olderThanYearBanner:
                "Esta receta se cargó hace 1 año. Verifica si aún es válida.",
              showPrescription: "muestra la receta",
              prescriptionDetailModal: {
                title: "Tu receta",
                edit: "Editar receta",
              },
            },
          },
          treatmentsFamily: {
            confirmedTitle: "Tipo de mica",
            title: "Selecciona el tipo de mica",
            blueLight: {
              title: "Filtro de luz azul",
              description:
                "Reduce la exposición a la luz azul de las pantallas digitales, lo que puede ayudar a prevenir la fatiga ocular.",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "Un par para interiores y exteriores con micas Transitions®: se oscurecen rápidamente y se vuelven transparentes, para que nunca tengas que cambiar de lentes.",
              imageFileName: "",
            },
            clear: {
              title: "Transparente",
              imageFileName: "",
            },
            sun: {
              title: "Sol",
              description: "Elige entre diferentes colores y tonos de micas.",
              imageFileName: "",
            },
          },
          addOns: {
            label: "Ya está incluido con tu mica",
            reviewTitle: "Acabados",
          },
        },
        confirmButton: "Continuar",
        vmmv: {
          backButton: "Volver al producto",
        },
        prescription: {
          toggleLabel:
            "Agrega tus valores de graduación, te ayudaremos a encontrar los cristales perfectos para satisfacer tus necesidades de visión.",
          prescriptionTitle:
            "Agrega tus valores de graduación, te mostraremos los cristales perfectos para satisfacer tus necesidades de visión.",
          rightEye: "(Ojo derecho)",
          rightEyeInitials: "OD",
          leftEye: "(Ojo izquierdo)",
          leftEyeInitials: "OS",
          readPrescription: "Cómo leer tu receta",
          addLabel: "También tengo un valor de adición",
          applyButton: "Aplicar y continuar",
          submitButton: "Enviar",
          clearButton: "Borrar todo",
          skipButton: "Saltar por ahora",
          compatibleFramesButton: "Comprar armazones compatibles",
          doctorDetails:
            "Te pediremos una copia de tu receta o los detalles de tu doctor durante o después de confirmar la compra",
          missingValueError: "Agrega tus valores para continuar",
          positiveNegativeError:
            "Introdujiste un valor positivo y un valor negativo, lo cual no es muy común. Te sugerimos revisar tu receta.",
          compatibleFrameError:
            "¡Lo sentimos! El armazón que seleccionaste no es compatible con los valores de tu receta.",
          compatibleFrameSubLabel:
            "No te preocupes, tenemos armazones que se adaptan a tu receta.",
          axisError: "¡Los valores de EJE que introdujiste son incorrectos!",
          axisSubError: "Estos deben ser entre 0 y 180.",
          axisSubErrorZero:
            "Si el valor de CIL. es más alto o bajo que 0, los valores de EJE no pueden ser 0.",
          editLabel: "Editar receta",
          pdHelp: "¿Qué es esto?",
          twoPDNumbers: "Tengo dos números de DIP",
          missingInputMessage:
            "Agrega los valores faltantes para completar la carga de tu receta y te recomendaremos los cristales más adecuados.",
          defaultInputMessage:
            "Agrega tus valores de ESF. y CIL. y te recomendaremos los cristales más adecuados para tus necesidades de visión.",
          skipMessage:
            "Más adelante, te pediremos completar o cargar tu receta",
          readyMessage:
            "¡Ya te podemos sugerir los cristales adecuados para ti!",
          bestPrescription: "Los mejores para tu receta",
          proceedCheckbox:
            "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
          proceedCheckboxError: "Necesario para continuar",
        },
        price: {
          totalFrameLens: {
            frame: "Precio del armazón",
            total: "Total",
          },
          free: "Gratis",
          included: "Incluido",
          frameLensStartingAt: "Armazón + micas en",
          frameLens: "Armazón + micas",
          startingAt: "A partir de",
        },
        learnMoreMade: "Ver cómo se hace",
        learnMoreTreatment: "Ver el tratamiento",
        exitSubtitle: "Tu selección de cristales no se guardará",
        exitYes: "Sí, salir",
        exitContinueEditing: "Seguir editando",
        exitSave: "Guardar y seguir comprando",
        insuranceButtonLabel: "Agregar beneficios del seguro",
        insuranceButtonLabelRemove: "Remove insurance benefits",
        insuranceSyncedLabel: "Se aplicaron los beneficios del seguro",
        applyingInsurance: "Aplicar beneficios del seguro",
        frameSize: "Precio del armazón",
        fallbackImageMessageTitle:
          "La vista previa del color de micas no está disponible",
        fallbackImageMessageSubtitle:
          "No te preocupes, recibirás tu armazón con el color de micas que seleccionaste.",
        proceedAsIs: "Proceder tal como están",
        yourSelections: "Tus selecciones",
        editButton: "Editar",
      },
      de_DE: {
        steps: {
          review: {
            insuranceButtonLabel: "Add insurance benefits",
            title: "Auswahl überprüfen",
            discountTitle:
              "- 40% auf Korrektionsgläser werden automatisch abgezogen.",
            firstLineReview: "Rahmen:",
            paymentInstallments: {
              modal: {
                klarna:
                  "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              },
            },
            insuranceButtonLabelRemove: "Versicherungsleistungen entfernen",
            oneYearCoverageAdd: "Hinzufügen",
            seeDetails: "siehe Details",
          },
          mixAndMatch: {
            title: "WÄHLE DEINE GESTELL- & GLASFARBE",
            polarized: "Polarisiert",
          },
          treatments: {
            addToBag: "In den Warenkorb legen",
            title: "Choose your light responsive treatment",
            confirmedTitle: "Behandlungen:",
            extraTreatments: {
              antiReflective: {
                value: "Entspiegelung",
              },
              uvProtection: {
                title: "UV-Filter",
              },
              antiScratch: {
                title: "Kratzfest",
                name: "Kratzschutz",
              },
            },
          },
          prescription: {
            title: "Korrektionsgläser ergänzen",
            description:
              "Oder wir empfehlen Ihnen das richtige Brillenglas für Ihre Sehanforderungen, so dass Sie sich ganz entspannt zurücklehnen können.",
          },
          type: {
            title: "Sehanforderungen auswählen",
            confirmedTitle: "Anwenden",
            prescriptionTitle: "Oder",
            addToCart: "In den Einkaufswagen legen",
            moreInfo: "Mehr Info",
          },
          brand: {
            title: "Brillenglasmarke auswählen",
            confirmedTitle: "Gläsermarke:",
          },
          thickness: {
            title: "Glasstärke auswählen",
            confirmedTitle: "Stärke",
            subtitle:
              "Ihre Brille wird nach dem besten Technologiestandard von LensCrafters veredelt und geliefert.",
          },
          gvpTreatment: {
            title: "Plus-Paket ergänzen",
            subtitle:
              "Ihre Brille wird nach dem besten Technologiestandard von LensCrafters gefertigt.",
            confirmedTitle: "Gläser-Paket:",
          },
          advancedPrescription: {
            title: "Hast du ein Rezept?",
            confirmedTitle: "Mit Sehstärke",
            manual: {
              subtitle: "",
              addPrismValues: "Prismenwerte hinzufügen",
              pupillaryDistanceSubtitle:
                "Du kannst die Standardeinstellungen 61 für Damen und 64 für Herren auswählen, wenn du eine durchschnittliche oder geringe Sehschwäche hast. Wenn du eine starke Sehschwäche hast (oder deine genaue Pupillendistanz wissen möchtest), ",
              pupillaryDistanceMisurePDAction:
                "miss bitte deine Pupillendistanz.",
              add: "Hinzufügen",
              addPlaceholder: "Keine",
              pupillaryDistanceWarningValue:
                "Bitte gib deinen Pupillenabstand ein; wenn du ihn nicht kennst, kannst du die obige Standardeinstellung verwenden oder ",
              review: {
                title: "Manuell eingegeben",
              },
              card: {
                title: "Manuell eingeben",
                description:
                  "Dies ist die schnellste und beliebteste Methode. Wir schlagen dir dann den besten Gläsertyp vor.",
              },
              rightEye: {
                initials: "OD",
                name: "(Rechtes Augen)",
              },
              leftEye: {
                initials: "OS",
                name: "(Linkes Auge)",
              },
              howToReadModal: {
                subtitle:
                  "Wenn du ein Rezept für eine Brille hast, enthalten die Rezeptangaben normalerweise Folgendes:",
                title: "So wird ein Rezept gelesen",
                rightEye: {
                  name: "OD (Rechtes Augen)",
                  description:
                    "OD steht für „Oculus Dexter“, das lateinische Wort für „rechtes Auge“.",
                },
                leftEye: {
                  name: "OS (Linkes Auge)",
                  description:
                    "OS steht für „Oculus Sinister“, das lateinische Wort für „linkes Auge“.",
                },
                sphere: {
                  name: "Sphäre (SPH) - auch bekannt als Power (PWR)",
                  description:
                    "Sie bedeuten dasselbe: den Grad deiner Sehstärke. Wenn du kurzsichtig bist, steht ein Minus (-) vor deinen Werten, wenn du weitsichtig bist, ein Plus (+).",
                },
                cylinder: {
                  name: "Zylinder (ZYL)",
                  description:
                    "Dieser Wert ist für Menschen mit Astigmatismus. In diesem Fall muss ein Teil des Auges stärker korrigiert werden als der Rest. Der Cylinder-Wert wird mit einem Minuszeichen (-) geschrieben.",
                },
                axis: {
                  description:
                    "Dieser Wert ist nur für Menschen mit Astigmatismus. Die Achse ist eine Zahl zwischen 0 und 180 und bestimmt die Ausrichtung des Zylinders (CYL).",
                },
                add: {
                  description:
                    "Dies zeigt die zusätzliche Vergrößerungskraft an, die der Linse hinzugefügt wird, um Presbyopie zu korrigieren, eine häufige altersbedingte Erkrankung, die die Nahsicht beeinträchtigt.",
                },
                infoText:
                  "Wenn eine dieser Informationen nicht in deinem Rezept enthalten ist, kannst du dieses Feld leer lassen.",
                customerService:
                  "Bist du dir bei etwas nicht sicher? Wende dich an unsere Kundenservice-Experten <a>XXXXXXXXXX</a>",
              },
              digitalOptometry: {
                title: "So misst du deinen PD",
                appSubtitle:
                  "Dein Pupillenabstand oder PD ist der Abstand in Millimetern (mm) von der Mitte einer Pupille zur Mitte der anderen. Er gibt genau an, durch welchen Teil des Glases du blickst und sorgt so für optimalen Komfort und klare Sicht. Du kannst ihn mit der App oder manuell messen.",
                manuallySubtitle:
                  "Dein Pupillenabstand oder PD ist der Abstand in Millimetern (mm) von der Mitte einer Pupille zur Mitte der anderen. Du kannst ihn mit der App oder manuell messen.",
                toggle: {
                  app: "Mit der App",
                  manually: "manuell",
                },
                opthyDesc:
                  "<b>Bekomme genau die richtige Brille mit Opthy.</b><br/><p>Opthy misst deinen Pupillenabstand und ermöglicht es dir, das bequemste, am besten passende Modell zu finden.</p><p>App verfügbar auf iPhone X und höher.</p>",
                manuallyContent:
                  "<div>Wenn du möchtest, kannst du deinen PD selbst messen, indem du diesen Schritten folgst:</div><ul><li>Nimm ein kleines Lineal in die Hand und bereite Stift und Papier vor.</li><li>Stelle dich nun etwa 20 cm vor einen Spiegel.</li><li>Schließe dein linkes Auge und richte die 0 über der Mitte deiner rechten Pupille aus.</li><li>Messe den Abstand von der rechten zur linken Pupille.</li><li>Die Zahl, die direkt über deiner linken Pupille liegt, ist dein PD (ein durchschnittlicher PD für einen Erwachsenen liegt zwischen 58 und 65).</li></ul>",
              },
              seeDetails: {
                axis: "Achse",
                sphere: "SPH",
                cylinder: "CYL",
                add: "HINZUFÜGEN",
                pd: "PD",
                od: "OD",
                os: "OS",
                vertical: "Vertikalprisma (Δ)",
                baseDirection: "Grundrichtung",
                horizontal: "Horizontalprisma (Δ)",
              },
              manualPreviewTitle: "Deine Rezeptdetails",
              sphere: "Sphäre (SPH)",
              cylinder: "Zylinder (ZYL)",
              axis: "Achse",
              add: "HINZUFÜGEN",
              vertical: "Vertikal (Δ)",
              baseDirection: "Grundrichtung",
              horizontal: "Horizontal (Δ)",
              pupillaryDistance: "PD (Pupillenabstand)",
              pdLeft: "Links",
              pdRight: "Rechts",
              applyButton: "Weiter",
              howToRead: "So wird ein Rezept gelesen",
              whatIsIt: "Was ist das?",
              iHaveTwoPd: "Ich habe zwei PD",
              pupillaryDistanceSubtitle:
                "Du kannst die Standardeinstellungen von <b>63</b> für Erwachsenengestelle und <b>54</b> für Kindergestelle wählen.",
              pupillaryDistanceWarningTooltip:
                "Text für diesen Tooltip bereitstellen",
              pupillaryDistanceWarningValue:
                "Bitte gib deinen Pupillenabstand ein; wenn du ihn nicht kennst, kannst du die obige Standardeinstellung verwenden oder",
              pupillaryDistanceMisurePDAction: "Deinen PD messen",
              moreOptions: "Mehr Optionen",
              commentsTitle: "Kommentare",
              incompatibleLenses:
                "Es tut uns leid, das von Ihnen eingegebene Rezept ist nicht mit unseren online angebotenen Linsen kompatibel. Finden Sie eine Filiale in Ihrer Nähe oder kontaktieren Sie unseren Kundenservice für weitere Informationen.",
              alerts: {
                pdValueWarningDigitalOptometrySmaller:
                  "Die gewählte PD liegt außerhalb des normalen Bereichs, bitte überprüfe dein Rezept. Wenn du diese Werte nicht hast, kannst du die oben angegebenen Werte hinzufügen oder du kannst ",
                pdValueWarningDigitalOptometryLarger:
                  "Die gewählte PD liegt außerhalb des normalen Bereichs, bitte überprüfe dein Rezept. Wenn du diese Werte nicht hast, kannst du die oben angegebenen Werte hinzufügen oder du kannst ",
                axisRangeError:
                  "Es sieht so aus, als wären die von dir eingegebenen ACHS-Werte falsch. Sie sollten zwischen 1 und 180 liegen.",
                axisZeroError:
                  "Es sieht so aus, als wären die von dir eingegebenen ACHS-Werte falsch. Wenn der CYL-Wert höher oder niedriger als 0 ist, können die ACHS-Werte nicht 0 sein.",
                requiredToProceed:
                  "Sie müssen dieses Kontrollkästchen aktivieren, um fortzufahren.",
                incompatibleWithPrescriptionTitle:
                  "Es tut uns leid! Dieses Gestell eignet sich nicht für dein Rezept.",
                incompatibleWithPrescriptionBody:
                  "Aber keine Sorge, wir haben passende Gestelle im Angebot",
                incompatibleWithPackagesTitle:
                  "Es tut uns leid! Dieses Rezept ist nicht kompatibel mit den für dieses Gestell erhältlichen Gläsern.",
                shopCompatible: "Passende Modelle anzeigen",
                clearAll: "Alle löschen",
                sphPositiveNegativeWarning:
                  "<b>Du hast 1 positiven und 1 negativen Wert eingegeben, was sehr selten ist.</b><br>Wir empfehlen dir, noch einmal dein Rezept zu überprüfen, bevor du fortfährst.",
                pdValueWarning:
                  "Der ausgewählte PD liegt außerhalb des normalen Bereichs; wir empfehlen dir, noch einmal dein Rezept zu überprüfen. Du kannst die Standardeinstellung von 63 für Erwachsene oder 54 für Kinder verwenden. Wenn wir weitere Informationen benötigen, wird sich einer unserer Experten melden.",
                pdValueWarning2:
                  "Der ausgewählte PD liegt außerhalb des normalen Bereichs; wir empfehlen dir, noch einmal dein Rezept zu überprüfen. Du kannst die Standardeinstellung von 63 für Erwachsene oder 54 für Kinder verwenden. Wenn wir weitere Informationen benötigen, wird sich einer unserer Experten melden.",
                missingValues: "Vervollständige die fehlenden Werte",
                requiredToProceed: "Dies ist erforderlich, um fortzufahren",
                shopCompatible: "Finde das richtige Gestell",
                clearAll: "Alles löschen",
                agreementText:
                  "Durch Anklicken dieses Kästchens bestätige ich, dass die oben eingegebenen Rezeptwerte einem gültigen (nicht abgelaufenen) Rezept entnommen sind, das mir von einem zugelassenen Augenarzt ausgestellt wurde.",
                agreementTextMobile:
                  "Durch Anklicken dieses Kästchens bestätige ich, dass die oben eingegebenen Rezeptwerte einem gültigen (nicht abgelaufenen) Rezept entnommen sind, das mir von einem zugelassenen Augenarzt ausgestellt wurde.",
              },
              continueModal: {
                continue: "Weiter",
                cancel: "Zurück",
                titleNoAdd: "BIST DU SICHER, DASS DU FORTFAHREN WILLST?",
                messageNoAdd:
                  "Bitte achte darauf, dass du den Zusatzwert (ADD) eingibst, wenn er in deinem Rezept aufgeführt ist, ansonsten fahre bitte ohne fort.",
                continueNoAdd: "FORTFAHREN OHNE (ADD)",
                title: "Rezept später senden",
                message:
                  "Durch Klicken auf Weiter wird der Rezeptschritt übersprungen. Wir werden dich bitten, es hochzuladen, nachdem du deine Bestellung abgeschlossen hast.",
              },
              issueDate: {
                label: "Ausgestellt am (MM/JJJJ)",
                missingError: "Bitte geben Sie das Datum ein",
                formatError: "Das eingegebene Datum ist nicht gültig",
                tooltipText:
                  "Bevor Sie Ihren Brillenpass einreichen, überprüfen Sie bitte die angegebenen Werte, um sicherzugehen, dass diese aktuell sind. Wir akzeptieren nur Brillenpässe bzw. Angaben zu Sehstärken, die innerhalb der letzten 2 Jahre ausgestellt wurden. Durch Markierung des Kontrollkästchens und Fortsetzen des Vorgangs erklären Sie sich mit unseren Datenschutzbestimmungen und AGB einverstanden.",
              },
            },
            californian: {
              chooseTitle: "WÄHLE, WIE DU DEIN REZEPT SENDEN MÖCHTEST",
              info: "Bitte beachte, dass Einwohner Kaliforniens beim manuellen Hinzufügen von Rezeptwerten auch dazu verpflichtet sind, ihr gültiges Rezept elektronisch zu übermitteln. Bitte Rezept hochladen und fortfahren.",
              uploadDesc:
                "Wir akzeptieren die folgenden Dateiformate: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) und iWork pages.",
              callMyDoc: "Meinen Arzt kontaktieren",
              requiredLabel: "* Gläsermarke",
            },
            account: {
              card: {
                title: "Über mein Konto abrufen",
                description:
                  "Loggen Sie sich ein und wählen Sie Ihren Brillenpass aus.",
              },
              review: {
                title: "Vom Konto geladen",
              },
            },
            upload: {
              card: {
                title: "Jetzt hochladen",
                description:
                  "Um den Rest kümmern wir uns. Es werden folgende Formate akzeptiert: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max. 10 MB) und iWork Pages",
              },
              review: {
                title: "Hochgeladen",
              },
              alerts: {
                agreementText:
                  "Durch Anklicken dieses Kontrollkästchens bestätige ich, dass die oben eingegebenen Korrekturwerte einem aktuellen (nicht abgelaufenen) Brillenpass entnommen sind, der mir von einem Optiker oder Augenarzt ausgestellt wurde.",
              },
              filePreviewTitle: "ihr Brillenpass",
            },
            doctor: {
              title: "Daten zu Ihrem Augenarzt eingeben",
              subtitle:
                "Wir fordern Ihre Korrekturwerte direkt bei ihrem Augenarzt/Ihrer Augenärztin an",
              searchBy: "Augenarzt suchen nach",
              card: {
                title: "Meinen Arzt anrufen",
                description:
                  "Wir fordern Ihre Korrekturwerte direkt bei ihm/ihr an",
              },
            },
            later: {
              card: {
                title: "Später senden",
                description:
                  "Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
              },
            },
            fromMyAccount: {
              card: {
                title: "Über mein Konto abrufen",
                description:
                  "Loggen Sie sich ein und wählen Sie Ihren Brillenpass aus.",
              },
              review: {
                title: "Vom Konto geladen",
              },
              title: "Brillenpass auswählen",
              subtitle:
                "Wählen Sie Ihren aktuellen Brillenpass aus. Um den Rest kümmern wir uns. \n Sie finden ihn nicht?",
              subtitleAdd: "Neuen Brillenpass hinzufügen",
              prescriptionName: "Name des Brillenpasses",
              uploadedOn: "Hochgeladen am",
              selectButton: "Brillenpass auswählen",
              savePrescriptionInAccount:
                "Brillenpass in meinem Konto speichern",
              newPrescriptionNameError:
                "Das Namensfeld für den Brillenpass darf nicht leer sein",
              olderThanYearBanner:
                "Dieser Brillenpass wurde vor einem Jahr hochgeladen. Bitte prüfen, ob die Angaben im Brillenpass noch gültig sind.",
              showPrescription: "Brillenpass anzeigen",
              prescriptionDetailModal: {
                title: "Ihr Brillenpass",
                edit: "Werte bearbeiten",
              },
            },
          },
          color: {
            polarized: "Nur polarisiert",
            confirmedTitle: "Farbe:",
            title: "Brillenglasfarbe auswählen",
            titleMobile: "Brillenglasfarbe auswählen",
            titleDesignTypeLayout: "Brillenglastechnologie auswählen",
            titleColorCategoryLayout: "Brillenglastyp auswählen",
            applyAndContinue: "Anwenden",
            subtitle:
              "Verbesserte Farb- und Kontrastsicht und eine bessere Detailerkennung",
            tooltip: {
              uvProtection:
                "Harte Schutzschichten auf Vorder- und Rückseite der Gläser für maximalen Schutz vor UV-Strahlung und Kratzern.",
              antiReflective:
                "Reduzieren Spiegelungen und dienen als unsichtbarer Schutz. Sie sind blendfrei, staub- und schmutzabweisend und sorgen für klare Sicht.",
              polarized:
                "Verringert die Blendung bei besonders hellem Licht und sorgt so für bessere Klarsicht und Schutz der Augen.",
              switch:
                "Verringert die Blendung bei besonders hellem Licht und sorgt so für bessere Klarsicht und Schutz der Augen.",
              blueLight:
                "Die Belastung durch blaues Licht von digitalen Bildschirmen und Sonnenstrahlen wird reduziert, was zur Verringerung von Augenermüdung beitragen kann.",
            },
          },
          treatmentsFamily: {
            confirmedTitle: "Brillenglastyp:",
            title: "Brillenglastyp auswählen",
            blueLight: {
              title: "Blaufilter",
              description:
                "Verringert die Blaulichtbelastung durch digitale Bildschirme, wodurch eine Ermüdung der Augen verringert werden kann.",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "Ein Modell für drinnen und draußen mit Transitions®-Gläsern: Sie verdunkeln sich schnell und werden wieder farblos, sodass Sie Ihre Brille nicht wechseln müssen.",
              imageFileName: "",
            },
            clear: {
              title: "Klar",
              description:
                "Klassische, transparente Gläser, perfekt für den täglichen Gebrauch",
              imageFileName: "",
            },
            sun: {
              title: "Sonne",
              description:
                "Wählen Sie aus verschiedenen Gläserfarben und -tönungen.",
              imageFileName: "",
            },
          },
          addOns: {
            confirmedTitle: "Ergänzen Sie Ihre Gläser.",
            label: "Bei Ihren Gläsern bereits inklusive",
            title: "Ergänzen Sie Ihre Gläser.",
            reviewTitle: "Veredelungen",
          },
          transitionColor: {
            title: "TransitionColor",
            indoor: "DRINNEN",
            outdoor: "DRAUSSEN",
          },
          lensColor: {
            confirmedTitle: "Farbe:",
            detailBox: {
              lightTransmission: "Lichtdurchlässigkeit",
              lightConditions: "Lichtverhältnisse",
              contrast: "Kontrast",
              baseLensColor: "Grundfarbe der Gläser",
            },
            categories: {
              all: {
                title: "Alle",
              },
              "Ugly category": {
                title: "Kategorie „Ugly“",
              },
              "Cool category": {
                title: "Kategorie „Cool“",
              },
            },
          },
        },
        confirmButton: "Weiter",
        vmmv: {
          backButton: "Zurück zum Produkt",
        },
        prescription: {
          applyButton: "Übernehmen & Weiter",
          toggleLabel:
            "Brillenpass-Werte eingeben. Wir helfen Ihnen dabei, die perfekten Gläser für Ihre Sehanforderungen zu finden",
          prescriptionTitle:
            "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
          rightEye: "(Rechtes Augen)",
          rightEyeInitials: "OD",
          leftEye: "(Linkes Auge)",
          leftEyeInitials: "OS",
          readPrescription: "Brillenpass richtig lesen",
          addLabel: "Ich habe auch einen ADD-Wert",
          submitButton: "Senden",
          clearButton: "Alle löschen",
          skipButton: "Jetzt überspringen",
          compatibleFramesButton: "Passende Modelle anzeigen",
          doctorDetails:
            "Bei oder nach dem Bestellvorgang fragen wir Sie nach einer Kopie Ihres Brillenpasses oder den erforderlichen Werte der Sehstärke",
          missingValueError: "Werte eingeben und weiter",
          positiveNegativeError:
            "Sie haben einen positiven und einen negativen Wert eingeben. Das ist eher ungewöhnlich. Bitte überprüfen Sie Ihre Eingabe.",
          compatibleFrameError:
            "Es tut uns leid! Doch das von Ihnen ausgewählte Modell ist für Ihre Sehstärke nicht erhältlich.",
          compatibleFrameSubLabel:
            "Keine Sorge. Wir haben Modelle, die zu Ihrer Sehstärke passen.",
          axisError:
            "Der von Ihnen eingegebene Wert für die ACHSE ist nicht korrekt!",
          axisSubError: "Der Wert muss zwischen 0 und 180 liegen.",
          axisSubErrorZero:
            "Wenn der Wert für den Zylinder (CYL) größer oder kleiner als 0 ist, kann der Wert für die ACHSE nicht 0 sein.",
          editLabel: "Werte bearbeiten",
          pdHelp: "Was bedeutet das?",
          twoPDNumbers: "Ich habe zwei PD-Werte",
          missingInputMessage:
            "Fügen Sie die fehlenden Werte hinzu, um den Upload Ihrer Sehstärke abzuschließen. Wir empfehlen daraufhin das am besten geeignete Glas.",
          defaultInputMessage:
            "Fügen Sie Ihre SPH- und CYL-Werte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre",
          skipMessage:
            "Wir werden Sie später bitten, Ihre Werte aus dem Brillenpass einzutragen oder hochzuladen.",
          readyMessage:
            "Jetzt können wir Ihnen das richtige Glas für Sie vorschlagen!",
          bestPrescription: "Die beste Auswahl für Ihre Sehanforderungen",
          proceedCheckbox:
            "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
          proceedCheckboxError: "Erforderlich, um fortzufahren",
        },
        price: {
          totalFrameLens: {
            frame: "Preis für den Rahmen",
            total: "Total",
            frameLensTotal: "Preis für Rahmen + Gläser",
          },
          startingAt: "Ab",
          free: "Kostenlos",
          included: "Inklusive",
          frameLensStartingAt: "Rahmen + Gläser bei",
          frameLens: "Rahmen + Gläser",
        },
        noTreatment: "No treatment",
        insuranceButtonLabelRemove: "Versicherungsleistungen entfernen",
        exitTitle: "MÖCHTEN SIE WIRKLICH ABBRECHEN?",
        exitSubtitle: "Ihre Glasauswahl wird nicht gespeichert",
        exitYes: "Änderungen verwerfen",
        exitContinueEditing: "Bearbeitung fortsetzen",
        exitSave: "Speichern und Einkauf fortsetzen",
        applyingInsurance: "Versicherungsleistungen eingeben",
        learnMoreTreatment: "Behandlung ansehen",
        learnMoreMade: "So wird es gemacht",
        insuranceButtonLabel: "Versicherungsleistungen hinzufügen",
        insuranceSyncedLabel: "Insurance is synced",
        fallbackImageMessageTitle: "Farbvorschau für Gläser nicht verfügbar",
        fallbackImageMessageSubtitle:
          "Keine Sorge, Sie erhalten Ihre Fassung mit der von Ihnen gewählten Glasfarbe.",
        proceedAsIs: "Weiter (ohne weitere Auswahl)",
        yourSelections: "Ihre Auswahl",
        editButton: "Bearbeiten",
      },
      de_CH: {
        steps: {
          advancedPrescription: {
            manual: {
              pupillaryDistanceWarningValue:
                "Bitte gib deinen Pupillenabstand ein; wenn du ihn nicht kennst, kannst du die obige Standardeinstellung verwenden oder ",
              alerts: {
                missingValues: "Bitte geben Sie die noch fehlenden Daten ein",
              },
              continueModal: {
                titleNoAdd: "BIST DU SICHER, DASS DU FORTFAHREN WILLST?",
                messageNoAdd:
                  "Bitte achte darauf, dass du den Zusatzwert (ADD) eingibst, wenn er in deinem Rezept aufgeführt ist, ansonsten fahre bitte ohne fort.",
                continueNoAdd: "FORTFAHREN OHNE (ADD)",
              },
            },
          },
        },
        fallbackImageMessageTitle: "Farbvorschau für Gläser nicht verfügbar",
        fallbackImageMessageSubtitle:
          "Keine Sorge, Sie erhalten Ihre Fassung mit der von Ihnen gewählten Glasfarbe.",
        proceedAsIs: "Weiter (ohne weitere Auswahl)",
      },
      it_IT: {
        steps: {
          prescription: {
            title: "Completa la tua prescrizione",
            description:
              "Oppure ti suggeriremo le lenti più adatte alle tue necessità visive e non dovrai preoccuparti più di nulla",
          },
          mixAndMatch: {
            title: "SCEGLI LA TUA MONTATURA E IL COLORE DELLE LENTI",
            polarized: "Polarizzate",
          },
          advancedPrescription: {
            title: "Hai una prescrizione medica?",
            manual: {
              pupillaryDistanceMisurePDAction:
                "misura la tua distanza pupillare.",
              pupillaryDistanceSubtitle:
                "Se necessiti di una gradazione media o bassa, puoi selezionare le impostazioni predefinite (61 per le DONNE e 64 per gli UOMINI). Se necessiti di una gradazione alta (o se vuoi conoscere la tua distanza pupillare), ",
              title: "Inserisci la tua prescrizione medica",
              card: {
                title: "Inserimento manuale",
                description:
                  "È il modo più veloce e usato. Ti suggeriremo noi il tipo di lente più indicato.",
              },
              subtitle:
                "Aggiungi i valori della tua prescrizione medica e ti suggeriremo le lenti più adatte alle tue esigenze visive.",
              rightEye: {
                initials: "OD",
                name: "(Occhio destro)",
              },
              leftEye: {
                initials: "OS",
                name: "(Occhio sinistro)",
              },
              howToReadModal: {
                subtitle:
                  "Le informazioni presenti sulla prescrizione per occhiali da vista, di solito, includono:",
                infoText:
                  "Se alcune di queste informazioni non sono incluse nella prescrizione, è possibile lasciare vuoto il campo.",
                customerService:
                  "Hai dei dubbi? Contatta il nostro Servizio Clienti al <a>XXXXXXXXXX</a>",
                rightEye: {
                  description:
                    "OD sta per “oculus dexter”, che in latino significa “occhio destro”.",
                },
                leftEye: {
                  description:
                    "OS sta per “oculus sinister”, che in latino significa “occhio sinistro”.",
                },
                sphere: {
                  description:
                    "Indicano la stessa cosa: il potere diottrico della tua prescrizione. Se sei miope, avrai un segno meno (-) prima dei valori; se sei ipermetrope, avrai un segno più (+).",
                },
                cylinder: {
                  description:
                    "Questo si applica solo agli astigmatici e si verifica quando una parte dell’occhio necessita di una correzione maggiore rispetto al resto. Il valore del cilindro viene indicato con un segno meno (-).",
                },
                axis: {
                  description:
                    "Dit is alleen voor mensen met astigmatisme. De as is een getal tussen 0 en 180 en bepaalt de oriëntatie van de cilinder (CYL).",
                },
              },
              sphere: "Sfera (SF)",
              cylinder: "Cilindro (CIL)",
              axis: "Asse",
              add: "AGGIUNGI",
              vertical: "Verticale (Δ)",
              baseDirection: "Direzione base",
              horizontal: "Orizzontale (Δ)",
              pupillaryDistance: "Distanza interpupillare (PD)",
              pdLeft: "Sinistra",
              pdRight: "Destra",
              iHaveTwoPd: "Ho 2 numeri PD",
              applyButton: "Continua",
              howToRead: "Come leggere la tua prescrizione oculistica",
              whatIsIt: "Di cosa si tratta?",
              pupillaryDistanceWarningTooltip:
                "Inserire testo per questa descrizione comando",
              moreOptions: "Altre opzioni",
              incompatibleLenses:
                "Siamo spiacenti, la prescrizione inserita non è compatibile con le nostre lenti offerte online. Trovi un negozio più vicino a lei o contatti il nostro Servizio Clienti per maggiori informazioni.",
              alerts: {
                pdValueWarningDigitalOptometrySmaller:
                  "La DP selezionata è inferiore rispetto al range standard. Ti suggeriamo di ricontrollare la prescrizione. Se non ce l'hai, puoi aggiungere i valori scritti sopra oppure puoi",
                pdValueWarningDigitalOptometryLarger:
                  "La DP selezionata è superiore rispetto al range standard. Ti suggeriamo di ricontrollare la prescrizione. Se non ce l'hai, puoi aggiungere i valori scritti sopra oppure puoi",
                axisRangeError:
                  "I valori ASSE che hai inserito non sono corretti. Questi valori devono essere compresi tra 1 e 180.",
                axisZeroError:
                  "I valori ASSE che hai inserito non sono corretti. Se il valore CIL è maggiore o minore di 0, i valori ASSE non possono essere uguali a 0.",
                missingValues: "Inserisci i valori mancanti",
                requiredToProceed: "È obbligatorio per procedere",
                incompatibleWithPrescriptionTitle:
                  "Spiacenti! La montatura scelta non è compatibile con la tua prescrizione medica",
                incompatibleWithPrescriptionBody:
                  "ma non preoccuparti! Abbiamo altre montature adatte",
                shopCompatible: "Acquista montature compatibili",
                clearAll: "Cancella tutto",
                agreementText:
                  "Selezionando questa casella confermo che i valori della prescrizione medica inseriti qui sopra sono presi da una prescrizione medica valida (non scaduta) emessa per me e firmata da un oftalmologo o optometrista autorizzato.",
              },
              continueModal: {
                title: "Invia la prescrizione medica in un secondo momento",
                message:
                  "Cliccando su “Continua” salterai il passaggio della prescrizione medica. Ti verrà richiesta più avanti nel corso dell’evasione del tuo ordine, richiedendoti di caricarla o di metterci in contatto con il tuo medico.",
                continue: "Sì, continua",
                cancel: "No, torna indietro",
                titleNoAdd: "VUOI DAVVERO CONTINUARE?",
                messageNoAdd:
                  "Assicurati di inserire il valore Aggiungi (ADD) se è indicato nella prescrizione, altrimenti procedi senza.",
                continueNoAdd: "CONTINUA SENZA (ADD)",
              },
              issueDate: {
                label: "Data emissione (MM/AAAA)",
                missingError: "Inserisci una data",
                formatError: "La data inserita non è valida",
                tooltipText:
                  "Prima di inviare la tua ricetta, verifica la data di scadenza per accertarti che sia ancora valida e corretta. Accettiamo solamente ricette emesse negli ultimi 2 anni. Spuntando questa casella e proseguendo, presti il tuo consenso alla nostra informativa sulla privacy e ai nostri Termini e Condizioni.",
              },
            },
            californian: {
              chooseTitle: "SCEGLI COME INVIARE LA TUA PRESCRIZIONE",
              info: "Ti ricordiamo che, se sei residente in California, dopo aver aggiunto manualmente i valori della prescrizione, dovrai trasmetterli anche per via elettronica. Procedi al caricamento.",
              uploadDesc:
                "Accettiamo i seguenti formati: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) e pagine IWork.",
              callMyDoc: "Chiama il mio medico",
              requiredLabel: "* Campo obbligatorio",
            },
            fromMyAccount: {
              card: {
                title: "Prendila dal mio account",
                description: "Effettua il log in e seleziona la ricetta.",
              },
              review: {
                title: "Caricata dall’account",
              },
              title: "Seleziona la ricetta",
              subtitle:
                "Scegli la ricetta da applicare e penseremo noi a tutto il resto. \n Non la trovi?",
              subtitleAdd: "Aggiungi ricetta",
              prescriptionName: "Nome ricetta",
              uploadedOn: "Caricata il",
              selectButton: "Selezionare ricetta",
              savePrescriptionInAccount: "Salva la ricetta nel mio account",
              newPrescriptionNameError:
                "Il campo “Nome ricetta” non può essere vuoto",
              olderThanYearBanner:
                "Questa ricetta è stata caricata un anno fa. Verifica che sia ancora valida.",
              showPrescription: "mostra ricetta",
              prescriptionDetailModal: {
                title: "La tua ricetta",
                edit: "Modifica ricetta",
              },
            },
            account: {
              card: {
                title: "Prendila dal mio account.",
                description: "Accedi e seleziona la tua prescrizione medica.",
              },
              review: {
                title: "Caricata dall'account",
              },
            },
            upload: {
              title: "La tua prescrizione",
              subtitle:
                "Prima di inviare la tua prescrizione medica, controlla la data di scadenza per assicurarti che sia ancora valida.",
              card: {
                title: "Caricala ora",
                description:
                  "Al resto penseremo noi. Accettiamo i formati elencati di seguito: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10MB) e documenti iWork",
              },
            },
            doctor: {
              title: "Aggiungi i dati del tuo medico",
              subtitle:
                "Chiederemo la tua prescrizione medica direttamente a lei/lui",
              searchBy: "Cerca il medico per",
              card: {
                title: "Chiama il mio medico",
              },
            },
            later: {
              card: {
                title: "Inviala in un secondo momento",
              },
            },
          },
          type: {
            title: "Seleziona le tue necessità visive",
            confirmedTitle: "Usa",
            prescriptionTitle: "Oppure",
            addToCart: "Aggiungi al carrello",
            moreInfo: "Maggiori informazioni",
          },
          brand: {
            title: "Scegli la marca delle lenti",
            confirmedTitle: "Marca delle lenti",
          },
          treatmentsFamily: {
            confirmedTitle: "Tipo di lente",
            title: "Scegli il tipo di lente",
            blueLight: {
              title: "Filtro anti luce blu",
              description:
                "Riduce l’esposizione alla luce blu degli schermi digitali, prevenendo l’affaticamento degli occhi.",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "Un solo paio sia all’aperto che al chiuso, grazie alle lenti Transitions®: le lenti variano velocemente da più scure a più chiare, e non dovrai mai cambiare gli occhiali.",
              imageFileName: "",
            },
            clear: {
              title: "Trasparenti",
              description:
                "Lenti trasparenti tradizionali, perfette per l’uso quotidiano",
              imageFileName: "",
            },
            sun: {
              title: "Sole",
              description:
                "Scegli tra una vasta gamma di lenti con colori e tonalità diverse.",
              imageFileName: "",
            },
          },
          gvpTreatment: {
            title: "Scegli il tuo Pacchetto Convenienza",
            subtitle:
              "I tuoi occhiali vanteranno la miglior tecnologia LensCrafters.",
            confirmedTitle: "Confezione lenti:",
          },
          thickness: {
            confirmedTitle: "Spessore",
            title: "Scegli lo spessore delle lenti",
            subtitle:
              "I tuoi occhiali vanteranno la miglior tecnologia LensCrafters.",
            designTypeTitle: "Scegli il design delle lenti",
          },
          treatments: {
            addToBag: "Aggiungi al carrello",
            title: "Choose your light responsive treatment",
            confirmedTitle: "Trattamenti",
            extraTreatments: {
              antiScratch: {
                name: "Antigraffio",
                title: "Anti graffio",
              },
              antiReflective: {
                value: "Anti riflesso",
              },
              uvProtection: {
                title: "Protezione UV",
              },
            },
          },
          transitionColor: {
            title: "Colore Transitions",
            indoor: "AL CHIUSO",
            outdoor: "ALL'APERTO",
          },
          lensColor: {
            confirmedTitle: "Colore",
            detailBox: {
              lightTransmission: "Trasmissione della luce",
              lightConditions: "Condizioni di illuminazione",
              contrast: "Contrasto",
              baseLensColor: "Colore lente base",
            },
            categories: {
              "Ugly category": {
                title: "Categoria Ugly",
              },
              "Cool category": {
                title: "Categoria Cool",
              },
            },
          },
          color: {
            confirmedTitle: "Colore",
            title: "Scegli il colore delle lenti",
            titleMobile: "Scegli il colore delle lenti",
            titleDesignTypeLayout: "Scegli la tecnologia delle lenti",
            titleColorCategoryLayout: "Scegli il tipo di lente",
            polarized: "Solo polarizzate",
            applyAndContinue: "Applica",
            subtitle:
              "Colore e contrasto migliorati per una maggiore visibilità dei dettagli",
            tooltip: {
              antiReflective:
                "Riduce i riflessi e offre uno stato protettivo invisibile.  Contrasta l'abbagliamento, la polvere e le macchie, garantendo una visione più chiara.",
              uvProtection:
                "Strati protettivi rigidi sulla parte anteriore e posteriore delle lenti per un'elevata protezione dai raggi UV e una maggiore resistenza ai graffi.",
              switch:
                "Riduzione del riverbero in condizioni di forte luminosità, per donare maggiore nitidezza proteggendo gli occhi.",
              polarized:
                "Riduzione del riverbero in condizioni di forte luminosità, per donare maggiore nitidezza proteggendo gli occhi.",
              blueLight:
                "Riduce l’esposizione ai raggi del sole e alla luce blu degli schermi dei dispositivi digitali, riducendo così l’affaticamento oculare.",
            },
          },
          addOns: {
            confirmedTitle: "Completa le lenti",
            label: "Già incluso nelle lenti",
            title: "Completa le lenti",
            reviewTitle: "Finitura",
          },
          review: {
            title: "Controlla le tue scelte",
            discountTitle:
              "Lo sconto del 40% sulle lenti graduate viene applicato automaticamente",
            firstLineReview: "Montatura:",
            insuranceButtonLabel: "Aggiungi copertura assicurativa",
            insuranceButtonLabelRemove: "Elimina copertura assicurativa",
            oneYearCoverageAdd: "Aggiungi",
          },
        },
        prescription: {
          toggleLabel:
            "Aggiungi i valori della tua prescrizione, ti aiuteremo a trovare la lente perfetta per le tue esigenze visive.",
          prescriptionTitle:
            "Aggiungi i valori della tua prescrizione, ti mostreremo la lente perfetta per le tue esigenze visive.",
          rightEye: "(Occhio destro)",
          rightEyeInitials: "OD",
          leftEye: "(Occhio sinistro)",
          leftEyeInitials: "OS",
          readPrescription: "Come leggere la tua prescrizione oculistica",
          addLabel: "Ho anche un valore ADD",
          applyButton: "Applica e continua",
          submitButton: "Invia",
          clearButton: "Cancella tutto",
          skipButton: "Salta",
          compatibleFramesButton: "Acquista montature compatibili",
          doctorDetails:
            "Ti chiederemo copia della tua prescrizione o delle informazioni mediche durante o dopo la procedura di pagamento",
          missingValueError: "Aggiungi i tuoi valori per continuare",
          positiveNegativeError:
            "Hai inserito un valore positivo e un valore negativo. Questo è piuttosto raro. Per sicurezza, controlla la tua prescrizione.",
          compatibleFrameError:
            "Spiacenti! La montatura che hai scelto non è disponibile per i tuoi valori.",
          compatibleFrameSubLabel:
            "Non preoccuparti, abbiamo altre montature che fanno per te.",
          axisError: "I valori ASSE che hai inserito non sono corretti.",
          axisSubError: "Questi valori devono essere compresi tra 0 e 180",
          axisSubErrorZero:
            "Se il valore CIL è maggiore o minore di 0, i valori ASSE non possono essere uguali a 0.",
          editLabel: "Modifica prescrizione",
          pdHelp: "Che succede?",
          twoPDNumbers: "Ho due numeri PD",
          missingInputMessage:
            "Inserisci i valori mancanti per completare il caricamento della tua prescrizione, e ti suggeriremo le lenti più adatte a te.",
          defaultInputMessage:
            "Aggiungi i tuoi valori SF e CIL e ti suggeriremo le lenti più adatte alle tue esigenze visive.",
          skipMessage:
            "Ti chiederemo di completare o caricare la tua prescrizione successivamente",
          readyMessage: "Ci siamo! Ecco le lenti perfette per te!",
          bestPrescription: "Le migliori per la tua prescrizione",
          proceedCheckbox:
            "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
          proceedCheckboxError: "Campo obbligatorio per procedere",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Prezzo montatura + lenti",
            frame: "Prezzo montatura",
          },
          free: "Gratis",
          included: "Incluso",
          frameLensStartingAt: "Montatura + Lenti a",
          frameLens: "Montatura + Lenti",
          startingAt: "A partire da",
        },
        noTreatment: "Nessun trattamento",
        learnMoreMade: "Scopri la creazione",
        learnMoreTreatment: "Scopri il trattamento",
        exitTitle: "Sicuro di voler uscire?",
        exitSubtitle: "La tua selezione di lenti non sarà salvata",
        exitYes: "Si, esci",
        exitContinueEditing: "No, continua",
        exitSave: "Salva e continua lo shopping",
        insuranceButtonLabel: "Aggiungi copertura assicurativa",
        insuranceButtonLabelRemove: "Elimina copertura assicurativa",
        applyingInsurance: "Applica copertura assicurativa",
        fallbackImageMessageTitle:
          "Anteprima del colore della lente non disponibile",
        fallbackImageMessageSubtitle:
          "Niente paura! Riceverai la montatura con la lente del colore che hai scelto.",
        proceedAsIs: "Procedi con le opzioni selezionate",
        yourSelections: "Scelti da te",
        editButton: "Modifica",
      },
      it_CH: {
        steps: {
          advancedPrescription: {
            title: "Hai una prescrizione?",
            confirmedTitle: "Prescrizione",
            doctor: {
              card: {
                description:
                  "Chiederemo la tua prescrizione medica direttamente a lei/lui.",
              },
            },
            manual: {
              addPrismValues: "Aggiungi valori prismatici",
              pupillaryDistanceWarningValue:
                "Inserisci la distanza pupillare; se non ce l’hai puoi usare l’impostazione predefinita precedentemente menzionata oppure ",
              continueModal: {
                titleNoAdd: "VUOI DAVVERO CONTINUARE?",
                messageNoAdd:
                  "Assicurati di inserire il valore Aggiungi (ADD) se è indicato nella prescrizione, altrimenti procedi senza.",
                continueNoAdd: "CONTINUA SENZA (ADD)",
              },
            },
          },
        },
        price: {
          totalFrameLens: {
            total: "Total",
          },
        },
        insuranceSyncedLabel: "Assicurazione applicata",
      },
      fr_BE: {
        steps: {
          brand: {
            confirmedTitle: "Marque",
          },
          gvpTreatment: {
            confirmedTitle: "Le pack verres :",
          },
          thickness: {
            confirmedTitle: "Épaisseur",
          },
          color: {
            tooltip: {
              antiReflective:
                "Réduit les reflets et fournit une protection visible. Résiste à l'éblouissement, à la poussière et aux taches pour une vision plus claire.",
              uvProtection:
                "Couches de protection dures sur l’avant et l’arrière des verres pour protéger totalement des UV et des rayures.",
            },
          },
          advancedPrescription: {
            title: "Vous avez une ordonnance ?",
            manual: {
              pupillaryDistanceWarningValue:
                "Veuillez saisir votre écart pupillaire. Si vous ne le connaissez pas, vous pouvez utiliser la valeur par défaut ci-dessus ou vous pouvez ",
              continueModal: {
                titleNoAdd: "ÊTES-VOUS SÛR(E) DE VOULOIR CONTINUER?",
                messageNoAdd:
                  "Veuillez saisir la valeur d'addition (ADD) si elle figure sur votre ordonnance, si non continuez sans la saisir.",
                continueNoAdd: "CONTINUER SANS (ADD)",
              },
            },
          },
          mixAndMatch: {
            title: "CHOISIR VOTRE MONTURE ET LA COULEUR DES VERRES",
          },
        },
        insuranceSyncedLabel: "Prestations sociales appliquées",
      },
      fr_CA: {
        steps: {
          prescription: {
            title: "Complétez votre ordonnance'",
          },
          advancedPrescription: {
            manual: {
              title: "Saisissez votre correction",
              card: {
                description:
                  "Il s'agit de notre méthode la plus rapide et la plus populaire. Nous vous recommanderons ensuite le type de verres le plus adapté.",
              },
              subtitle:
                "Ajoutez les valeurs de votre ordonnance et nous vous recommanderons les verres les mieux adaptés à votre vue.",
              commentsTitle: "Comments",
              alerts: {
                sphPositiveNegativeWarning:
                  "<b>Vous avez saisi 1 valeur positive et 1 valeur négative, ce qui est très rare.</b><br>Nous vous conseillons de vérifier votre ordonnance. Si ces valeurs sont correctes, veuillez continuer.",
              },
              continueModal: {
                message:
                  "En cliquant sur continuer, vous sauterez l'étape de l'ordonnance. Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
                cancel: "Non, retourner en arrière",
                title: "Envoyer l'ordonnance plus tard",
                continue: "Oui, continuer",
                titleNoAdd: "ÊTES-VOUS SÛR(E) DE VOULOIR CONTINUER?",
                messageNoAdd:
                  "Veuillez saisir la valeur d'addition (ADD) si elle figure sur votre ordonnance, si non continuez sans la saisir.",
                continueNoAdd: "CONTINUER SANS (ADD)",
              },
            },
            upload: {
              subtitle:
                "Avant d’envoyer votre ordonnance, vérifier la date d'expiration pour vous assurer qu'elle est toujours valable.",
            },
            later: {
              card: {
                description:
                  "Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
              },
            },
          },
          brand: {
            confirmedTitle: "Marque'",
          },
          treatmentsFamily: {
            subtitle:
              "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse.",
          },
          thickness: {
            confirmedTitle: "Épaisseur'",
            title: "Sélectionnez l’épaisseur de vos verres'",
          },
          treatments: {
            subtitle:
              "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse.",
          },
          color: {
            tooltip: {
              antiScratch:
                "Protège la surface de vos verres contre les chocs violents ",
            },
          },
          addOns: {
            subtitle:
              "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse",
          },
          review: {
            subtitle:
              "Vos lunettes seront équipées de la meilleure technologie Ray-Ban.",
            paymentInstallments: {
              installmentsLabel:
                "Payez en plusieurs versements sans intérêt avec ###PAYMENT_TYPE###",
              modal: {
                title: "Payer en plusieurs versements",
                subtitle:
                  "Vous souhaitez échelonner le paiement votre commande avec ###BRAND### ? \\Sélectionnez ###PAYMENT_TYPE### comme mode de paiement au moment de payer afin de régler le montant en plusieurs fois sans intérêts et sans frais cachés.",
                affirm:
                  "Avec ###PAYMENT_TYPE###, payez en ###INSTALLMENTS### versements sans intérêts toutes les deux semaines, sans frais cachés. Les options de paiement sont proposées par ###PAYMENT_TYPE###, sont soumises à une vérification d'admissibilité et peuvent ne pas être disponibles dans tous les États. Résidents de Californie : Les prêts proposés par ###PAYMENT_TYPE### Loan Services, LLC sont accordés ou organisés conformément à une licence de prêteur de la Californie.",
                klarna:
                  "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
                afterpay:
                  "Avec ###PAYMENT_TYPE###, payez en ###INSTALLMENTS### versements sans intérêts toutes les deux semaines, sans frais cachés. Vous devez avoir plus de 18 ans, être résident aux États-Unis et répondre à d'autres critères d'admissibilité pour être admissible. Des frais de retard peuvent s'appliquer. Cliquez ici pour consulter toutes les conditions. Les prêts aux résidents de la Californie sont accordés ou organisés conformément à une licence prévue par loi sur les prêteurs de la Californie (California Finance Lenders Law)",
              },
            },
            eligibleForInsurance:
              "Admissibles par &nbsp;<b>Assurance des soins de la vue</b>",
            visionCareTooltipTitle: "Pourquoi est-ce éligible?",
            visionCareTooltipDesc:
              "Ce produit comprend des lentilles d'ordonnance qui sont admissibles dans le cadre d'un régime d'assurance des soins de la vue.",
            insuranceButtonLabel: "Appliquer l’assurance'",
            tooltip:
              "Assurez vos lunettes avec une garantie étendue pour une réparation et un remplacement sans limitations dans n'importe quel magasin LensCrafters.",
          },
        },
        confirmButton: "Continuer",
        vmmv: {
          backButton: "Revenir au produit",
        },
        prescription: {
          toggleLabel:
            "Ajoutez les valeurs de votre ordonnance, nous vous aiderons à trouver la paire parfaite pour vos besoins'",
          prescriptionTitle:
            "Ajoutez les valeurs de votre ordonnance, nous vous montrerons le verre répondant parfaitement à vos besoins.'",
          readPrescription: "Comment lire votre ordonnance'",
          addLabel: "J’ai aussi une valeur d’addition'",
          applyButton: "Appliquer et continuer'",
          submitButton: "Envoyer'",
          clearButton: "Effacer tout'",
          skipButton: "Ignorer pour l’instant'",
          compatibleFramesButton: "Acheter des montures compatibles'",
          doctorDetails:
            "Nous vous demanderons une copie de votre ordonnance ou les coordonnées de votre médecin pendant ou après le paiement'",
          missingValueError: "Ajoutez vos valeurs pour continuer'",
          positiveNegativeError:
            "Vous avez entré une valeur positive et une valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance.'",
          compatibleFrameError:
            "Désolés ! La monture que vous avez choisie n’est pas compatible avec les valeurs de votre correction.'",
          axisError:
            "'Les valeurs d’AXE que vous avez saisies sont incorrectes !'",
          axisSubError: "Ces valeurs doivent entre 0 et 180.'",
          axisSubErrorZero:
            "Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.'",
          editLabel: "Modifier la correction'",
          twoPDNumbers: "J’ai deux valeurs d'EP'",
          missingInputMessage:
            "Ajoutez les valeurs manquantes pour compléter le téléchargement de votre ordonnance et nous vous recommanderons le verre le plus adapté.'",
          defaultInputMessage:
            "Ajoutez vos valeurs SPH et CYL et nous vous recommanderons le verre le plus adapté aux besoins de votre vue.'",
          skipMessage:
            "Nous vous demanderons de compléter ou de télécharger votre ordonnance plus tard'",
          readyMessage:
            "Nous sommes maintenant en mesure de vous proposer le verre qui vous convient le mieux  !'",
          bestPrescription: "Le meilleur pour votre correction'",
          proceedCheckbox: "Obligatoire pour continuer'",
          proceedCheckboxError: "Ou {0} paiements de {1}{2} avec'",
        },
        price: {
          startingAt: "Prix partant de'",
        },
        learnMoreMade: "Voir comment c’est fait'",
        learnMoreTreatment: "Voir le traitement'",
        exitTitle: "ÊTES-VOUS SÛR DE VOULOIR SORTIR ?'",
        exitSubtitle: "Votre choix de verres ne sera pas enregistré'",
        exitContinueEditing: "Continuer la modification'",
        exitSave: "Enregistrer et continuer le shopping'",
        insuranceButtonLabel: "Ajouter des prestations sociales'",
        insuranceSyncedLabel: "Prestations sociales appliquées'",
        applyingInsurance: "Application des prestations sociales en cours'",
        frameSize: "Frame size",
      },
      fr_FR: {
        steps: {
          prescription: {
            description:
              "Ou nous vous suggérerons les verres les mieux adaptés à votre vue, afin que vous puissiez vous libérer de vos soucis.",
          },
          mixAndMatch: {
            title: "CHOISIR VOTRE MONTURE ET LA COULEUR DES VERRES",
            polarized: "Verres polarisés",
          },
          advancedPrescription: {
            confirmedTitle: "Verres correcteurs",
            title: "Avez-vous une correction ?",
            manual: {
              subtitle:
                "Ajoutez les valeurs de correction qui vous ont été prescrites et nous vous recommanderons le meilleur verre pour vos besoins visuels.",
              pupillaryDistanceMisurePDAction:
                "mesurez votre écart pupillaire.",
              pupillaryDistanceSubtitle:
                "Sélection des paramètres par défaut : 61 FEMME et 64 HOMME avec prescription moyenne/faible. En cas de forte prescription (ou si vous voulez connaître votre écart pupillaire exacte) ",
              addPrismValues: "Ajouter les valeurs de prisme",
              card: {
                title: "Saisissez-la manuellement",
                description:
                  "C'est la méthode la plus rapide et la plus employée. Nous vous proposerons ensuite le type de verres qui vous convient le mieux.",
              },
              rightEye: {
                initials: "OD",
                name: "(Œil droit)",
              },
              leftEye: {
                initials: "OG",
                name: "(Œil gauche)",
              },
              howToReadModal: {
                subtitle:
                  "Si vous avez une ordonnance pour des lunettes de vue, les informations comprennent les éléments suivants :",
                infoText:
                  "Si l’une de ces informations n’est pas incluse dans votre ordonnance, laissez le champ vide.",
                customerService:
                  "Un doute ? Appelez nos experts du service client <a>XXXXXXXXXX</a>",
                rightEye: {
                  description:
                    "OD signifie « oculus dexter » : « œil droit » en latin.",
                },
                leftEye: {
                  description:
                    "OS signifie « oculus sinister » : « œil gauche » en latin.",
                },
                sphere: {
                  description:
                    "Ils signifient la même chose: le degré de correction prescrit. Si vous êtes myope, vous aurez un moins (-) devant des valeurs et si vous êtes hypermétrope, vous aurez un plus (+).",
                },
                cylinder: {
                  name: "Cylinder (CYL)",
                  description:
                    "Pour les personnes atteintes d’astigmatisme. Quand une partie de l'œil requiert une correction plus importante. La valeur Cylindre est écrite avec un moins (-).",
                },
                axis: {
                  description:
                    "Uniquement pour les personnes atteintes d’astigmatisme. L’axe est compris entre 0 et 180 et détermine l’orientation du cylindre (CYL).",
                },
              },
              seeDetails: {
                axis: "Axe",
                sphere: "SPH",
                cylinder: "CYL",
                add: "ADD (addition)",
                pd: "EP",
                od: "OD",
                os: "OG",
                vertical: "Prisme vertical (Δ)",
                baseDirection: "Orientation de la base",
                horizontal: "Prisme horizontal (Δ)",
              },
              sphere: "Sphère (SPH)",
              cylinder: "Cylindre (CYL)",
              axis: "Axe",
              add: "AJOUTER",
              vertical: "Vertical (Δ)",
              baseDirection: "Orientation de la base",
              horizontal: "Horizontal (Δ)",
              pupillaryDistance: "EP (écart pupillaire)",
              pdLeft: "Gauche",
              pdRight: "Droit",
              iHaveTwoPd: "J'ai deaux EP",
              applyButton: "Continuer",
              howToRead: "Comment lire votre ordonnance",
              whatIsIt: "Qu'est-ce que c'est ?",
              pupillaryDistanceWarningValue:
                "Veuillez saisir votre écart pupillaire. Si vous ne le connaissez pas, vous pouvez utiliser la valeur par défaut ci-dessus ou vous pouvez ",
              pupillaryDistanceWarningTooltip:
                "Fournir du texte pour cette info-bulle",
              pupillaryDistanceMisurePDAction: "Mesurer votre EP",
              moreOptions: "Plus d'options",
              commentsTitle: "Commentaires",
              incompatibleLenses:
                "Nous sommes désolés, l'ordonnance que vous avez saisie n'est pas compatible avec nos lentilles proposées en ligne. Trouvez un magasin près de chez vous ou contactez notre service clientèle pour plus d'informations.",
              alerts: {
                sphPositiveNegativeWarning:
                  "<b>Vous avez saisi 1 valeur positive et 1 valeur négative, ce qui est très rare.</b><br>Nous vous conseillons de vérifier votre ordonnance. Si ces valeurs sont correctes, veuillez continuer.",
                pdValueWarningDigitalOptometrySmaller:
                  "EP sélectionné plus petit que la moyenne, veuillez recontrôler votre ordonnance. Si vous ne l’avez pas, vous pouvez ajouter les valeurs écrites ci-dessus",
                pdValueWarningDigitalOptometryLarger:
                  "EP sélectionné est plus grand que la moyenne, veuillez recontrôler votre ordonnance. Si vous ne l’avez pas, vous pouvez ajouter les valeurs écrites ci-dessus",
                axisRangeError:
                  "Il semble que les valeurs AXE que vous avez renseignées sont incorrectes. Elles doivent être comprises entre 1 et 180.",
                axisZeroError:
                  "Il semble que les valeurs AXE que vous avez renseignées sont incorrectes. Si la valeur CYL est supérieure ou inférieure à 0, les valeurs AXE ne peuvent pas être 0.",
                missingValues: "Compléter les valeurs manquantes",
                missingBaseDir:
                  "Veuillez choisir l’orientation de la base de votre prisme",
                requiredToProceed: "Ceci est nécessaire pour continuer",
                incompatibleWithPrescriptionTitle:
                  "Désolé ! Cette monture ne convient pas à la correction qui vous a été prescrite.",
                incompatibleWithPrescriptionBody:
                  "Mais ne vous inquiétez pas, il existe d’autres montures disponibles",
                incompatibleWithPackagesTitle:
                  "Désolé ! La correction qui vous a été prescrite n'est pas compatible avec les verres disponibles pour cette monture.",
                shopCompatible: "Trouver les bonnes montures",
                clearAll: "Tout désélectionner",
                agreementText:
                  "En cochant cette case, je confirme que les valeurs de correction saisies ci-dessus sont tirées d'une ordonnance en cours de validité (non expirée) qui m'a été délivrée, signée par un opticien ou un ophtalmologiste agréé.",
              },
              continueModal: {
                title: "Envoyer l'ordonnance plus tard",
                continue: "Continuer",
                cancel: "Retour",
                titleNoAdd: "ÊTES-VOUS SÛR(E) DE VOULOIR CONTINUER?",
                messageNoAdd:
                  "Veuillez saisir la valeur d'addition (ADD) si elle figure sur votre ordonnance, si non continuez sans la saisir.",
                continueNoAdd: "CONTINUER SANS (ADD)",
                message:
                  "En cliquant sur continuer, vous sauterez l'étape de l’ordonnance. Nous vous demanderons de la télécharger une fois que vous aurez terminé votre commande.",
              },
              digitalOptometry: {
                title: "Comment mesurer votre EP",
                appSubtitle:
                  "Votre écart pupillaire, ou EP, est la distance en millimètres (mm) entre le centre d'une pupille et le centre de l'autre. Il indique exactement à travers quelle partie du verre vous regardez, pour assurer un maximum de confort et de clarté. Vous pouvez le mesurer avec l'application ou manuellement.",
                manuallySubtitle:
                  "Votre écart pupillaire, ou EP, est la distance en millimètres (mm) entre le centre d'une pupille et le centre de l'autre. Vous pouvez le mesurer avec l'application ou manuellement.",
                toggle: {
                  app: "avec l'appli",
                  manually: "manuellement",
                },
                opthyDesc:
                  "<b>Procurez-vous des lunettes parfaites avec Opthy.</b><br/><p>Opthy mesure votre écart pupillaire et vous permet de trouver l'ajustement le plus confortable et le plus personnalisé.</p><p>Application disponible sur iPhone X et supérieur.</p>",
                manuallyContent:
                  "<div>Si vous le souhaitez, vous pouvez mesurer vous-même votre EP en suivant ces étapes :</div><ul><li>Tenez une réglette dans une main et ayez votre crayon et votre papier à portée de main.</li><li>Maintenant, placez-vous à environ 20 cm d'un miroir.</li><li>Fermez votre œil gauche et alignez le 0 sur le centre de votre pupille droite.</li><li>Mesurez la distance entre votre pupille droite et votre pupille gauche.</li><li>Le chiffre qui s'aligne directement au-dessus de votre pupille gauche est votre EP (un EP moyen pour une personne adulte se situe entre 58 et 65).</li></ul>",
              },
              manualPreviewTitle: "Récapitulatif de votre ordonnance",
              issueDate: {
                label: "Date de délivrance (MM/AAAA)",
                missingError: "Veuillez saisir la date",
                formatError: "La date saisie n'est pas valide",
                tooltipText:
                  "Avant d’envoyer votre ordonnance, vérifier la date d'expiration pour vous assurer qu'elle est toujours à jour et valable. Nous n'acceptons que les ordonnances délivrées au cours des deux dernières années. En cochant la case et en continuant ci-dessous, vous acceptez notre politique de confidentialité et nos conditions générales de vente.",
              },
            },
            californian: {
              info: "Lors de l’ajout manuel des valeurs d’ordonnance, les résidents Californiens doivent transmettre leur ordonnance valide par voie électronique. Veuillez la télécharger et continuer.",
              chooseTitle: "CHOIX DE L'ENVOI DE VOTRE ORDONNANCE",
              uploadDesc:
                "Nous acceptons les formats suivants : .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 Mo) et pages IWork.",
              callMyDoc: "Appeler mon médecin",
              requiredLabel: "* Champ obligatoire",
            },
            fromMyAccount: {
              card: {
                title: "La récupérer dans mon compte",
                description: "Connectez-vous et sélectionnez votre ordonnance.",
              },
              review: {
                title: "Téléchargée à partir du compte",
              },
              title: "Choisissez votre ordonnance",
              subtitle:
                "Choisissez l'ordonnance qui convient le mieux et nous nous chargeons du reste. \n Vous ne la trouvez pas ?",
              subtitleAdd: "Ajouter une nouvelle ordonnance",
              prescriptionName: "Nom de l'ordonnance",
              uploadedOn: "Téléchargée en amont le ",
              selectButton: "Sélectionner l'ordonnance",
              savePrescriptionInAccount:
                "Enregistrer l'ordonnance dans mon compte",
              newPrescriptionNameError:
                "Le champ Nom de l'ordonnance ne peut pas être vide",
              olderThanYearBanner:
                "Cette ordonnance a été téléchargée il y a 1 an. Veuillez vérifier si elle est toujours valable.",
              showPrescription: "afficher l'ordonnance",
              prescriptionDetailModal: {
                title: "Votre ordonnance",
                edit: "Modifier l'ordonnance",
              },
            },
            account: {
              card: {
                title: "La récupérer dans mon compte",
                description: "Connectez-vous et sélectionnez votre ordonnance.",
              },
              review: {
                title: "Chargé depuis le compte",
              },
            },
            upload: {
              title: "Votre ordonnance",
              card: {
                title: "Téléchargez-la maintenant",
                description:
                  "Et nous nous chargeons du reste. Nous acceptons les formats de fichier suivants : .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) et pages iWork",
              },
            },
            doctor: {
              title: "Ajoutez les coordonnées de votre médecin",
              subtitle:
                "Nous obtiendrons les détails de votre ordonnance directement de lui/elle.",
              searchBy: "Recherchez votre ophtalmologiste par",
              card: {
                title: "Appelez mon médecin",
                description:
                  "Nous obtiendrons votre ordonnance directement de lui/elle.",
              },
            },
            later: {
              card: {
                title: "Envoyez-la plus tard",
              },
            },
          },
          type: {
            title: "Sélectionnez vos exigences de vue",
            confirmedTitle: "Utilisez",
            prescriptionTitle: "Ou",
            addToCart: "Ajouter au panier",
            moreInfo: "En savoir plus",
          },
          brand: {
            title: "Choisissez la marque de vos verres",
            confirmedTitle: "Marque des verres",
          },
          treatmentsFamily: {
            confirmedTitle: "Type de verres",
            title: "Choisissez le type de verres",
            blueLight: {
              title: "Filtre lumière bleue",
              description:
                "Réduit l'exposition à la lumière bleue des écrans numériques, ce qui peut aider à prévenir la fatigue oculaire.",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "Une seule paire pour l'intérieur et l'extérieur avec les verres Transitions® : ils s'assombrissent et s’éclaircissent rapidement pour devenir clairs, pour que vous n'ayez jamais à changer de lunettes.",
              imageFileName: "",
            },
            clear: {
              title: "Transparents",
              description:
                "Verres transparents conventionnels parfaits pour la vie de tous les jours",
              imageFileName: "",
            },
            sun: {
              title: "Solaires",
              description:
                "Faites votre choix parmi différentes couleurs et teintes de verres.",
              imageFileName: "",
            },
          },
          gvpTreatment: {
            title: "Complétez votre forfait au meilleur prix",
            subtitle:
              "Vos lunettes seront équipées de la meilleure technologie LensCrafters",
            confirmedTitle: "Le pack verres ",
          },
          thickness: {
            subtitle:
              "Vos lunettes seront équipées de la meilleure technologie LensCrafters",
            designTypeTitle: "Choisissez le design de vos verres",
          },
          treatments: {
            addToBag: "Ajouter au panier",
            title: "Complétez vos verres",
            confirmedTitle: "Traitements",
            extraTreatments: {
              antiScratch: {
                name: "Anti-rayures",
                title: "Résistance aux rayures",
              },
              antiReflective: {
                value: "Traitement anti-reflets",
              },
              uvProtection: {
                title: "protection contre les UV et résistance aux rayures",
              },
            },
          },
          transitionColor: {
            title: "Transition teintés",
            indoor: "INTÉRIEUR",
            outdoor: "EXTÉRIEUR",
          },
          lensColor: {
            confirmedTitle: "Couleur",
            detailBox: {
              lightTransmission: "Transmission de la lumière",
              lightConditions: "Conditions de luminosité",
              contrast: "Contraste",
              baseLensColor: "Couleur de base des verres",
            },
            categories: {
              "Ugly category": {
                title: "Catégorie Ugly",
              },
              "Cool category": {
                title: "Catégorie Cool",
              },
            },
          },
          color: {
            confirmedTitle: "Couleur:",
            title: "Choisissez la couleur de vos verres",
            titleMobile: "Choisissez la couleur de vos verres",
            titleDesignTypeLayout: "Choisissez la technologie pour vos verres",
            titleColorCategoryLayout: "Choisissez le type de verres",
            polarized: "Uniquement polarisants",
            applyAndContinue: "Appliquer",
            subtitle:
              "Un meilleur rendu des couleurs et un contraste optimal afin de vous permettre de voir plus de détails",
            tooltip: {
              antiReflective:
                "Réduit les reflets et fournit une protection visible. Résiste à l'éblouissement, à la poussière et aux taches pour une vision plus claire.",
              uvProtection:
                "Couches de protection dures sur l’avant et l’arrière des verres pour protéger totalement des UV et des rayures.",
              switch:
                "Réduit l'éblouissement en cas de lumière très vive, pour une clarté exceptionnelle et une protection de l'œil supérieure",
              polarized:
                "Réduit l'éblouissement en cas de lumière très vive, pour une clarté exceptionnelle et une protection de l'œil supérieure",
              blueLight:
                "Réduit l'exposition à la lumière bleue des écrans numériques et aux rayons du soleil, ce qui peut aider à réduire la fatigue oculaire.",
            },
          },
          addOns: {
            confirmedTitle: "Complétez vos verres",
            label: "Déjà compris avec vos verres",
            title: "Complétez vos verres",
            reviewTitle: "Finitions",
          },
          review: {
            title: "Vérifiez vos choix",
            discountTitle:
              "Une remise de 40 % sur les verres correcteurs est appliquée automatiquement",
            firstLineReview: "Monture :",
            insuranceButtonLabelRemove: "Supprimer des prestations sociales",
            insuranceSyncedLabel: "Prestations sociales appliquées",
            oneYearCoverageAdd: "Ajouter",
          },
        },
        confirmButton: "Continuer",
        vmmv: {
          backButton: "Retourner au produit",
        },
        prescription: {
          rightEye: "(Œil droit)",
          rightEyeInitials: "OD",
          leftEye: "(Œil gauche)",
          leftEyeInitials: "OS",
          compatibleFrameSubLabel:
            '"Aucun problème, nous avons des montures s\'adaptant à votre correction."',
          pdHelp: "\"Qu'est-ce c'est ?\"",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Prix monture + verres",
            frame: "Prix de la monture",
            total: "Total",
          },
          free: "Gratuit",
          included: "Inclus",
          frameLensStartingAt: "Monture+Verres à",
          frameLens: "Monture + Verres",
        },
        noTreatment: "No treatment",
        exitYes: "Oui, sortie",
        insuranceButtonLabelRemove: "Supprimer des prestations sociales",
        fallbackImageMessageTitle:
          "Aperçu de la couleur des verres pas disponible",
        fallbackImageMessageSubtitle:
          "Ne vous inquiétez pas, vous recevrez votre monture avec la couleur de verre que vous avez choisie.",
        proceedAsIs: "Procéder comme décrit",
        yourSelections: "Vos sélections",
        editButton: "Modifier",
      },
      fr_CH: {
        steps: {
          prescription: {
            title: "Complétez votre ordonnance",
          },
          type: {
            title: "Sélectionnez vos exigences de vue",
            confirmedTitle: "Utilisez",
          },
          brand: {
            title: "Choisissez la marque de vos verres",
          },
          gvpTreatment: {
            confirmedTitle: "Le pack verres ",
          },
          thickness: {
            confirmedTitle: "Épaisseur",
            title: "Sélectionnez l’épaisseur de vos verres",
          },
          advancedPrescription: {
            confirmedTitle: "Vous avez une ordonnance ?",
            manual: {
              add: "ADD (addition)",
              pupillaryDistanceWarningValue:
                "Veuillez saisir votre écart pupillaire. Si vous ne le connaissez pas, vous pouvez utiliser la valeur par défaut ci-dessus ou vous pouvez ",
              continueModal: {
                titleNoAdd: "ÊTES-VOUS SÛR(E) DE VOULOIR CONTINUER?",
                messageNoAdd:
                  "Veuillez saisir la valeur d'addition (ADD) si elle figure sur votre ordonnance, si non continuez sans la saisir.",
                continueNoAdd: "CONTINUER SANS (ADD)",
              },
            },
          },
        },
      },
      nl_NL: {
        steps: {
          prescription: {
            description:
              "Of we adviseren je het brillenglas dat het beste aan jouw eisen voldoet, zodat je je geen zorgen hoeft te maken",
          },
          brand: {
            confirmedTitle: "Merk van glazen",
          },
          advancedPrescription: {
            title: "Heb je een brilrecept?",
            confirmedTitle: "Brilrecept",
            manual: {
              commentsTitle: "Opmerkingen",
              moreOptions: "Meer opties",
              addPrismValues: "Handmatig ingevoerd",
              manualPreviewTitle: "Je receptdetails",
              pupillaryDistanceMisurePDAction: "je PD meten",
              pupillaryDistanceSubtitle:
                "Je kunt de standaardinstellingen van <b>63</b> voor monturen voor volwassenen en <b>54</b> voor kindermonturen selecteren.",
              pupillaryDistanceWarningValue:
                "Voer je pupilafstand in. Als je deze niet hebt, kun je de standaard van het bovenstaande gebruiken of je kunt ",
              title: "Je brilrecept invoeren",
              subtitle:
                "Voeg je waarden toe en wij adviseren de beste glazen voor jouw zichtbehoeften.",
              rightEye: {
                initials: "OD",
                name: "(Rechteroog)",
              },
              leftEye: {
                initials: "OS",
                name: "(Linkeroog)",
              },
              card: {
                title: "Handmatig invoeren",
                description:
                  "Dit is de snelste en meest gebruikte methode. Wij stellen dan het beste type glazen voor jou voor.",
              },
              review: {
                title: "Handmatig ingevoerd",
              },
              digitalOptometry: {
                title: "Hoe meet je je PD?",
                appSubtitle:
                  "De pupilafstand (PD) is de afstand in millimeters (mm) van het midden van de ene pupil tot het midden van de andere. Deze waarde geeft precies aan door welk deel van het glas je kijkt, wat zorgt voor optimaal comfort en duidelijkheid. Je kunt met de app of handmatig meten.",
                manuallySubtitle:
                  "De pupilafstand (PD) is de afstand in millimeters (mm) van het midden van de ene pupil tot het midden van de andere. Je kunt met de app of handmatig meten.",
                toggle: {
                  app: "met de app",
                  manually: "handmatig",
                },
                opthyDesc:
                  "<b>Maak je bril precies op maat met Opthy.</b><br/><p>Opthy meet je pupilafstand, zodat je de meest comfortabele, gepersonaliseerde pasvorm kunt vinden.</p><p>App beschikbaar op iPhone X en hoger.</p>",
                manuallyContent:
                  "<div>Als je wilt, kun je jouw PD zelf meten door deze stappen te volgen:</div><ul><li>Pak een kleine liniaal in één hand en houd potlood en papier bij de hand.</li><li>Ga nu ongeveer 20 cm van een spiegel staan of zitten.</li><li>Sluit je linkeroog en lijn de 0 uit over het midden van je rechterpupil.</li><li>Meet de afstand van je rechter- tot je linkerpupil.</li><li>Het getal dat direct boven je linkerpupil staat is je PD (een gemiddelde PD voor een volwassene ligt tussen de 58 en 65).</li></ul>",
              },
              seeDetails: {
                axis: "As",
                sphere: "SPH",
                cylinder: "CYL",
                add: "ADD",
                pd: "PD",
                od: "OD",
                os: "OS",
                vertical: "Verticaal prisma (Δ)",
                baseDirection: "Basis",
                horizontal: "Horizontaal prisma (Δ)",
              },
              howToReadModal: {
                subtitle:
                  "Als je over een voorschrift voor een bril beschikt, bevat de voorschriftinformatie doorgaans het volgende:",
                infoText:
                  "Als één van deze gegevens niet in je voorschrift staat, kun je dit veld leeg laten.",
                customerService:
                  "Ben je ergens niet zeker van? Bel de experts van onze klantenservice <a>XXXXXXXXXX</a>",
                rightEye: {
                  description:
                    'OD staat voor "oculus dexter" wat Latijn is voor "rechteroog".',
                },
                leftEye: {
                  description:
                    'OS staat voor "oculus sinister", wat Latijn is voor "linkeroog".',
                },
                sphere: {
                  description:
                    "Ze betekenen hetzelfde: de sterkte van jouw voorschrift. Als je bijziend bent, heb je een min (-) voor je waarden, en als je verziend bent, heb je een plus (+).",
                },
                cylinder: {
                  description:
                    "Dit is voor mensen met astigmatisme. Dit is wanneer een deel van het oog meer correctie nodig heeft dan de rest. De cilinderwaarde wordt geschreven met een minteken (-).",
                },
                axis: {
                  description:
                    "Dit is alleen voor mensen met astigmatisme. De as is een getal tussen 0 en 180 en bepaalt de oriëntatie van de cilinder (CYL).",
                },
              },
              sphere: "Sfeer (SPH)",
              cylinder: "Cilinder (CYL)",
              axis: "As",
              add: "ADD",
              vertical: "Verticaal (Δ)",
              baseDirection: "Basis",
              horizontal: "Horizontaal (Δ)",
              pupillaryDistance: "PD (Pupilafstand)",
              pdLeft: "Links",
              pdRight: "Rechts",
              iHaveTwoPd: "Ik heb twee PD's",
              applyButton: "Doorgaan",
              howToRead: "Hoe lees je je brilrecept?",
              whatIsIt: "Wat is het?",
              pupillaryDistanceWarningTooltip:
                "Voer tekst in voor deze tooltip",
              moreOptions: "Meer opties",
              incompatibleLenses:
                "Het spijt ons, het recept dat je hebt ingevoerd is niet compatibel met onze online aangeboden lenzen. Zoek een winkel bij jou in de buurt of neem contact op met onze klantenservice voor meer informatie.",
              alerts: {
                pdValueWarningDigitalOptometrySmaller:
                  "De geselecteerde PD is kleiner dan gemiddeld, we raden je aan je voorschrift te controleren. Als je het niet hebt, kun je de hierboven beschreven waarden toevoegen of je kan",
                pdValueWarningDigitalOptometryLarger:
                  "De geselecteerde PD is groter dan gemiddeld, we raden je aan je voorschrift te controleren. Als je het niet hebt, kun je de hierboven beschreven waarden toevoegen of je kan",
                axisRangeError:
                  "Het lijkt erop dat de assen die je hebt ingevoerd onjuist zijn. Deze moeten tussen de 1 en 180 zijn.",
                axisZeroError:
                  "Het lijkt erop dat de assen die je hebt ingevoerd onjuist zijn. Als de CYL hoger of lager is dan 0, kunnen de assen geen 0 zijn.",
                missingValues: "Vul de ontbrekende waarden in",
                requiredToProceed: "Dit is vereist om door te gaan",
                sphPositiveNegativeWarning:
                  "<b>Je hebt 1 positieve en 1 negatieve waarde ingevoerd, wat zeer zeldzaam is.</b><br>We raden je aan om je recept nog een keer te controleren voordat je doorgaat.",
                pdValueWarning:
                  "De geselecteerde PD valt buiten het normale bereik, we raden je aan je recept nog een keer te controleren. Je kunt de standaardinstelling van 63 voor volwassenen of 54 voor kinderen laten staan. Als we meer informatie nodig hebben, neemt een van onze experts contact met je op.",
                pdValueWarning2:
                  "De geselecteerde PD is kleiner dan gemiddeld, we raden aan je recept nog een keer te controleren. Als je deze niet hebt, kun je de bovenstaande waarden toevoegen of je kunt ",
                missingPrismValues: "Kies de prismawaarde",
                requiredToProceed: "Dit is nodig om door te gaan",
                missingBaseDir: "Kies de basis voor je prisma",
                incompatibleWithPrescriptionTitle:
                  "Sorry! Dit montuur is niet geschikt voor de waarden van jouw brilrecept.",
                incompatibleWithPrescriptionBody:
                  "Maar maak je geen zorgen, er zijn compatibele monturen verkrijgbaar",
                incompatibleWithPackagesTitle:
                  "Sorry! De waarden van dit brilrecept zijn niet compatibel met de glazen die beschikbaar zijn voor dit montuur.",
                shopCompatible: "Vind het juiste montuur",
                clearAll: "Alles wissen",
                agreementText:
                  "Door op dit vakje te klikken, bevestig ik dat de hierboven ingevoerde waarden afkomstig zijn van een geldig (niet verlopen) recept dat aan mij is verstrekt en is ondertekend door een erkende optometrist of oogarts.",
              },
              continueModal: {
                continue: "Doorgaan",
                cancel: "Terug",
                title: "Recept later versturen",
                message:
                  "Door op Doorgaan te klikken sla je de receptstap over. We vragen je je brilrecept te uploaden nadat je je bestelling hebt afgerond.",
                titleNoAdd: "WEET JE ZEKER DAT JE WILT DOORGAAN?",
                messageNoAdd:
                  "Zorg ervoor dat je de ADD-waarde invoert als deze op je recept staat, anders kun je zonder deze waarde doorgaan.",
                continueNoAdd: "DOORGAAN ZONDER (ADD)",
              },
              issueDate: {
                label: "Afgiftedatum (MM/JJJJ)",
                missingError: "Voer een datum in",
                formatError: "De ingevoerde datum is ongeldig",
                tooltipText:
                  "Controleer de geldigheidsdatum om na te gaan of je brilrecept nog altijd geldig is, voordat je het indient. Wij accepteren alleen brilrecepten die binnen de afgelopen 2 jaar zijn afgegeven. Als je het hokje aanvinkt en hieronder verder gaat, aanvaardt je onze voorwaarden en privacybeleid.",
              },
            },
            californian: {
              chooseTitle: "KIES HOE JE JOUW VOORSCHRIFT WIL VERZENDEN",
              info: "Houd er rekening mee dat inwoners van Californië bij het handmatig toevoegen van waarden van hun voorschrift ook verplicht zijn om hun geldig voorschrift elektronisch door te geven. Upload het om verder te gaan.",
              uploadDesc:
                "Wij aanvaarden de volgende bestandsformaten: .pdf, .png, .jpeg .gif, .tiff, .bmp, .docx (max 10 MB) en IWork pagina's.",
              callMyDoc: "Bel mijn huisarts",
              requiredLabel: "* Verplicht veld",
            },
            fromMyAccount: {
              card: {
                title: "Haal het op uit mijn account",
                description: "Log in en selecteer jouw brilrecept.",
              },
              review: {
                title: "Geladen uit het account",
              },
              title: "Selecteer jouw brilrecept",
              subtitle:
                "Kies het gewenste brilrecept, wij denken aan de rest. \n Kun je het niet vinden?",
              subtitleAdd: "Een nieuw brilrecept toevoegen",
              prescriptionName: "Naam brilrecept",
              uploadedOn: "Geüpload op",
              selectButton: "Brilrecept selecteren",
              savePrescriptionInAccount:
                "Sla het brilrecept op in mijn account",
              newPrescriptionNameError:
                "Het veld naam brilrecept mag niet leeg zijn",
              olderThanYearBanner:
                "Dit brilrecept werd 1 jaar geleden geüpload. Controleer of het nog altijd geldig is.",
              showPrescription: "brilrecept tonen",
              prescriptionDetailModal: {
                title: "Jouw brilrecept",
                edit: "Brilrecept bewerken",
              },
            },
            account: {
              card: {
                title: "Haal het op uit mijn account",
                description: "Log in en selecteer jouw brilrecept.",
              },
              review: {
                title: "Geladen vanaf account",
              },
            },
            upload: {
              title: "Jouw brilrecept",
              card: {
                description:
                  "Wij denken aan de rest. We accepteren de volgende bestandsformaten': .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) en IWork pages",
              },
            },
            doctor: {
              title: "Voeg de gegevens van jouw arts toe",
              subtitle:
                "We zullen direct met hem/haar contact opnemen en jouw brilrecept opvragen",
              searchBy: "Zoek je oogarts op",
              card: {
                title: "Bel mijn arts",
                description:
                  "We zullen direct met hem/haar contact opnemen en jouw brilrecept opvragen.",
              },
            },
          },
          type: {
            prescriptionTitle: "Of",
            addToCart: "Aan winkelwagen toevoegen",
            moreInfo: "Meer informatie",
          },
          treatmentsFamily: {
            confirmedTitle: "Type brillenglas:",
            title: "Kies het type brillenglazen",
            blueLight: {
              title: "Blauwlichtfilter",
              description:
                "Reduceert de blootstelling aan blauw licht afkomstig van digitale schermen, waardoor je oogvermoeidheid kunt beperken.",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "Eén bril voor binnen en buiten met Transitions®-brillenglazen die snel overschakelen van licht naar donker en omgekeerd, zodat je nooit van bril hoeft te wisselen.",
              imageFileName: "",
            },
            clear: {
              title: "Helder",
              description:
                "Traditionele, transparante brillenglazen voor alledaags gebruik",
              imageFileName: "",
            },
            sun: {
              title: "Zon",
              description:
                "Maak een keuze uit brillenglazen in diverse kleuren en nuances.",
              imageFileName: "",
            },
          },
          gvpTreatment: {
            subtitle:
              "Jouw bril wordt geleverd met de beste LensCrafters-technologie.",
            confirmedTitle: "Lenzenpakket:",
          },
          thickness: {
            subtitle:
              "Jouw bril wordt geleverd met de beste LensCrafters-technologie.",
            designTypeTitle: "Kies het design van de brillenglazen",
          },
          treatments: {
            extraTreatments: {
              antiScratch: {
                name: "Krasbestendig",
              },
            },
          },
          transitionColor: {
            title: "TransitionColor",
            indoor: "BINNEN",
            outdoor: "BUITEN",
          },
          lensColor: {
            confirmedTitle: "Kleur:",
            detailBox: {
              lightTransmission: "Lichttransmissie",
              lightConditions: "Lichtomstandigheden",
              contrast: "Contrast",
              baseLensColor: "Basiskleur brillenglazen",
            },
            categories: {
              "Ugly category": {
                title: "Ugly categorie",
              },
              "Cool category": {
                title: "Cool categorie",
              },
            },
          },
          color: {
            confirmedTitle: "Kleur:",
            title: "Kies de kleur van de brillenglazen",
            titleMobile: "Kies de kleur van de brillenglazen",
            titleDesignTypeLayout: "Kies de technologie van de brillenglazen",
            titleColorCategoryLayout: "Kies het type brillenglazen",
            polarized: "Uitsluitend gepolariseerd",
            applyAndContinue: "Toepassen",
            subtitle:
              "Verbeterde kleur en contrast, zodat je meer details kunt waarnemen",
            tooltip: {
              switch:
                "Minder verblinding bij extra helder licht, voor helderder zicht en bescherming van de ogen.",
              polarized:
                "Minder verblinding bij extra helder licht, voor helderder zicht en bescherming van de ogen.",
              blueLight:
                "Reduceert de blootstelling aan blauw licht afkomstig van digitale schermen, waardoor oogvermoeidheid wordt beperkt.",
            },
          },
          addOns: {
            confirmedTitle: "Maak jouw brillenglazen af",
            label: "Reeds inbegrepen met jouw brillenglas",
            title: "Maak jouw brillenglazen af",
            reviewTitle: "Afwerking",
          },
          review: {
            title: "Herzie jouw keuzes",
            discountTitle:
              "Over brillenglazen op sterkte wordt automatisch -40% toegepast",
            firstLineReview: "Montuur:",
            insuranceButtonLabel: "Add insurance benefits",
            insuranceButtonLabelRemove: "Verzekeringsuitkeringen verwijderen",
            oneYearCoverageAdd: "Toevoegen",
          },
        },
        confirmButton: "Verder",
        vmmv: {
          backButton: "Terug naar product",
        },
        prescription: {
          rightEye: "(Rechteroog)",
          rightEyeInitials: "OD",
          leftEye: "(Linkeroog)",
          leftEyeInitials: "OS",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Bedrag montuur + brillenglazen",
            frame: "Bedrag montuur",
            total: "Total",
          },
          free: "Gratis",
          included: "Inclusief",
          frameLensStartingAt: "Montuur + brillenglazen bij",
          frameLens: "Montuur + brillenglazen",
        },
        noTreatment: "No treatment",
        exitYes: "Ja, afsluiten",
        insuranceButtonLabelRemove: "Remove insurance benefits",
        fallbackImageMessageTitle: "Weergave lenskleur niet beschikbaar",
        fallbackImageMessageSubtitle:
          "Geen probleem, je ontvangt het montuur met de gekozen lenskleur.",
        proceedAsIs: "Doorgaan zonder wijzigingen",
        yourSelections: "Jouw keuzes",
        editButton: "Bewerken",
      },
      nl_BE: {
        steps: {
          mixAndMatch: {
            title: "KIES DE KLEUR VAN JE MONTUUR EN GLAZEN",
            polarized: "Gepolariseerd",
          },
          color: {
            confirmedTitle: "KLEUR",
          },
        },
      },
      no_NO: {
        steps: {
          prescription: {
            title: "Legg til brilleseddelverdiene dine",
            description:
              "Ellers forslår vi riktig brilleglas etter ditt synsbehov, slik at du kan fortsette uten problemer",
          },
          mixAndMatch: {
            title: "CHOOSE YOUR FRAME & LENS COLOR",
          },
          advancedPrescription: {
            confirmedTitle: "Prescription:",
            title: "Har du brilleseddel?",
            manual: {
              addPrismValues: "Legg til prismeverdier",
              review: {
                title: "Angitt manuelt",
              },
              title: "Angi brilleseddel",
              card: {
                title: "Angi manuelt",
              },
              subtitle:
                "Legg til brilleseddelverdiene dine, så anbefaler vi det beste brilleglasset for dine synsbehov.",
              rightEye: {
                initials: "OD",
                name: "(Høyre øye)",
              },
              leftEye: {
                initials: "OS",
                name: "(Venstre øye)",
              },
              sphere: "Kule (SPH)",
              cylinder: "Sylinder (CYL)",
              axis: "Akse",
              add: "ADD",
              vertical: "Loddrett (Δ)",
              baseDirection: "Baseretning",
              horizontal: "Vannrett (Δ)",
              pupillaryDistance: "PD (Pupillavstand)",
              pdLeft: "Venstre",
              pdRight: "Høyre",
              iHaveTwoPd: "Jeg har to PD-numre",
              applyButton: "Fortsett",
              howToRead: "Slik leser du brilleseddelen din",
              whatIsIt: "Hva er det?",
              pupillaryDistanceWarningValue:
                "Skriv inn pupillavstanden din. Hvis du ikke kjenner til denne, kan du bruke standarden ovenfor eller ",
              pupillaryDistanceMisurePDAction: "måle pupillavstanden din.",
              pupillaryDistanceWarningTooltip:
                "Angi tekst for dette verktøytipset",
              moreOptions: "Flere alternativer",
              commentsTitle: "Kommentarer",
              alerts: {
                axisRangeError:
                  "AKSE-verdiene du har angitt er ikke riktige! Disse verdiene må være mellom 1 og 180.",
                axisZeroError:
                  "AKSE-verdiene du har angitt er ikke riktige! Hvis CYL-verdien er høyere eller lavere enn 0, kan ikke AKSE-verdiene være 0.",
                sphPositiveNegativeWarning:
                  "<b>Du har satt inn én positiv og én negativ verdi, noe som er svært sjeldent.</b><br>Vi foreslår at du dobbeltsjekker brilleseddelen din. Hvis dette er riktig, kan du fortsette.",
                pdValueWarning:
                  "Valgt PD er utenfor det normale området. Vi foreslår at du dobbeltsjekker brilleseddelen din. Du kan la standardinnstillingen være 63, som er den gjennomsnittlige målingen for voksne. Hvis vi trenger mer informasjon om brilleseddelen din, vil en av våre eksperter ta kontakt.",
                pdValueWarning2:
                  "Valgt pupillavstand er mindre enn gjennomsnittet, så vi anbefaler at du dobbeltsjekker styrken din. Hvis du ikke kjenner til denne, kan du legge til verdiene ovenfor, eller ",
                missingValues: "Fullfør de manglende verdiene",
                requiredToProceed: "Dette er nødvendig for å fortsette",
                incompatibleWithPrescriptionTitle:
                  "Beklager! Innfatningen du har valgt er ikke kompatibel med brilleseddelen din",
                incompatibleWithPrescriptionBody:
                  "men slapp av, det finnes kompatible innfatninger",
                incompatibleWithPackagesTitle:
                  "Beklager! Brilleseddelen du har valgt er ikke kompatibel med noen av de tilgjengelige brilleglassene våre for denne innfatningen",
                shopCompatible: "Kjøp kompatible innfatninger",
                clearAll: "Tøm alle",
                agreementText:
                  "Ved å klikke på denne boksen, bekrefter jeg at brilleseddelverdiene som er angitt ovenfor er hentet fra en gyldig (ikke utløpt) brilleseddel utstedt til meg, signert av en lisensiert optiker eller øyelege.",
              },
              continueModal: {
                title: "Send brilleseddel senere",
                message:
                  "Ved å klikke på fortsett, hopper du over brilleseddeltrinnet. Vi vil be om det etter bestillingen din, enten ved å laste den opp eller ved at vi ringer legen din.",
                continue: "Ja, fortsett",
                titleNoAdd: "ER DU SIKKER PÅ AT DU VIL FORTSETTE?",
                messageNoAdd:
                  "Vennligst skriv inn addisjonsverdien (ADD) hvis den er oppført i brilleseddelen, ellers kan du fortsette uten.",
                continueNoAdd: "FORTSETT UTEN (ADD)",
              },
              digitalOptometry: {
                title: "Slik måler du pupillavstand",
                manuallySubtitle:
                  "Pupillavstanden er avstanden i millimeter (mm) fra midten av en pupill til midten av den andre. Du kan måle med appen eller manuelt.",
                toggle: {
                  app: "med appen",
                  manually: "manuelt",
                },
                opthyDesc:
                  "<b>Få helt riktige briller med Opthy.</b><br/><p>Opthy måler pupillavstanden din, slik at du kan finne den mest komfortable og personlige passformen.</p><p>Appen er tilgjengelig på iPhone X og nyere.</p>",
                manuallyContent:
                  "<div>Hvis du vil, kan du selv måle pupillavstanden på følgende måte:</div><ul><li>Ta en liten linjal i den ene hånden og ha papir og blyant klart.</li><li>Stå ca. 20 cm fra et speil.</li><li>Lukk venstre øye og legg 0 mm på linjalen over midten til høyre pupill.</li><li>Mål avstanden fra høyre til venstre pupill.</li><li>Tallet som er like over venstre pupill er pupillavstanden din (gjennomsnittlig pupillavstand for voksne er mellom 58 og 65 mm).</li></ul>",
              },
              manualPreviewTitle: "Angi brilleseddelinformasjon",
              seeDetails: {
                sphere: "SPH",
                cylinder: "CYL",
                axis: "Akse",
                add: "ADD",
                pd: "PD",
                od: "OD",
                os: "OS",
                vertical: "Loddrett prisme (Δ)",
                baseDirection: "Baseretning",
                horizontal: "Vannrett prisme (Δ)",
              },
              issueDate: {
                label: "Utstedelsesdato (MM/ÅÅÅÅ)",
                missingError: "Angi dato",
                formatError: "Datoen som er angitt er ikke gyldig",
                tooltipText:
                  "Før du sender inn resepten, må du sjekke utløpsdatoen for å sikre at den er aktuell og gyldig. Vi aksepterer bare resepter som er utstedt i løpet av de siste 2 årene. Ved å merke av i boksen og fortsette nedenfor, godtar du personvernerklæringen og vilkårene våre.",
              },
            },
            fromMyAccount: {
              card: {
                title: "Få det fra kontoen min",
                description: "Logg inn og velg resepten din.",
              },
              review: {
                title: "Lastet inn fra kontoen",
              },
              title: "Velg resepten din",
              subtitle:
                "Velg ønsket resept, så tar vi oss av resten. \n Finner du den ikke?",
              subtitleAdd: "Legg til en ny resept",
              prescriptionName: "Reseptnavn",
              uploadedOn: "Lastet opp den ",
              selectButton: "Velg resept",
              savePrescriptionInAccount: "Lagre resept på kontoen min",
              newPrescriptionNameError:
                "Feltet for reseptnavn kan ikke være tomt",
              olderThanYearBanner:
                "Denne resepten ble lastet opp for 1 år siden. Sjekk om den fortsatt er gyldig.",
              showPrescription: "vis resept",
              prescriptionDetailModal: {
                title: "Resepten din",
                edit: "Rediger resept",
              },
            },
            account: {
              card: {
                title: "Hent det fra kontoen min",
                description: "Logg inn og velg brilleseddel.",
              },
              review: {
                title: "Lastet fra kontoen",
              },
            },
            upload: {
              title: "Din brilleseddel",
              subtitle:
                "Før du sender inn brilleseddelen, må du sjekke utløpsdatoen for å sikre at den fortsatt er gyldig.",
              card: {
                title: "Last den opp nå",
                description:
                  "Så tar vi oss av resten. Vi godtar følgende filformater: ###FILE_FORMATS### (maks ###FILE_SIZE### MB) og IWork-sider",
              },
              review: {
                title: "Lastet opp",
              },
              uploadDifferentFile: "Last opp en annen fil",
              changeMethod: "Endre sendemetode",
              somethingWentWrong: "Beklager, noe gikk galt.",
              fileTooBigErrorTitle: "Filen er for stor",
              fileTooBigErrorDescription:
                "Filstørrelsen er for stor. Maksimal opplasting er ###FILE_SIZE### MB",
              tryAgain: "Prøv på nytt",
              upload: "Last opp",
              pdForm: {
                pupillaryDistance: "PD (Pupillavstand)",
                whatIsIt: "Hva er det?",
                pdValueWarning:
                  "Valgt PD er utenfor det normale området. Vi foreslår at du dobbeltsjekker brilleseddelen din. Du kan la standardinnstillingen være 63, som er den gjennomsnittlige målingen for voksne. Hvis vi trenger mer informasjon om brilleseddelen din, vil en av våre eksperter ta kontakt.",
                pdLeft: "Venstre",
                pdRight: "Høyre",
                iHaveTwoPd: "Jeg har to PD-numre",
              },
              confirmAndContinue: "Bekreft og fortsett",
              filePreviewTitle: "Din brilleseddel",
            },
            doctor: {
              title: "Legg til legedetaljene dine",
              subtitle:
                "Det må være den samme legen som utstedte og signerte brilleseddelen din. Vi ringer legen for å bekrefte opplysningene dine.",
              searchBy: "Søk etter øyelegen din etter",
              card: {
                title: "Ring legen min",
                description:
                  "Vi henter brilleseddelen din direkte fra ham/henne.",
              },
              review: {
                title: "Vi ringer legen din",
              },
              addNewDoctorModal: {
                title: "Legg til ny lege",
                clinicOrDoctorName: "Klinikk- eller legenavn",
                phoneNumber: "Telefonnummer",
                addDoctor: "Legg til lege",
              },
              slider: {
                phone: "Telefonnummer",
                clinicOrDoctorName: "Klinikk- eller legenavn",
              },
              table: {
                doctorName: "Navn på lege",
                clinicName: "Navn på klinikk",
                address: "Adresse",
                chooseButton: "Velg",
                phone: "Telefon",
                fax: "Faks",
                phoneNumber: "Telefonnummer",
                changeButton: "Endre",
                wrongDoctor: "Feil lege?",
              },
              pdForm: {
                pupillaryDistance: "PD (Pupillavstand)",
                whatIsIt: "Hva er det?",
                pdValueWarning:
                  "Valgt PD er utenfor det normale området. Vi foreslår at du dobbeltsjekker brilleseddelen din. Du kan la standardinnstillingen være 63, som er den gjennomsnittlige målingen for voksne. Hvis vi trenger mer informasjon om brilleseddelen din, vil en av våre eksperter ta kontakt.",
                pdLeft: "Venstre",
                pdRight: "Høyre",
                iHaveTwoPd: "Jeg har to PD-numre",
              },
              addManuallyOption: {
                didntFind: "Fant du ikke legen din?",
                addManually: "Legg til manuelt",
              },
              missingInfoError: "Fyll ut den manglende informasjonen",
              doctorNotFoundBoxMessage: "Vi fant ikke legen din.",
              doctorNotFoundBoxMessageAdd: "Legg til flere detaljer eller",
              doctorNotFoundBoxMessageLink: "legg til en ny lege.",
              phoneNumber: "Telefonnummer til lege",
              search: "Søk",
              clinicOrDoctorName: "Klinikk- eller legenavn",
              city: "By",
              state: "Stat",
              changeSubmissionMethod: "Endre innsendingsmetode",
              confirmAndContinue: "Bekreft og fortsett",
              selectedDoctorPageTitle: "Dine legedetaljer",
              doctorPreviewTitle: "Vi ringer legen din",
            },
            later: {
              card: {
                title: "Send den senere",
              },
              review: {
                title: "Vi ber om det etter betaling",
              },
            },
            californian: {
              toggle: "Er du bosatt i California?",
              info: "I tillegg til å legge til styrkeverdier manuelt, må innbyggere i California også overføre gyldig styrke elektronisk. Velg et alternativ for å fortsette.",
              chooseTitle: "VELG HVORDAN DU SKAL SENDE STYRKEN",
              uploadTitle: "Last opp",
              uploadDesc:
                "Vi godtar følgende filformater: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (maks. 10 MB) og IWork-sider.",
              uploadButton: "Bla gjennom",
              callMyDoc: "Ring legen min",
              callMyDocButton: "FINN LEGEN DIN",
              yes: "JA",
              no: "NEI",
              modalMissingPrescription: {
                title: "Manglende styrkeverdier",
                subtitle:
                  "Hvis du ikke legger inn verdiene, kan vi ikke foreslå riktige brilleglass for deg",
                bottonYes: "JA, FORTSETT",
                bottonNo: "NEI, GÅ TILBAKE",
              },
            },
          },
          type: {
            title: "Velg ditt synsbehov",
            confirmedTitle: "Synsbehov:",
            prescriptionTitle: "Eller",
            addToCart: "Legg til i handlekurv",
            moreInfo: "Mer informasjon",
          },
          brand: {
            title: "Velg brilleglassmerke",
            confirmedTitle: "Brilleglassmerke:",
          },
          treatmentsFamily: {
            confirmedTitle: "Type brilleglass:",
            title: "Velg brilleglasstype",
            blueLight: {
              title: "Blålysfiltrering",
              description:
                "Reduserer eksponering mot blått lys fra digitale skjermer, noe som kan bidra til å forhindre tretthet i øynene.",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "Transitions®-brilleglass blir raskt mørkere når de utsettes for sollys og går tilbake til klare når du er innendørs. Velg mellom en rekke farger. Blålysfilter inkludert.",
              imageFileName: "",
            },
            clear: {
              title: "Klart",
              description:
                "Tradisjonelle, gjennomsiktige brilleglass som er perfekte til hverdagsbruk",
              imageFileName: "",
            },
            sun: {
              title: "Sol",
              description:
                "Velg mellom forskjellige brilleglassfarger og -nyanser.",
              imageFileName: "",
            },
          },
          gvpTreatment: {
            title: "Velg en prisgunstig pakke",
            subtitle:
              "Brillene dine vil komme med førsteklasses Oakley-teknologi.",
            confirmedTitle: "Brilleglasspakke:",
          },
          thickness: {
            confirmedTitle: "Tykkelse::",
            title: "Velg brilleglasstykkelse",
            subtitle:
              "Brillene dine vil komme med førsteklasses LensCrafters-teknologi.",
            designTypeTitle: "Velg brilleglassdesign",
          },
          treatments: {
            addToBag: "LEGG I HANDLEKURV",
            title: "Velg en lysresponsiv behandling",
            confirmedTitle: "Behandling:",
            extraTreatments: {
              antiScratch: {
                name: "Anti-riper",
                title: "Ripebestandig",
              },
              antiReflective: {
                value: "Anti-reflekterende",
              },
              uvProtection: {
                title: "UV-beskyttelse",
              },
            },
          },
          transitionColor: {
            title: "Overgangsfarge",
            indoor: "Innendørs",
            outdoor: "Utendørs",
          },
          lensColor: {
            confirmedTitle: "Farge:",
            detailBox: {
              lightTransmission: "Lysoverføring",
              lightConditions: "Lysforhold",
              contrast: "Kontrast",
              baseLensColor: "Grunnfarge på brilleglassene",
            },
            categories: {
              all: {
                title: "Alle",
              },
              "Ugly category": {
                title: "Stygg kategori",
              },
              "Cool category": {
                title: "Kul kategori",
              },
            },
          },
          color: {
            confirmedTitle: "Farge:",
            title: "Velg brilleglassfarge",
            titleMobile: "Velg brilleglassfarge",
            titleDesignTypeLayout: "Velg brilleglassteknologi",
            titleColorCategoryLayout: "Velg brilleglasstype",
            polarized: "Kun polarisert",
            applyAndContinue: "Bruk",
            subtitle:
              "Forsterkede farger og kontrast slik at du kan se flere detaljer",
            tooltip: {
              switch:
                "Reduserer gjenskinn i ekstra sterkt lys, for overlegen klarhet og beskyttelse av øyet.",
              polarized:
                "Reduserer gjenskinn i ekstra sterkt lys, for overlegen klarhet og beskyttelse av øyet.",
              antiReflective:
                "Reduserer refleksjoner og gir et usynlig skjold. Det motstår gjenskinn, støv og flekker for generelt klarere syn.",
              antiScratch: "Beskytter brilleglassoverflaten mot harde støt",
              blueLight:
                "Reduserer eksponeringen av blått lys fra digitale skjermer og solstråler som kan bidra til å redusere trøtte øyne",
            },
          },
          addOns: {
            confirmedTitle: "Sluttbehandling:",
            label: "Allerede inkludert i brilleglassene",
            title: "Fullfør brilleglassene",
            reviewTitle: "Sluttbehandling:",
          },
          review: {
            title: "Se gjennom valgene dine",
            discountTitle:
              "40 % avslag på reseptbelagte brilleglass påføres automatisk",
            firstLineReview: "Innfatning:",
            paymentInstallments: {
              installmentsLabel:
                "Betal over tid i rentefrie avdrag med ###PAYMENT_TYPE###",
              modal: {
                title: "Betaling i avdrag",
                subtitle:
                  "Vil du spre ut kostnadene for bestillingen din med ###BRAND###? Velg ###PAYMENT_TYPE### som betalingsmetode i kassen for å betale i rentefrie avdrag, uten skjulte gebyrer.",
                affirm:
                  "Med Affirm foretar du tre rentefrie betalinger annenhver uke med null skjulte gebyrer. Betalingsalternativer tilbys av Affirm, er underlagt en kvalifiseringskontroll og er kanskje ikke tilgjengelig i alle stater. CA-innbyggere: Lån av Affirm Loan Services, LLC inngås eller arrangeres i henhold til en California Finance Lender-lisens.",
                klarna:
                  "Med ###PAYMENT_TYPE###, gjør ###INSTALLMENTS### rentefrie avdrag på ###MONTHLY_PRICE###",
                afterpay:
                  "Med Afterpay foretar du fire rentefrie betalinger annenhver uke. Du må være over 18 år, bosatt i USA og oppfylle ytterligere kvalifikasjonskriterier for å være kvalifisert. Gebyrer for sen betaling kan påløpe. Klikk her for fullstendige vilkår. Lån til innbyggere i California som inngås eller arrangeres gjøres i henhold til en California Finance Lenders Law-lisens.",
              },
            },
            insuranceButtonLabel: "Legg til forsikringsfordeler",
            insuranceButtonLabelRemove: "Fjern forsikringsfordeler",
            oneYearCoverageAdd: "Legg til",
            tooltip:
              "Sikre brillene med utvidet garanti for ubegrenset reparasjon og utskifting i enhver LensCrafters-butikk.",
            seeDetails: "Se detaljer",
          },
        },
        prescription: {
          toggleLabel:
            "Legg til brilleseddelverdiene dine, så hjelper vi deg med å finne de perfekte brilleglassene for dine synsbehov.",
          prescriptionTitle:
            "Legg til brilleseddelverdiene dine, så viser vi deg de perfekte brilleglassene for dine synsbehov.",
          rightEye: "(Høyre øye)",
          rightEyeInitials: "OD",
          leftEye: "(Venstre øye)",
          leftEyeInitials: "OS",
          readPrescription: "Slik leser du brilleseddelen din",
          addLabel: "Jeg har også en ADD-verdi",
          applyButton: "Bruk og fortsett",
          submitButton: "Send inn",
          clearButton: "Tøm alle",
          skipButton: "Hopp over foreløpig",
          compatibleFramesButton: "Kjøp kompatible innfatninger",
          doctorDetails:
            "Vi ber om en kopi av brilleseddelen eller legens detaljer under eller etter utsjekking",
          missingValueError: "Legg til verdiene dine for å fortsette",
          positiveNegativeError:
            "Du har angitt én positiv verdi og én negativ verdi, noe som er ganske sjeldent. Vi foreslår at du dobbeltsjekker brilleseddelen din.",
          compatibleFrameError:
            "Beklager! Innfatningen du har valgt er ikke kompatibel med brilleseddelen din.",
          compatibleFrameSubLabel:
            "Men slapp av, det finnes kompatible innfatninger.",
          axisError: "Husk å legge til AKSE-verdiene",
          axisSubError: "Disse verdiene må være mellom 0 og 180.",
          axisSubErrorZero:
            "Hvis CYL-verdien er høyere eller lavere enn 0, kan ikke AKSE-verdiene være 0.",
          editLabel: "Rediger brilleseddel",
          pdHelp: "Hva er dette?",
          twoPDNumbers: "Jeg har to PD-verdier",
          missingInputMessage:
            "Legg til dine manglende verdier for å fullføre opplastingen av brilleseddelen din, så anbefaler vi brilleglassene som passer best.",
          defaultInputMessage:
            "Velg å legge til brilleseddelen din nå, eller last den opp senere.",
          skipMessage: "",
          readyMessage: "Nå kan vi foreslå riktig brilleglass til deg!",
          bestPrescription: "Best til brilleseddelen din",
          proceedCheckbox:
            "Ved å klikke på denne boksen, bekrefter jeg at brilleseddelverdiene angitt ovenfor er hentet fra en gyldig (ikke utløpt) brilleseddel utstedt til meg, signert av en lisensiert optiker eller øyelege.",
          proceedCheckboxError: "Nødvendig for å fortsette",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Pris på innfatning + brilleglass",
            frame: "Pris på innfatning",
            total: "Totalt",
          },
          free: "Gratis",
          included: "Inkludert",
          frameLensStartingAt: "Innfatning + brilleglass til",
          frameLens: "Innfatning + brilleglass",
          startingAt: "Fra",
          vatIncluded: "Inkludert mva",
          discountBadgePercentage: "###VALUE######SYMBOL",
          discountBadgeAmount: "###VALUE######SYMBOL### AV",
        },
        noTreatment: "Ingen behandling",
        learnMoreMade: "Se hvordan det lages",
        learnMoreTreatment: "Se behandlingen",
        exitTitle: "Er du sikker på at du vil avslutte?",
        exitSubtitle: "Valget av brilleglass blir ikke lagret",
        exitYes: "Ja, avslutt",
        exitContinueEditing: "Nei, fortsett",
        exitSave: "Lagre og fortsett å handle",
        insuranceButtonLabel: "Legg til forsikringsfordeler",
        insuranceLabelRemove: "Fjern fordeler",
        insuranceButtonLabelRemove: "Fjern forsikringsfordeler",
        insuranceSyncedLabel: "Forsikringen er synkronisert",
        applyingInsurance: "Bruke forsikringsfordeler",
        fallbackImageMessageTitle:
          "Forhåndsvisning av brilleglassfarge er ikke tilgjengelig",
        fallbackImageMessageSubtitle:
          "Ingen problem, du vil motta innfatningen med brilleglassfargen du valgte.",
        insuranceErrorModal: {
          title: "Noe gikk galt…",
          content:
            "Vi kunne ikke bruke forsikringsfordelene dine. Synkronisering av forsikringsplanen din vil automatisk bli opphevet for å kunne fortsette.",
          confirm: "Bekreft",
        },
        proceedAsIs: "Fortsett som det er",
        grayOutBannerMessage:
          "Noen alternativer kan være deaktivert, da de ikke er kompatible med brilleseddelverdiene dine.",
        yourSelections: "Dine valg",
        editButton: "Rediger",
        error: {
          title: "Noe gikk galt …",
          description:
            "Beklager, vi har tekniske problemer. Hvis du i mellomtiden har spørsmål eller trenger hjelp, ta gjerne <a href='#'>kontakt med vår kundeservice.</a>",
          buttonLabel: "Tilbake",
        },
        generic: {
          modal: {
            close: "",
          },
        },
      },
      sv_SE: {
        exitYes: "Ja, lämna",
        steps: {
          prescription: {
            description:
              "Eller så föreslår vi den rätta linsen för dina synbehov så att du slipper alla bekymmer",
          },
          mixAndMatch: {
            title: "VÄLJ FÄRG PÅ BÅGAR OCH LINSER",
            polarized: "Polarized",
          },
          advancedPrescription: {
            confirmedTitle: "Recept",
            title: "Har du ett recept?",
            manual: {
              commentsTitle: "Kommentarers",
              moreOptions: "Fler alternativ",
              manualPreviewTitle: "Je receptdetails",
              addPrismValues: "Lägg till prismavärden",
              pupillaryDistanceMisurePDAction: "Mät ditt PD",
              pupillaryDistanceSubtitle:
                "Du kan välja standardinställningarna <b>63</b> för bågar i vuxenstorlek och <b>54</b> för barnbågar.",
              pupillaryDistanceWarningValue:
                "Vänligen ange ditt pupillavstånd, om du inte har det kan du använda standarden ovan eller så kan du ",
              pupillaryDistanceWarningTooltip:
                "Ange text för detta verktygstips",
              title: "Mata in ditt glasögonrecept",
              card: {
                title: "Mata in det manuellt",
                description:
                  "Detta är vår snabbaste och mest populära metod. Vi rekommenderar sedan den lämpligaste linstypen.",
              },
              subtitle:
                "Lägg till dina receptvärden så kommer vi att rekommendera den bästa linsen för dina synbehov.",
              rightEye: {
                initials: "OD",
                name: "(Höger öga)",
              },
              leftEye: {
                initials: "OS",
                name: "(Vänster öga)",
              },
              digitalOptometry: {
                title: "Så mäter du ditt PD",
                appSubtitle:
                  "Ditt pupillavstånd, eller PD, är avståndet i millimeter (mm) från mitten av en pupill till mitten av den andra. Det indikerar exakt vilken del av linsen du tittar igenom, vilket säkerställer optimal komfort och klarhet. Du kan mäta med appen eller manuellt.",
                manuallySubtitle:
                  "Ditt pupillavstånd, eller PD, är avståndet i millimeter (mm) från mitten av en pupill till mitten av den andra. Du kan mäta med appen eller manuellt.",
                toggle: {
                  app: "med appen",
                  manually: "manuellt",
                },
                opthyDesc:
                  "<b>Få dina glasögon precis som du vill ha dem med Opthy.</b><br/><p>Opthy mäter ditt pupillavstånd, så att du kan hitta den mest bekväma passformen anpassad för just dig.</p><p>App tillgänglig på iPhone X och senare.</p>",
                manuallyContent:
                  "<div>Om du vill kan du mäta ditt PD själv genom att följa dessa steg:</div><ul><li>Ta en liten linjal i ena handen och ha penna och papper till hands.</li><li>Placera dig nu ungefär 20 cm från en spegel.</li><li>Blunda med vänster öga och rikta in nollan över mitten av din högra pupill.</li><li>Mät avståndet från höger till vänster pupill.</li><li>Siffran som ligger direkt över din vänstra pupill är ditt PD (ett genomsnittligt PD för en vuxen varierar mellan 58 och 65).</li></ul>",
              },
              seeDetails: {
                axis: "Axel",
                sphere: "SPH",
                cylinder: "CYL",
                add: "LÄGG TILL",
                pd: "PD",
                od: "OD",
                os: "OS",
                vertical: "Vertikalt prisma (Δ)",
                baseDirection: "Basriktning",
                horizontal: "Horisontellt prisma (Δ)",
              },
              howToReadModal: {
                subtitle:
                  "Om du har ett glasögonrecept, innehåller receptet vanligtvis följande information:",
                infoText:
                  "Om några av dessa uppgifter saknas i ditt recept, kan du lämna fältet tomt.",
                customerService:
                  "Undrar du över något? Ring våra kundservice-experter <a>XXXXXXXXXX</a>",
                rightEye: {
                  description:
                    'OD står för "oculus dexter", vilket betyder "höger öga" på latin.',
                },
                leftEye: {
                  description:
                    'OS står för "oculus sinister" vilket betyder "vänster öga" på latin.',
                },
                sphere: {
                  description:
                    "De har samma innebörd: styrkan på ditt recept. Om du är närsynt, anges ett minustecken (-) före värdena, och om du är översynt kommer där istället finnas ett plustecken (+).",
                },
                cylinder: {
                  description:
                    "Detta gäller personer med astigmatism. Det är när en del av ögat behöver korrigeras mer än övriga delar av det. Cylindervärdet anges med ett minustecken (-).",
                },
                axis: {
                  description:
                    "Detta gäller endast personer med astigmatism. Axeln är ett nummer mellan 0 och 180 och avgör cylinderns riktning (CYL).",
                },
              },
              sphere: "Sfär (SPH)",
              cylinder: "Cylinder (CYL)",
              axis: "Axel (AXIS)",
              add: "LÄGG TILL",
              vertical: "Vertikal (Δ)",
              baseDirection: "Basriktning",
              horizontal: "Horisontell (Δ)",
              pupillaryDistance: "PD (Pupillavstånd)",
              pdLeft: "Vänster",
              pdRight: "Höger",
              iHaveTwoPd: "Jag har två pd",
              applyButton: "Fortsätt",
              howToRead: "Så läser du ditt recept",
              whatIsIt: "Vad är det?",
              moreOptions: "Fler alternativ",
              incompatibleLenses:
                "Vi beklagar, men det recept du har angett är inte kompatibelt med våra linser som erbjuds online. Hitta en butik nära dig eller kontakta vår kundtjänst för mer information.",
              alerts: {
                pdValueWarningDigitalOptometrySmaller:
                  "Om valt PD är mindre än genomsnittet, rekommenderar vi att du dubbelkollar ditt recept. Om du inte har det till hands kan du lägga till ovanstående värden, eller så kan du",
                pdValueWarningDigitalOptometryLarger:
                  "Om valt PD är större än genomsnittet, rekommenderar vi att du dubbelkollar ditt recept. Om du inte har det till hands kan du lägga till ovanstående värden, eller så kan du",
                axisRangeError:
                  "Det verkar som att AXIS-värdena du angett är felaktiga. De ska vara mellan 1 och 180.",
                axisZeroError:
                  "Det verkar som att AXIS-värdena du angett är felaktiga. Om CYL-värdet är högre eller lägre än 0, kan AXIS-värdena inte vara 0.",
                missingValues: "Fyll i de värden som fattas",
                sphPositiveNegativeWarning:
                  "<b>Du har infogat 1 positivt och 1 negativt värde, vilket är mycket sällsynt.</b><br>Vi föreslår att du dubbelkollar mot ditt recept innan du fortsätter.",
                pdValueWarning:
                  "Valt PD ligger utanför det normala intervallet, vi föreslår att du dubbelkollar ditt recept. Du kan lämna standardinställningen 63 för vuxna eller 54 för barn. Om vi behöver mer information kommer en av våra experter att höra av sig.",
                pdValueWarning2:
                  "Valt PD är lägre än genomsnittet, vi föreslår att du dubbelkollar ditt recept. Om du inte har det kan du lägga till värdena ovan eller så kan du ",
                missingPrismValues: "Vänligen välj prismavärdet",
                requiredToProceed: "Detta krävs för att fortsätta",
                missingBaseDir: "Välj basriktningen för ditt prisma",
                incompatibleWithPrescriptionTitle:
                  "Tyvärr! Denna båge är inte lämplig för ditt recept.",
                incompatibleWithPrescriptionBody:
                  "men oroa dig inte, kompatibla bågar finns tillgängliga",
                incompatibleWithPackagesTitle:
                  "Tyvärr! Detta recept är inte kompatibelt med de linser som finns tillgängliga för denna båge.",
                shopCompatible: "Hitta rätt båge",
                clearAll: "Rensa alla",
                agreementText:
                  "Genom att klicka på den här rutan bekräftar jag att receptvärdena som anges ovan är hämtade från ett giltigt (ej utgånget) recept som utfärdats till mig, och som undertecknats av en legitimerad optiker eller ögonläkare.",
              },
              continueModal: {
                title: "Skicka recept senare",
                message:
                  "Genom att klicka på Fortsätt hoppar du över glasögonreceptsteget. Vi kommer att be om det efter din beställning, antingen genom att det laddas upp eller att vi ringer din optiker/ögonläkare.",
                continue: "Fortsätt",
                cancel: "Gå tillbaka",
                titleNoAdd: "ÄR DU SÄKER PÅ ATT DU VILL FORTSÄTTA?",
                messageNoAdd:
                  "Var noga med att ange tilläggsvärdet (ADD) om det finns i ditt recept, annars fortsätter du utan.",
                continueNoAdd: "FORTSÄTT UTAN (ADD)",
                message:
                  "Genom att klicka på fortsätt hoppar du över receptsteget. Vi kommer att be dig ladda upp det efter att du har slutfört din beställning.",
              },
              issueDate: {
                label: "Utfärdandedatum (MM/ÅÅÅÅ)",
                missingError: "Mata in datum",
                formatError: "Det inmatade datumet är inte giltigt",
                tooltipText:
                  "Kontrollera utgångsdatumet innan du skickar ditt glasögonrecept så att du vet att det fortfarande är aktuellt och giltigt. Vi godtar endast glasögonrecept som har utfärdats de senaste 2 åren. Genom att kryssa i rutan och fortsätta nedan godkänner du vår sekretesspolicy och allmänna villkor.",
              },
            },
            californian: {
              chooseTitle: "VÄLJ HUR DU VILL SKICKA IN DITT RECEPT",
              info: "Obs: om du är bosatt i Kalifornien måste du, när du lägger till dina receptvärden manuellt, även skicka in ett giltigt recept på elektronisk väg. Ladda upp receptet för att fortsätta.",
              uploadDesc:
                "Vi godkänner följande filformat: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) och IWork-sidor.",
              callMyDoc: "Ring min läkare",
              requiredLabel: "* Obligatoriskt fält",
            },
            fromMyAccount: {
              card: {
                title: "Få det från mitt konto",
                description: "Logga in och välj ditt glasögonrecept.",
              },
              review: {
                title: "Nedladdat från kontot",
              },
              title: "Välj ditt glasögonrecept",
              subtitle:
                "Välj önskat glasögonrecept så tar vi hand om resten. \n Kan du inte hitta det?",
              subtitleAdd: "Lägg till ett nytt glasögonrecept",
              prescriptionName: "Namn på glasögonrecept",
              uploadedOn: "Uppladdat på",
              selectButton: "Välj glasögonrecept",
              savePrescriptionInAccount: "Spara glasögonrecept på mitt konto",
              newPrescriptionNameError:
                "Fältet Namn på glasögonrecept måste vara ifyllt",
              olderThanYearBanner:
                "Detta glasögonrecept laddades upp för 1 år sedan. Kontrollera om det fortfarande är giltigt.",
              showPrescription: "visa glasögonrecept",
              prescriptionDetailModal: {
                title: "Ditt glasögonrecept",
                edit: "Redigera glasögonrecept",
              },
            },
            account: {
              card: {
                title: "Få det från mitt konto",
                description: "Logga in och välj ditt glasögonrecept.",
              },
              review: {
                title: "Laddas från konto",
              },
            },
            upload: {
              title: "Ditt glasögonrecept",
              subtitle:
                "Kontrollera utgångsdatumet innan du skickar ditt glasögonrecept så att du vet att det fortfarande är giltigt.",
              card: {
                title: "Ladda upp det nu",
                description:
                  "Vi tar hand om resten. Vi accepterar följande filformat: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max. 10 MB) och IWork-sidor",
              },
            },
            doctor: {
              title: "Lägg till din optikers/ögonläkares kontaktuppgifter",
              subtitle:
                "Vi hämtar dina receptuppgifter direkt från honom/henne",
              searchBy: "Sök efter din optiker/ögonläkare på",
              card: {
                title: "Ring min optiker/ögonläkare",
                description:
                  "Vi hämtar ditt glasögonrecept direkt från honom/henne",
              },
            },
            later: {
              card: {
                title: "Skicka det senare",
                description:
                  "Vi kommer att be om det efter din beställning, antingen genom att det laddas upp eller att vi ringer din optiker/ögonläkare.",
              },
            },
          },
          type: {
            confirmedTitle: "Använd",
            prescriptionTitle: "Eller",
            addToCart: "Lägg till i kundvagn",
            moreInfo: "Mer info",
          },
          treatmentsFamily: {
            confirmedTitle: "Linstyp:",
            title: "Välj din linstyp",
            blueLight: {
              title: "Blåljusfilter",
              description:
                "Minskar exponering för blått ljus från digitala skärmar vilket kan hjälpa till att förhindra ögontrötthet.",
              imageFileName: "",
            },
            transition: {
              title: "Transitions ® Signature ® GEN 8™",
              description:
                "Ett enda par för inomhus- och utomhusbruk med Transitions®-linser: Blir både mörka och klara snabbt så att du aldrig behöver byta glasögon.",
              imageFileName: "",
            },
            clear: {
              title: "Klara",
              description:
                "Traditionella genomskinliga linser för dagligt bruk",
              imageFileName: "",
            },
            sun: {
              title: "Sol",
              description: "Välj mellan olika linsfärger och färgtoner.",
              imageFileName: "",
            },
          },
          gvpTreatment: {
            subtitle: "Dina glasögon har förstklassig LensCrafters-teknik.",
            confirmedTitle: "Linspaket:",
          },
          thickness: {
            subtitle: "Dina glasögon har förstklassig LensCrafters-teknik.",
            designTypeTitle: "Välj din linsdesign",
          },
          treatments: {
            extraTreatments: {
              antiScratch: {
                name: "Reptålighet",
              },
            },
          },
          transitionColor: {
            title: "Övergångsfärg",
            indoor: "INOMHUS",
            outdoor: "UTOMHUS",
          },
          brand: {
            confirmedTitle: "Glasögonlinsens varumärke",
          },
          lensColor: {
            confirmedTitle: "Färg:",
            detailBox: {
              lightTransmission: "Ljusgenomsläpplighet",
              lightConditions: "Ljusförhållanden",
              contrast: "Kontrast",
              baseLensColor: "Baslinsfärg",
            },
            categories: {
              "Ugly category": {
                title: "Kategori Ugly",
              },
              "Cool category": {
                title: "Kategori Cool",
              },
            },
          },
          color: {
            confirmedTitle: "Färg:",
            title: "Välj din linsfärg",
            titleMobile: "Välj din linsfärg",
            titleDesignTypeLayout: "Välj din linsteknik",
            titleColorCategoryLayout: "Välj din linstyp",
            polarized: "Endast polariserande",
            applyAndContinue: "Applicera",
            subtitle: "Förbättrad färg och kontrast så att du kan se tydligare",
            tooltip: {
              switch:
                "Minskar bländning i extra starkt ljus för ökad klarhet och skydd av ögat.",
              polarized:
                "Minskar bländning i extra starkt ljus för ökad klarhet och skydd av ögat.",
              blueLight:
                "Minskar exponering för blått ljus från digitala skärmar och solens strålar vilket kan hjälpa till att förhindra ögontrötthet.",
            },
          },
          addOns: {
            confirmedTitle: "Slutför dina linser",
            label: "Ingår redan i din lins",
            title: "Slutför dina linser",
            reviewTitle: "Ytfinishar",
          },
          review: {
            title: "Granska dina val",
            discountTitle: "40 % rabatt dras automatiskt av på slipade linser",
            firstLineReview: "Båge:",
            insuranceButtonLabelRemove: "Ta bort försäkringsförmåner",
            oneYearCoverageAdd: "Lägg till",
          },
        },
        confirmButton: "Fortsätt",
        vmmv: {
          backButton: "Tillbaka till produkt",
        },
        prescription: {
          rightEye: "(Höger öga)",
          rightEyeInitials: "OD",
          leftEye: "(Vänster öga)",
          leftEyeInitials: "OS",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Pris för båge + linser",
            frame: "Pris för båge",
            total: "Total",
          },
          free: "Gratis",
          included: "Inklusive",
          frameLensStartingAt: "Båge + Linser hos",
          frameLens: "Båge + Linser",
        },
        noTreatment: "No treatment",
        exitTitle: "ÄR DET SÄKERT ATT DU VILL AVSLUTA?",
        exitSubtitle: "Ditt linsval kommer inte att sparas",
        exitYes: "Yes, exit",
        exitContinueEditing: "Fortsätt redigering",
        exitSave: "Spara och fortsätt handla",
        insuranceButtonLabelRemove: "Ta bort försäkringsförmåner",
        fallbackImageMessageTitle: "Förhandsvisning av linsfärg finns inte",
        fallbackImageMessageSubtitle:
          "Inga problem, du kommer att få din båge med den linsfärg du har valt.",
        proceedAsIs: "Fortsätt härifrån",
        editButton: "Redigera",
      },
      ja_JP: {
        steps: {
          prescription: {
            title: "処方箋を確定",
            description:
              "またはお客様が必要とする視界に適したレンズをお勧めし、迷わず選べます",
          },
          advancedPrescription: {
            manual: {
              rightEye: {
                initials: "OD",
                name: "（右眼）",
              },
              leftEye: {
                initials: "OS",
                name: "（左眼）",
              },
              axis: "乱視軸",
              add: "加入度数",
              vertical: "垂直 (Δ)",
              baseDirection: "基底方向",
              horizontal: "水平 (Δ)",
              pupillaryDistance: "PD （瞳孔間距離）",
              pdLeft: "左",
              pdRight: "右",
              iHaveTwoPd: "PD値が2種あります",
              applyButton: "続ける",
              howToRead: "処方箋の読み方",
              whatIsIt: "これは何でしょう？",
              pupillaryDistanceWarningTooltip: "このツールチップに文章を入れる",
              moreOptions: "他のオプション",
              incompatibleLenses:
                "申し訳ございません。ご入力いただいた処方箋は、オンラインでご提供しているレンズと互換性がありません。お近くの店舗をお探しいただくか、カスタマーサービスまでお問い合わせください。",
              incompatibleFrame:
                "ご入力いただいた度数では製作できません。恐れ入りますが、別のフレームをご検討下さい。",
              alerts: {
                axisRangeError:
                  "入力した乱視軸の値は不正確です。この値は1から180の間になるはずです。",
                axisZeroError: "入力エラー。乱視軸の数値が正しくありません。",
                missingValues: "未入力の値を入力してください",
                requiredToProceed: "※必須",
                incompatibleWithPrescriptionBody:
                  "ご心配には及びません、他にも適するフレームが揃っています。",
                shopCompatible: "適するフレームを購入",
                clearAll: "全て削除",
                pdValueWarning:
                  "ご入力いただいた数値は処方箋に即しておりますでしょうか？PD（瞳孔間距離）が適切でない場合、見えにくい原因の一つとなります。恐れ入りますが、今一度ご確認をお願いいたします。<br>※処方箋によっては欄外に記載されていることがあります<br>※処方箋に記載の無い場合は、必ずお客様ご自身で医療機関にお問い合わせください<br>※左右に分けて記載されている場合と、左右合わせた１つの数値が記載される場合があります。左右合わせての記載である場合、数値÷２の値を左右それぞれの欄にご入力ください",
              },
              issueDate: {
                label: "発行日 （月月/年年年年）",
                missingError: "日付を入力してください",
                formatError: "入力した日付は無効です",
                tooltipText:
                  "処方箋は医療機関発行かつ有効期限内であることをご確認下さい。",
              },
              continueModal: {
                titleNoAdd: "続行してもよろしいですか？",
                messageNoAdd:
                  "処方箋に加入度数(ADD)が記載されている場合は必ず入力してください。そうでない場合は入力せずにお進みください。",
                continueNoAdd: "（ADD）を記入せずに続ける",
              },
            },
            fromMyAccount: {
              card: {
                title: "マイアカウントから入手する",
                description: "ログインして処方せんを選択してください。",
              },
              review: {
                title: "アカウントからアップロード",
              },
              title: "ご自分の処方せんを選択",
              subtitle:
                "お好みの度付レンズを選べば、残りはすべてお任せください。\nが見つかりませんか？",
              subtitleAdd: "新しい処方せんを追加する",
              prescriptionName: "処方せん名",
              uploadedOn: "次のところにアップロード済み",
              selectButton: "処方せんを選択",
              savePrescriptionInAccount: "マイアカウントに処方せんを保存",
              newPrescriptionNameError: "処方せん名の欄は空にしないでください",
              olderThanYearBanner:
                "この処方せんは1年前にアップロードされました。まだ有効化を確認してください。",
              showPrescription: "処方せんを表示する",
              prescriptionDetailModal: {
                title: "お客様の処方せん",
                edit: "処方せんを編集する",
              },
            },
            account: {
              card: {
                title: "マイアカウントから入手する",
                description: "ログインして処方せんを選択してください。",
              },
              review: {
                title: "アカウントから読み込まれました",
              },
            },
            doctor: {
              title: "主治医の情報を入力してください",
              subtitle:
                "お医者様から直接処方せんを送信していただけるように手配します",
              searchBy: "眼科医の検索は",
              card: {
                title: "医師に電話する",
                description:
                  "お医者様から直接処方せんをいただけるように手配します。",
              },
            },
          },
          type: {
            title: "レンズの補正機能",
            prescriptionTitle: "または",
            addToCart: "カートに追加",
            moreInfo: "詳細情報",
          },
          brand: {
            title: "レンズのブランドを選択",
            confirmedTitle: "ブランド",
          },
          treatmentsFamily: {
            confirmedTitle: "レンズタイプ:",
            blueLight: {
              title: "ブルーライト・フィルター",
              description:
                "電子スクリーンが発するブルーライトの照射を抑え、眼精疲労を予防します。",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "屋内でも屋外でもTransitions-トランジション® レンズ装着なら1点で兼用： ダークからクリアに素早く変化し、アイグラスをかけ替える必要がありません。",
              imageFileName: "",
            },
            clear: {
              title: "クリア",
              description: "伝統的なトランスペアレントレンズで日常使いに最適",
              imageFileName: "",
            },
            sun: {
              title: "サン",
              description: "豊富なレンズカラーや色調から選択。",
              imageFileName: "",
            },
          },
          gvpTreatment: {
            title: "グレートバリューパッケージを確定",
            subtitle:
              "お客様のアイグラスは最高ランクのLensCrafters技術を使っています。",
            confirmedTitle: "レンズパッケージ：",
          },
          thickness: {
            confirmedTitle:
              "レンズの厚さ（組み合わせによっては、自動的に決まる場合もあります）",
            title: "レンズの厚さを選択",
            subtitle:
              "お客様のアイグラスは最高ランクのLensCrafters技術を使っています。",
          },
          treatments: {
            addToBag: "カートに追加",
            title: "レンズを確定",
            confirmedTitle: "加工",
            extraTreatments: {
              antiScratch: {
                name: "アンチスクラッチ",
              },
              antiReflective: {
                value: "Anti-reflective",
              },
            },
          },
          transitionColor: {
            title: "TransitionColor",
            indoor: "インドア",
            outdoor: "アウトドア",
          },
          lensColor: {
            detailBox: {
              contrast: "コントラスト",
              baseLensColor: "ベースレンズカラー",
            },
            categories: {
              "Ugly category": {
                title: "アグリーカテゴリー",
              },
              "Cool category": {
                title: "クールカテゴリー",
              },
            },
          },
          color: {
            title: "レンズカラーを選択",
            titleDesignTypeLayout: "レンズテクノロジーを選択",
            titleColorCategoryLayout: "ご自分のレンズタイプを選択",
            polarized: "偏光レンズのみ",
            applyAndContinue: "確定",
            subtitle: "色調やコントラストを強調し細部までくっきり見せる",
            tooltip: {
              antiReflective:
                "反射を抑え、目を保護します。眩しさ、ホコリ、汚れに強く、いつでもクリアな視界を実現します。",
              uvProtection:
                "レンズの表面・裏面に紫外線やキズを最大限に防ぐ硬質な保護レイヤーを採用しています。",
              switch:
                "光量が特に多い条件で眩光を抑え、一際くっきり見せ眼を守ります。",
              polarized:
                "光量が特に多い条件で眩光を抑え、一際くっきり見せ眼を守ります。",
              blueLight:
                "電子スクリーンが発するブルーライト照射の影響を抑えることで、眼精疲労を和らげます。",
            },
          },
          addOns: {
            confirmedTitle: "付属オプション",
            label: "レンズに元々付属しているのは",
            reviewTitle: "フィニッシュ",
          },
          review: {
            title: "選択中の内容",
            discountTitle: "度付レンズの40%オフは自動的に適応されます。",
            firstLineReview: "フレーム:",
            insuranceButtonLabel: "保険を適用する",
            insuranceButtonLabelRemove: "保険の恩恵を受けない",
            oneYearCoverageAdd: "追加する",
          },
        },
        confirmButton: "続ける",
        vmmv: {
          backButton: "製品に戻る",
        },
        prescription: {
          toggleLabel:
            "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
          prescriptionTitle:
            "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
          rightEye: "（右眼）",
          rightEyeInitials: "OD",
          leftEye: "（左眼）",
          leftEyeInitials: "OS",
          readPrescription: "How to read your prescription",
          addLabel: "I also have an ADD value",
          submitButton: "Submit",
          clearButton: "Clear all",
          skipButton: "Skip for now",
          compatibleFramesButton: "Shop compatible frames",
          doctorDetails:
            "We'll ask for a copy of your prescription or doctor's details during or after checkout",
          missingValueError: "Add your values to continue",
          positiveNegativeError:
            "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
          compatibleFrameError:
            "Sorry! The frame you've chosen isn't compatible with your prescription.",
          compatibleFrameSubLabel:
            "But don't worry, compatible frames are available.",
          axisError: "Remember to add your AXIS values",
          axisSubError: "These values must be between 0 and 180.",
          axisSubErrorZero:
            "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
          editLabel: "Edit prescription",
          pdHelp: "What is this?",
          twoPDNumbers: "I have two PD values",
          missingInputMessage:
            "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
          defaultInputMessage:
            "Choose to add your prescription now or upload it later.",
          skipMessage: "",
          readyMessage: "We are now able to suggest the right lens for you!",
          bestPrescription: "Best for your prescription",
          proceedCheckbox:
            "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
          proceedCheckboxError: "Required to proceed",
        },
        price: {
          totalFrameLens: {
            frame: "フレーム価格",
            total: "Total",
          },
          free: "Free",
          included: "付",
          frameLensStartingAt: "フレーム + レンズを",
          frameLens: "フレーム + レンズ",
          startingAt: "Starting at",
        },
        noTreatment: "No treatment",
        learnMoreMade: "製造方法を見る",
        learnMoreTreatment: "加工を見る",
        exitTitle: "本当に終了しますか？",
        exitSubtitle: "お客様が選択したレンズは保存されません",
        exitContinueEditing: "入力を続ける",
        exitSave: "保存してショッピングを続ける",
        insuranceButtonLabel: "Add insurance benefits",
        insuranceButtonLabelRemove: "保険の恩恵を受けない",
        insuranceSyncedLabel: "Insurance is synced",
        applyingInsurance: "保険給付を受ける",
        fallbackImageMessageTitle: "レンズカラーのプレビュー表示非対応",
        fallbackImageMessageSubtitle:
          "ご心配なく。選択したカラーのレンズを装着したフレームをお届けします。",
        proceedAsIs: "このままで続ける",
        yourSelections: "あなたの選択",
        editButton: "編集",
      },
      en_AU: {
        steps: {
          prescription: {
            title: "Add your prescription values",
            description:
              "Or we will suggest the right lens for your vision need, so you can go hussle free",
          },
          mixAndMatch: {
            title: "CHOOSE YOUR FRAME & LENS COLOUR",
            polarized: "Polarised",
          },
          advancedPrescription: {
            title: "Do you have a prescription?",
            manual: {
              rightEye: {
                name: "(Right eye)",
              },
              leftEye: {
                name: "(Left eye)",
              },
              alerts: {
                axisZeroError:
                  "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
                incompatibleWithPrescriptionBody:
                  "but don’t worry, compatible frames are available",
              },
              continueModal: {
                continue: "Yes, continue",
                cancel: "No, go back",
                titleNoAdd: "ARE YOU SURE YOU WANT TO CONTINUE?",
                messageNoAdd:
                  "Please make sure to enter the Addition (ADD) value if it is listed in your prescription, otherwise please proceed without.",
                continueNoAdd: "Continue without (add)",
              },
              issueDate: {
                label: "Date of issue",
                missingError: "Please insert date",
                formatError: "The date entered is not valid",
                tooltipText:
                  "Before submitting your prescription, please check the expiration date to make sure it is current and valid. We only accept prescriptions that have been issued within the last 2 years. By selecting the tick box and continuing below, you agree to our privacy policy and T&Cs.",
              },
            },
            account: {
              card: {
                description: "Log in and select your prescription.",
              },
            },
            upload: {
              card: {
                title: "Upload it now",
                description:
                  "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
              },
              review: {
                title: "Loaded from account",
              },
            },
            doctor: {
              title: "Add your doctor details",
              searchBy: "Search your eye doctor by",
              card: {
                title: "Call my doctor",
              },
            },
            fromMyAccount: {
              uploadedOn: "Uploaded on",
              prescriptionDetailModal: {
                title: "Your prescription",
              },
            },
          },
          type: {
            title: "Choose your vision need",
          },
          brand: {
            title: "Choose your lens brand",
            confirmedTitle: "Lens brand:",
          },
          treatmentsFamily: {
            sun: {
              description: "Choose from different lens colours and tints.",
            },
          },
          thickness: {
            confirmedTitle: "Thickness:",
            title: "Choose your lens thickness",
            designTypeTitle: "Choose your lens design",
          },
          treatments: {
            addToBag: "Add to bag",
            title: "Choose your light responsive treatment",
            extraTreatments: {
              antiScratch: {
                title: "Scratch resistant",
              },
              antiReflective: {
                value: "Anti-reflective",
              },
              uvProtection: {
                title: "UV Protection",
              },
            },
          },
          transitionColor: {
            title: "TransitionColor",
          },
          color: {
            confirmedTitle: "Colour:",
            title: "Choose your lens colour",
            subtitle: "Enhanced colour and contrast so you can see more detail",
            tooltip: {
              blueLight:
                "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
            },
          },
          warranty: {
            title: "LensCrafters Care Coverage",
            add: "Add ",
            oneYear: "one-year coverage",
            twoYear: "two-year coverage",
          },
          review: {
            title: "Review your selections",
            paymentInstallments: {
              installmentsLabel:
                "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
              modal: {
                klarna:
                  "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              },
            },
            insuranceButtonLabel: "Add insurance benefits",
            insuranceButtonLabelRemove: "Remove insurance benefits",
          },
        },
        prescription: {
          toggleLabel:
            "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
          prescriptionTitle:
            "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
          rightEye: "(Right eye)",
          leftEye: "(Left eye)",
          readPrescription: "How to read your prescription",
          addLabel: "I also have an ADD value",
          applyButton: "Apply & continue",
          submitButton: "Submit",
          clearButton: "Clear all",
          skipButton: "Skip for now",
          compatibleFramesButton: "Shop compatible frames",
          doctorDetails:
            "We'll ask for a copy of your prescription or doctor's details during or after checkout",
          missingValueError: "Add your values to continue",
          positiveNegativeError:
            "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
          compatibleFrameError:
            "Sorry! The frame you've chosen isn't compatible with your prescription.",
          compatibleFrameSubLabel:
            "But don't worry, compatible frames are available.",
          axisError: "Remember to add your AXIS values",
          axisSubError: "These values must be between 0 and 180.",
          axisSubErrorZero:
            "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
          editLabel: "Edit prescription",
          pdHelp: "What is this?",
          twoPDNumbers: "I have two PD values",
          missingInputMessage:
            "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
          defaultInputMessage:
            "Choose to add your prescription now or upload it later.",
          skipMessage: "",
          readyMessage: "We are now able to suggest the right lens for you!",
          bestPrescription: "Best for your prescription",
          proceedCheckboxError: "Required to proceed",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Frame + lenses price",
            frame: "Frame price",
          },
          free: "Free",
          included: "Included",
          frameLensStartingAt: "Frame + Lenses at",
          frameLens: "Frame + Lenses",
          startingAt: "Starting at",
        },
        learnMoreMade: "See how it's made",
        learnMoreTreatment: "See the treatment",
        exitTitle: "Are you sure you want to exit?",
        exitSubtitle: "Your lens selection will not be saved",
        exitContinueEditing: "No, continue",
        exitSave: "Save and continue shopping",
        insuranceButtonLabel: "Add insurance benefits",
        insuranceSyncedLabel: "Insurance is synced",
        applyingInsurance: "Applying insurance benefits",
        yourSelections: "Your selections",
      },
      es_US: {
        steps: {
          type: {
            title: "Selecciona tus necesidades de visión",
            confirmedTitle: "Uso",
          },
          brand: {
            confirmedTitle: "Lens brand:",
          },
          thickness: {
            title: "Selecciona el grosor de tus cristales",
            designTypeTitle: "SELECCIONE EL DISEÑO DE LAS LENTES",
          },
          gvpTreatment: {
            title: "Choose your Great Value Package",
          },
          treatments: {
            addToBag: "Agregar a la cesta",
            subtitle:
              "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          },
          color: {
            confirmedTitle: "Color:",
            tooltip: {
              polarized:
                "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
              switch:
                "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
              antiScratch:
                "Protege la superficie de las lentes de los impactos fuertes ",
            },
          },
          mixAndMatch: {
            title: "ELIGE EL COLOR DE LA MONTURA Y LOS CRISTALES",
          },
          review: {
            title: "REVISE SU CONFIGURACIÓN",
            subtitle:
              "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase.",
            paymentInstallments: {
              installmentLabel:
                "Or ###PAYMENT_TYPE### interest-free payments of ###MONTHLY_PRICE### with ",
              installmentsLabel:
                "Paga en cuotas sin intereses con\n###PAYMENT_TYPE###",
              modal: {
                title: "Pago a plazos",
                subtitle:
                  "¿Quieres separar el costo de tu pedido con ###BRAND###? \n Selecciona ###PAYMENT_TYPE### como tu método de pago al finalizar la compra \n para pagar en cuotas sin intereses, sin cargos ocultos.",
                affirm:
                  "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. ###PAYMENT_TYPE### ofrece las opciones de pago, están sujetas a una verificación de elegibilidad y es posible que no esté disponible en todos los estados. Residentes de California: Los préstamos de ###PAYMENT_TYPE### Loan Services, LLC se otorgan de conformidad con una licencia de prestamista de California.",
                afterpay:
                  "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. Debes ser mayor de 18 años, residente de los EE. UU. y cumplir con los criterios de elegibilidad adicionales para calificar. Se pueden aplicar cargos por pagos atrasados. Haz clic aquí para conocer los términos completos. Los préstamos otorgados a los residentes de California se rigen por una licencia de la Ley de prestamistas de California.",
                klarna:
                  "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              },
            },
            tooltip:
              "Asegura tus lentes con una garantía extendida para reparaciones y reemplazos ilimitados en cualquier tienda LensCrafters.",
          },
          advancedPrescription: {
            manual: {
              title: "Ingresa tu receta",
              card: {
                title: "Ingrésala de manera manual",
                description:
                  "Este es nuestro método más rápido y popular. A continuación, recomendaremos el tipo de mica más adecuado.",
              },
              subtitle:
                "Agrega tus valores de la receta y te recomendaremos las mejores micas para tus necesidades de visión.",
              incompatibleLenses:
                "Lo sentimos, la graduación que ha introducido no es compatible con nuestras lentes ofrecidas en línea. Busca una tienda cerca de ti o ponte en contacto con nuestro equipo de Atención al cliente para obtener más información.",
              alerts: {
                sphPositiveNegativeWarning:
                  "<b>Insertaste 1 valor positivo y 1 negativo, lo cual es muy poco común.</b><br>Te sugerimos revisar tu receta. Si es correcto, puedes continuar.",
                agreementText:
                  "Al hacer clic en esta casilla, confirmo que los valores de la receta ingresados anteriormente se toman de una receta válida (no vencida) que me fue emitida, firmada por un optometrista u oftalmólogo con licencia.",
              },
              continueModal: {
                title: "Envía la receta más tarde",
                message:
                  "Al hacer clic en continuar, te saltarás el paso de receta. La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
                cancel: "No, regresar",
                titleNoAdd: "¿SEGURO QUE DESEA CONTINUAR?",
                messageNoAdd:
                  "Asegúrese de introducir el valor ADD (Adición) si se incluye en su receta; de lo contrario continue sin este concepto.",
                continueNoAdd: "CONTINUAR SIN ADD",
              },
              samePrescriptionBothEyes:
                "Usar la misma graduación para ambos ojos.",
            },
            upload: {
              title: "Tu receta",
              subtitle:
                "Antes de enviar tu receta, verifica la fecha de vencimiento para asegurarte de que aún sea válida.",
              card: {
                title: "Cárgala ahora",
              },
            },
            later: {
              card: {
                description:
                  "La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
              },
            },
          },
          treatmentsFamily: {
            title: "Seleccione el tipo de lentes",
            subtitle:
              "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
            clear: {
              description:
                "Micas tradicionales y translúcidas, perfectas para el uso diario",
            },
          },
          addOns: {
            confirmedTitle: "Completa tus micas",
            title: "Completa tus micas",
            subtitle:
              "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
          },
        },
        confirmButton: "Continuar",
        vmmv: {
          backButton: "Volver al producto",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Precio del armazón + cristales",
          },
        },
        noTreatment: "No treatment",
        exitTitle: "'¿SEGURO QUE QUIERES SALIR?'",
      },
      de_AT: {
        steps: {
          review: {
            paymentInstallments: {
              installmentsLabel:
                "Ratenzahlung in zinslosen Raten mit ###INSTALLMENTS###",
              modal: {
                title: "Bezahlung in Raten",
                subtitle:
                  "Sie möchten Ihre Bestellung bei ###BRAND### in Raten zahlen? \\Wählen Sie an der Kasse ###INSTALLMENTS### als Zahlungsmethode aus, um in zinslosen Raten zu zahlen, ohne versteckte Gebühren.",
                affirm:
                  "Mit Affirm können Sie alle zwei Wochen 3 zinslose Raten zahlen, ohne versteckte Gebühren. Die Zahlungsoptionen werden von Affirm angeboten, unterliegen einer Berechtigungsprüfung und sind möglicherweise nicht in allen Ländern verfügbar. Kalifornien: Kredite von Affirm Loan Services, LLC werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt.",
                afterpay:
                  "Mit Afterpay können Sie alle zwei Wochen 4 zinslose Raten zahlen, ohne versteckte Gebühren. Sie müssen über 18 Jahre alt sein, einen Wohnsitz in den USA haben und weitere Kriterien erfüllen, um die Ratenzahlung Anspruch nehmen zu können. Es können Säumnisgebühren anfallen. Klicken Sie hier, um die vollständigen Bedingungen einzusehen. Kredite an in Kalifornien ansässige Personen werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt.",
              },
            },
            tooltip:
              "Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft.",
            seeDetails: "Details anzeigen",
          },
          thickness: {
            designTypeTitle: "Brillenglasdesign auswählen",
          },
          advancedPrescription: {
            manual: {
              title: "Gib dein Rezept ein",
              card: {
                description:
                  "Dies ist unsere schnellste und beliebteste Methode. Danach empfehlen wir dir den am besten geeigneten Glastyp.",
              },
              review: {
                title: "Manuell eingegeben",
              },
              subtitle:
                "Füge deine Rezeptwerte hinzu, und wir empfehlen dir das beste Glas für deine Sehbedürfnisse.",
              add: "ADD",
              iHaveTwoPd: "Ich habe 2 PD-Nummern",
              alerts: {
                sphPositiveNegativeWarning:
                  "<b>Sie haben 1 positiven und 1 negativen Wert eingegeben, was sehr selten ist.</b><br>Bitte überprüfen Sie Ihre Eingabe. Falls Ihre Eingabe richtig ist, fahren Sie fort.",
                missingValues: "Bitte geben Sie die noch fehlenden Daten ein",
                pdValueWarning:
                  "Die gewählte PD liegt außerhalb des normalen Bereichs, bitte überprüfe dein Rezept. Du kannst die Standardeinstellung von 63 beibehalten, da dies der durchschnittliche Wert für Erwachsene ist. Wenn wir weitere Informationen zu deinem Rezept benötigen, wird sich einer unserer Experten mit dir in Verbindung setzen.",
              },
              continueModal: {
                title: "Rezept später versenden",
                cancel: "NEIN, ZURÜCKGEHEN",
              },
              digitalOptometry: {
                title: "SO MISST DU DEINE PD",
                appSubtitle:
                  "Deine Pupillendistanz (PD) ist der Abstand in Millimetern (mm) von der Mitte der einen Pupille zur Mitte der anderen.",
              },
            },
            upload: {
              title: "Ihr Brillenpass",
              subtitle:
                "Bevor du dein Rezept einreichst, überprüfe bitte das Ablaufdatum, um sicherzustellen, dass es noch gültig ist.",
              prescriptionUploaded: "Rezept hochgeladen",
              fileTooBigErrorTitle: "Datei ist zu groß",
              fileTooBigErrorDescription:
                "Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB",
              changeMethod: "Übermittlungsart ändern",
              uploadDifferentFile: "Eine andere Datei hochladen",
              pdForm: {
                pupillaryDistance: "PD (Pupillendistanz)",
                whatIsIt: "Was ist das?",
                iHaveTwoPd: "Ich habe 2 PD-Nummern",
              },
              confirmAndContinue: "Bestätigen & Fortfahren",
            },
            later: {
              card: {
                description:
                  "Wir bitten dich nach deiner Bestellung darum. Du kannst es entweder hochladen oder wir kontaktieren deinen Arzt.",
              },
              review: {
                title: "Wir fragen nach dem Bezahlvorgang danach",
              },
            },
          },
          color: {
            tooltip: {
              antiReflective:
                "Reduzieren Spiegelungen und dienen als unsichtbarer Schutz. Sie sind blendfrei, staub- und schmutzabweisend und sorgen für klare Sicht.",
              uvProtection:
                "Harte Schutzschichten auf Vorder- und Rückseite der Gläser für maximalen Schutz vor UV-Strahlung und Kratzern.",
            },
          },
        },
        frameSize: "Frame size",
        fallbackImageMessageTitle: "Vorschau der Glasfarbe nicht verfügbar",
        fallbackImageMessageSubtitle:
          "Keine Sorge, du erhältst dein Modell mit der von dir gewählten Glasfarbe.",
      },
      pt_BR: {
        steps: {
          prescription: {
            title: "Adicione os valores de sua receita",
            description:
              "Ou nós sugeriremos as lentes certas para sua necessidade de visão, assim você não tem de se preocupar com nada",
          },
          advancedPrescription: {
            title: "Você tem uma receita?",
            confirmedTitle: "Óculos com grau",
            manual: {
              commentsTitle: "Comentários",
              moreOptions: "Mais opções",
              pupillaryDistanceMisurePDAction: "Medir sua DP",
              pupillaryDistanceSubtitle:
                "Você pode selecionar as configurações padrão de <b>63</b> para armações adultas e <b>54</b> para infantis.",
              pupillaryDistanceWarningValue:
                "Insira sua distância pupilar. Se você não tiver, pode usar o padrão acima ou pode ",
              incompatibleLenses:
                "Lamentamos, mas a prescrição que você inseriu não é compatível com nossas lentes oferecidas on-line. Encontre uma loja perto de você ou entre em contato com nossa equipe de Atendimento ao Cliente para obter mais informações.",
              review: {
                title: "Inserido manualmente",
              },
              manualPreviewTitle: "Detalhes da sua receita",
              digitalOptometry: {
                title: "Como medir sua DP",
                appSubtitle:
                  "Sua Distância Pupilar, ou DP, é a distância em milímetros (mm) do centro de uma pupila ao centro da outra. Ela indica exatamente por qual parte da lente você olha, garantindo conforto e nitidez ideais. Você pode medir com o aplicativo ou manualmente.",
                manuallySubtitle:
                  "Sua Distância Pupilar, ou DP, é a distância em milímetros (mm) do centro de uma pupila ao centro da outra. Você pode medir com o aplicativo ou manualmente.",
                toggle: {
                  app: "com o aplicativo",
                  manually: "manualmente",
                },
                opthyDesc:
                  "<b>Tenha óculos perfeitos com o Opthy.</b><br/><p>O Opthy mede sua Distância Pupilar, permitindo encontrar o ajuste mais confortável e personalizado.</p><p>Aplicativo disponível a partir do iPhone X.</p>",
                manuallyContent:
                  "<div>Se você quiser, pode medir sua DP por conta própria. Siga estes passos:</div><ul><li>Segure uma pequena régua com uma das mãos e tenha lápis e papel à mão.</li><li >Agora posicione-se a aproximadamente 20 cm de um espelho.</li><li>Feche o olho esquerdo e alinhe o 0 acima do centro da pupila direita.</li><li>Meça a distância da pupila direita à esquerda.</li><li>O número alinhado diretamente acima da pupila esquerda é sua DP (a DP média para um adulto varia entre 58 e 65).</li></ul>",
              },
              addPrismValues: "Handmatig ingevoerd",
              title: "Inserir sua receita",
              card: {
                title: "Inserir manualmente",
                description:
                  "Este é o método mais rápido e popular. Depois vamos sugerir o melhor tipo de lente para seu caso.",
              },
              subtitle:
                "Insira os dados de sua receita para recomendarmos a melhor lente para suas necessidades visuais.",
              rightEye: {
                initials: "OD",
                name: "(Olho direito)",
              },
              leftEye: {
                initials: "OS",
                name: "(Olho esquerdo)",
              },
              seeDetails: {
                axis: "Axis/Eixo",
                sphere: "SPH/ESF",
                cylinder: "CYL/CIL",
                add: "ADD/AD",
                pd: "DP",
                od: "OD",
                os: "OE",
                vertical: "Prisma Vertical (Δ)",
                baseDirection: "Direção da base",
                horizontal: "Prisma Horizontal (Δ)",
              },
              howToReadModal: {
                subtitle:
                  "Caso tenha uma receita para óculos, as informações da receita normalmente incluirão o seguinte:",
                infoText:
                  "Caso essa informação não esteja incluída na sua receita, poderá deixar esse campo em branco.",
                customerService:
                  "Alguma dúvida? Contate nosso Serviço de Atendimento ao Cliente <a>XXXXXXXXXX</a>",
                rightEye: {
                  description:
                    'OD significa "oculus dexter" que, em latim, significa "olho direito".',
                },
                leftEye: {
                  description:
                    'OS significa "oculus sinister", que significa "olho esquerdo" em latim.',
                },
                sphere: {
                  description:
                    "Eles significam a mesma coisa: a força de sua prescrição. Caso seja míope, terá um sinal de menos (-) antes de seus valores e, caso seja hipermétrope, terá um sinal de mais (+).",
                },
                cylinder: {
                  description:
                    "Isso é para pessoas com astigmatismo. Isso ocorre quando uma parte do olho precisa de mais correção do que o restante. O valor do Cilindro é escrito com um sinal de menos (-).",
                },
                axis: {
                  description:
                    "Isso é apenas para pessoas com astigmatismo. O eixo é um número entre 0 e 180 e determina a orientação do Cilindro (CYL).",
                },
              },
              sphere: "Esfera (ESF/SPH)",
              cylinder: "Cilindro (CIL/CYL)",
              axis: "Axis/Eixo",
              add: "ADD/AD",
              vertical: "Vertical (Δ)",
              baseDirection: "Direção base",
              horizontal: "Horizontal (Δ)",
              pupillaryDistance: "DP (Distância pupilar)",
              pdLeft: "Esquerdo",
              pdRight: "Direito",
              iHaveTwoPd: "Tenho duas dp",
              applyButton: "Continuar",
              howToRead: "Como ler sua receita",
              whatIsIt: "O que é?",
              pupillaryDistanceWarningTooltip:
                "Forneça o texto para esta descrição",
              moreOptions: "Mais opções",
              alerts: {
                pdValueWarningDigitalOptometrySmaller:
                  "A DP selecionada é menor que a média. Sugerimos que verifique novamente sua receita. Caso não a possua, poderá adicionar os valores escritos acima ou poderá",
                pdValueWarningDigitalOptometryLarger:
                  "A DP selecionada é maior que a média. Sugerimos que verifique novamente sua receita. Caso não a possua, poderá adicionar os valores escritos acima ou poderá",
                axisRangeError:
                  "Os valores do AXIS/Eixo que você inseriu parecem estar incorretos. Eles devem estar entre 1 e 180.",
                axisZeroError:
                  "Os valores do AXIS/Eixo que você inseriu parecem estar incorretos. Se o valor CYL/CIL for maior ou menor que 0, os valores AXIS/Eixo não podem ser 0.",
                missingValues: "Completar os valores que faltam",
                sphPositiveNegativeWarning:
                  "<b>Você inseriu 1 valor positivo e 1 valor negativo, o que é muito raro.</b><br>Sugerimos verificar novamente sua receita antes de continuar.",
                pdValueWarning:
                  "A DP selecionada está fora da faixa normal. Sugerimos verificar novamente sua receita. Você pode deixar a configuração padrão de 63 para adultos ou 54 para crianças. Se precisarmos de mais informações, um de nossos especialistas entrará em contato.",
                pdValueWarning2:
                  "A DP selecionada é menor que a média. Sugerimos verificar novamente sua receita. Se você não tiver, pode adicionar os valores escritos acima ou pode ",
                missingPrismValues: "Escolher o valor do prisma",
                requiredToProceed: "Isso é necessário para continuar",
                missingBaseDir: "Escolher a direção da base para seu prisma",
                incompatibleWithPrescriptionTitle:
                  "Desculpe! Esta armação não é adequada para sua receita.",
                incompatibleWithPrescriptionBody:
                  "Mas não se preocupe, há armações compatíveis disponíveis",
                incompatibleWithPackagesTitle:
                  "Desculpe! Este grau não é compatível com as lentes disponíveis para esta armação.",
                shopCompatible: "Encontrar as armações certas",
                clearAll: "Limpar tudo",
                agreementText:
                  "Ao clicar nesta caixa, confirmo que os valores da receita inseridos acima são de uma receita válida (não vencida) emitida para mim, assinada por um optometrista ou oftalmologista licenciado.",
              },
              continueModal: {
                title: "Enviar receita mais tarde",
                message:
                  "Clicando em continuar, irá ignorar o passo da receita. Pediremos para carregar a receita após sua encomenda ou chamaremos seu médico.",
                continue: "Continuar",
                cancel: "Voltar",
                titleNoAdd: "TEM CERTEZA DE QUE DESEJA CONTINUAR?",
                messageNoAdd:
                  "Não se esqueça de inserir o valor de Adição (AD ou ADD) se ele estiver presente na sua receita; caso contrário, continue sem ele.",
                continueNoAdd: "CONTINUAR SEM (AD ou ADD)",
                message:
                  "Ao clicar em continuar, você estará pulando a etapa da receita. Solicitaremos que você a envie após concluir o pedido.",
              },
              issueDate: {
                tooltipText:
                  "Antes de enviar sua receita, verifique a data de validade para garantir que ela ainda esteja atualizada e válida. Só aceitamos prescrições que tenham sido emitidas nos últimos 2 anos. Ao marcar a caixa e continuar, você concorda com nossa política de privacidade e termos de uso.",
              },
              manualPreviewTitle: "Detalhes de sua receita",
              seeDetails: {
                sphere: "Esférico",
                cylinder: "Cilíndrico",
                axis: "Eixo",
                add: "ADICIONAR",
                pd: "DP",
                od: "OD",
                os: "OE",
                vertical: "Prisma Vertical (Δ)",
                baseDirection: "Direção base",
                horizontal: "Prisma Horizontal (Δ)",
              },
            },
            californian: {
              chooseTitle: "ESCOLHA COMO ENVIAR SUA RECEITA",
              info: "Note que, ao adicionar valores da receita manualmente, os residentes da Califórnia também precisam enviar eletronicamente sua receita válida. Carregue a receita para continuar.",
              uploadDesc:
                "Aceitamos os seguintes formatos de arquivo: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (máx 10 MB) e páginas IWork.",
              callMyDoc: "Contatar me médico",
              requiredLabel: "* Campo obrigatório",
            },
            account: {
              card: {
                title: "Obter de minha conta",
                description: "Faça login e selecione sua receita.",
              },
              review: {
                title: "Carregado da conta",
              },
            },
            upload: {
              title: "Sua receita",
              subtitle:
                "Antes de submeter sua receita, verifique a data de vencimento para verificar se ainda é válida.",
              card: {
                title: "Carregue agora",
                description:
                  "E nós cuidaremos do resto. Aceitamos os seguintes formatos de arquivo: ###FILE_FORMATS### (máx. ###FILE_SIZE### MB) e páginas IWork",
              },
              review: {
                title: "CARREGADA",
              },
              uploadDifferentFile: "Carregar arquivo diferente",
              changeMethod: "Alterar método de envio",
              prescriptionUploaded: "Receita Carregada",
              somethingWentWrong: "Desculpe, algo deu errado.",
              fileTooBigErrorTitle: "O arquivo é muito grande",
              fileTooBigErrorDescription:
                "O tamanho de seu arquivo é muito grande. Carregamento máximo ###FILE_SIZE### MB",
              tryAgain: "Por favor, tente novamente",
              upload: "Carregar",
              pdForm: {
                pupillaryDistance: "DP (Distância pupilar)",
                whatIsIt: "O que é isso?",
                pdValueWarning:
                  "A DP selecionada está fora do intervalo normal, sugerimos que verifique novamente sua receita. Pode deixar a predefinição de 63, que é a medição média para adultos.\nSe precisarmos de mais informações sobre sua receita, um de nossos especialistas estará em contato consigo. ",
                pdLeft: "Esquerda",
                pdRight: "Direita",
                iHaveTwoPd: "Eu tenho 2 números de DP",
              },
              confirmAndContinue: "Confirmar e continuar",
              filePreviewTitle: "Sua receita",
              alerts: {
                agreementText:
                  "Confirmo que os valores de prescrição inseridos são de uma prescrição válida (não vencida) emitida para mim, dentro de um período de 1 ano e assinada por um médico oftalmologista licenciado.",
              },
            },
            doctor: {
              title: "Adicione os dados de seu médico",
              subtitle:
                "Tem de ser o mesmo médico que emitiu e assinou sua receita. Vamos chamar para verificar seus detalhes.",
              searchBy: "Procure seu oftalmologista por",
              card: {
                title: "Chamar meu médico",
                description: "Vamos obter sua receita diretamente do médico/a.",
              },
              addNewDoctorModal: {
                title: "Adicionar médico novo",
                clinicOrDoctorName: "Nome do médico ou da clínica",
                phoneNumber: "Número de telefone",
                addDoctor: "Adicionar médico",
              },
              slider: {
                phone: "Número de telefone",
                clinicOrDoctorName: "Nome do médico ou da clínica",
              },
              table: {
                doctorName: "Nome do médico",
                clinicName: "Nome da clínica",
                address: "Endereço",
                chooseButton: "Selecionar",
                phone: "Telefone",
                fax: "Fax",
                phoneNumber: "Número de telefone",
                changeButton: "Mudar",
                wrongDoctor: "Médico errado?",
              },
              pdForm: {
                pupillaryDistance: "DP (Distância pupilar)",
                whatIsIt: "O que é isso?",
                pdValueWarning:
                  "A DP selecionada está fora do intervalo normal, sugerimos que verifique novamente sua receita. Pode deixar a predefinição de 63, que é a medição média para adultos.\n Se precisarmos de mais informações sobre sua receita, um de nossos especialistas estará em contato consigo.",
                pdLeft: "Esquerda",
                pdRight: "Direita",
                iHaveTwoPd: "Eu tenho 2 números de DP",
              },
              addManuallyOption: {
                didntFind: "Não encontrou seu médico?",
                addManually: "Adicione manualmente",
              },
              missingInfoError: "Preencha as informações ausentes",
              doctorNotFoundBoxMessage: "Não encontramos seu médico",
              doctorNotFoundBoxMessageAdd: "Adicione mais detalhes ou ",
              doctorNotFoundBoxMessageLink: "adicione um médico novo.",
              phoneNumber: "Número de telefone do médico",
              search: "Buscar",
              clinicOrDoctorName: "Nome do médico ou da clínica",
              city: "Cidade",
              state: "Estado",
              changeSubmissionMethod: "Alterar método de envio",
              confirmAndContinue: "Confirmar e continuar",
              selectedDoctorPageTitle: "Os dados de seu médico",
              doctorPreviewTitle: "Chamaremos seu médico",
            },
            later: {
              card: {
                title: "Enviar mais tarde",
                description:
                  "Pediremos para carregar após sua encomenda ou chamaremos seu médico.",
              },
              review: {
                title:
                  "Pediremos para carregar após o procedimento de pagamento",
              },
            },
            californian: {
              toggle: "É residente na Califórnia?",
              info: "Observe que, para além de adicionar manualmente os valores da receita, os residentes na Califórnia também são obrigados a transmitir eletronicamente sua receita válida. Escolha uma opção para continuar.",
              chooseTitle: "ESCOLHA COMO ENVIAR SUA RECEITA",
              uploadTitle: "Carregar",
              uploadDesc:
                "Aceitamos os seguintes formatos de arquivo: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (máx. 10 MB) e páginas IWork.",
              uploadButton: "Navegar",
              callMyDoc: "Chamar meu médico",
              callMyDocButton: "ENCONTRE SEU MÉDICO",
              yes: "SIM",
              no: "NÃO",
              modalMissingPrescription: {
                title: "Valores de receita ausentes",
                subtitle:
                  "Se não preencher os valores, não poderemos sugerir as lentes certas para você",
                bottonYes: "CONTINUAR",
                bottonNo: "VOLTAR",
              },
            },
          },
          type: {
            title: "Selecione sua necessidade de visão",
            confirmedTitle: "Necessidade de visão:",
            prescriptionTitle: "Ou",
            addToCart: "Adicione ao carrinho",
            moreInfo: "Mais informações",
          },
          brand: {
            title: "Escolha a marca de sua lente",
            confirmedTitle: "Marca da lente:",
          },
          treatmentsFamily: {
            confirmedTitle: "Tipo de lente:",
            title: "Escolha seu tipo de lente",
            blueLight: {
              title: "Filtro de luz azul",
              description:
                "Reduz a exposição à luz azul emitida pelas telas digitais, assim ajuda a conter a fadiga ocular.",
              imageFileName: "",
            },
            transition: {
              title: "Transition ® Signature ® GEN 8™",
              description:
                "Uns óculos para ambientes internos e o ar livre com lentes Transitions®: escurecem e clareiam rapidamente, assim não tem de mudar de óculos.",
              imageFileName: "",
            },
            clear: {
              title: "Transparentes",
              description:
                "Lentes tradicionais, transparentes perfeitas para uso diário",
              imageFileName: "",
            },
            sun: {
              title: "Sol",
              description:
                "Escolha entre cores e nuances de lentes diferentes.",
              imageFileName: "",
            },
          },
          gvpTreatment: {
            title: "Escolha seu Pacote de Grande Valor",
            subtitle: "Seus óculos vêm com a melhor tecnologia LensCrafters.",
            confirmedTitle: "Embalagem de Lentes:",
          },
          thickness: {
            confirmedTitle: "Espessura:",
            title: "Escolha a espessura de suas lentes",
            subtitle: "Seus óculos vêm com a melhor tecnologia LensCrafters.",
            designTypeTitle: "Escolha o design de sua lente",
          },
          treatments: {
            addToBag: "Adicione ao carrinho",
            title: "Escolha o tratamento de ajuste à luz",
            confirmedTitle: "Tratamento:",
            extraTreatments: {
              antiScratch: {
                name: "Antirrisco",
                title: "Resistente a riscos",
              },
              antiReflective: {
                value: "Antirreflexo",
              },
              uvProtection: {
                title: "Proteção UV",
              },
            },
          },
          transitionColor: {
            title: "TransitionColor",
            indoor: "Interno",
            outdoor: "Ar livre",
          },
          lensColor: {
            confirmedTitle: "Cor:",
            detailBox: {
              lightTransmission: "Transmissão da luz",
              lightConditions: "Condições de iluminação",
              contrast: "Contraste",
              baseLensColor: "Cor da lente base",
            },
            categories: {
              "Ugly category": {
                title: "Categoria feio",
              },
              "Cool category": {
                title: "Categoria legal",
              },
            },
          },
          color: {
            confirmedTitle: "Cor:",
            title: "Escolha a cor de suas lentes",
            titleMobile: "Escolha a cor de suas lentes",
            titleDesignTypeLayout: "Escolha a tecnologia de suas lentes",
            titleColorCategoryLayout: "Escolha seu tipo de lente",
            polarized: "Apenas as polarizadas",
            applyAndContinue: "Aplicar",
            subtitle:
              "Cores e contraste incrementados, para que você possa ver com maiores detalhes",
            tooltip: {
              switch:
                "Reduz o reflexo com luz muito intensa, para uma excelente nitidez e proteção dos olhos.",
              polarized:
                "Reduz o reflexo com luz muito intensa, para uma excelente nitidez e proteção dos olhos.",
              antiReflective:
                "Reduz o reflexo e fornece um escudo invisível. É resistente a reflexo, poeira e manchas para uma visão panorâmica mais clara.",
              uvProtection:
                "Camadas de proteção rígidas à frente e atrás das lentes para máxima proteção contra raios UV e riscos.",
              antiScratch:
                "Protege a superfície de suas lentes de fortes impactos",
              blueLight:
                "Reduz a exposição à luz azul emitida pelas telas digitais e raios do sol, e assim ajuda a conter a fadiga ocular",
            },
          },
          addOns: {
            confirmedTitle: "Acabamentos:",
            label: "Já incluídos em suas lentes",
            title: "Complete suas lentes",
            reviewTitle: "Acabamentos:",
          },
          review: {
            title: "Confira sua seleção",
            discountTitle:
              "40% de desconto em lentes de grau aplicado automaticamente",
            firstLineReview: "Armação:",
            paymentInstallments: {
              installmentsLabel:
                "Pagar ao longo do tempo em parcelas sem juros com ###PAYMENT_TYPE###",
              modal: {
                title: "Compre agora. Pague mais tarde.",
                subtitle:
                  "Selecione ###PAYMENT_TYPE### como seu meio de pagamento no check-out para pagar em parcelas sem juros, sem taxas ocultas.",
                affirm:
                  "Com ###PAYMENT_TYPE###, pague em ###INSTALLMENTS### parcelas sem juros de ###MONTHLY_PRICE###",
                afterpay:
                  "Com ###PAYMENT_TYPE###, pague em ###INSTALLMENTS### parcelas sem juros de ###MONTHLY_PRICE###",
                klarna:
                  "Com ###PAYMENT_TYPE###, pague em ###INSTALLMENTS### parcelas sem juros de ###MONTHLY_PRICE###",
                footer:
                  "Você deve ser maior de 18 anos, residente nos Estados Unidos, e satisfazer critérios de elegibilidade adicionais para estar qualificado. Podem ser aplicadas taxas posteriores. Os empréstimos concedidos ou acordados aos residentes da Califórnia estão de acordo com uma licença da Lei de Empréstimos Financeiros da Califórnia.",
                exitButton: "Entendi!",
              },
            },
            insuranceButtonLabel: "Adicionar benefícios de seguro",
            insuranceButtonLabelRemove: "Adicionar benefícios de seguro",
            oneYearCoverageAdd: "Adicionar",
            tooltip:
              "Assegure seus óculos com uma garantia estendida para conserto e substituição ilimitados em qualquer loja LensCrafters.",
            seeDetails: "Ver os detalhes",
          },
        },
        confirmButton: "Continuar",
        vmmv: {
          backButton: "Voltar para o produto",
        },
        prescription: {
          toggleLabel:
            "Adicione os valores de sua receita e nós o ajudaremos a encontrar a lente perfeita para suas necessidades de visão.",
          prescriptionTitle:
            "Adicione os valores de sua receita e nós lhe mostraremos a lente perfeita para suas necessidades de visão.",
          rightEye: "(Olho direito)",
          rightEyeInitials: "OD",
          leftEye: "(Olho esquerdo)",
          leftEyeInitials: "OE",
          readPrescription: "Como ler sua receita",
          addLabel: "Também tenho um valor de adição",
          applyButton: "Aplicar e continuar",
          submitButton: "Submeter",
          clearButton: "Limpar tudo",
          skipButton: "Pular por enquanto",
          compatibleFramesButton: "Compre armações compatíveis",
          doctorDetails:
            "Pediremos uma cópia de sua receita ou os dados do médico durante ou após o check-out",
          missingValueError: "Adicione seus valores para continuar",
          positiveNegativeError:
            "Você inseriu um valor positivo e um valor negativo, o que é bastante raro. Sugerimos que verifique novamente sua receita.",
          compatibleFrameError:
            "Ops! A armação que escolheu não é compatível com sua receita.",
          compatibleFrameSubLabel:
            "Mas não se preocupe, estão disponíveis armações compatíveis.",
          axisError: "Lembre-se de adicionar seus valores de EIXO",
          axisSubError: "Estes valores devem estar entre 0 e 180.",
          axisSubErrorZero:
            "Se o valor de cilíndrico for maior ou menor de 0, os valores de EIXO não podem ser 0.",
          editLabel: "Editar receita",
          pdHelp: "O que é isso?",
          twoPDNumbers: "Eu tenho dois valores de DP",
          missingInputMessage:
            "Adicione os valores ausentes para completar o carregamento de sua receita e recomendaremos a lente mais adequada.",
          defaultInputMessage:
            "Escolha para adicionar sua receita agora ou carregar mais tarde.",
          skipMessage: "",
          readyMessage: "Agora podemos sugerir a lente certa para você!",
          bestPrescription: "A melhor para sua receita",
          proceedCheckbox:
            "Clicando nessa caixa, confirmo que os valores de receita digitados acima são retirados de uma receita escrita não expirada emitida para mim, assinada por um optometrista ou oftalmologista licenciado.",
          proceedCheckboxError: "Necessário para continuar",
        },
        price: {
          totalFrameLens: {
            frameLensTotal: "Preço da armação + lentes",
            frame: "Preço da armação",
            total: "Total",
          },
          free: "Gratuito",
          included: "Incluído",
          frameLensStartingAt: "Armação + Lentes por",
          frameLens: "Armação + Lentes",
          startingAt: "A partir de",
          vatIncluded: "Imposto incluído",
          discountBadgePercentage: "###VALUE######SYMBOL### OFF",
          discountBadgeAmount: "###VALUE######SYMBOL### OFF",
        },
        noTreatment: "Nenhum tratamento",
        learnMoreMade: "Veja como é feito",
        learnMoreTreatment: "Veja o tratamento",
        exitTitle: "Tem certeza que deseja sair?",
        exitSubtitle: "Sua seleção de lentes não será salva",
        exitYes: "Sim, sair",
        exitContinueEditing: "Não, continuar",
        exitSave: "Salve e continue comprando",
        insuranceButtonLabel: "Adicionar benefícios de seguro",
        insuranceLabelRemove: "Remover benefícios",
        insuranceButtonLabelRemove: "Remover benefícios de seguro",
        insuranceSyncedLabel: "O seguro está sincronizado",
        applyingInsurance: "Aplicando benefícios de seguro",
        frameSize: "Tamanho armação",
        fallbackImageMessageTitle:
          "A pré-visualização da cor da lente não está disponível",
        fallbackImageMessageSubtitle:
          "Não se preocupe, receberá sua armação com a cor da lente que escolheu.",
        insuranceErrorModal: {
          title: "Algo deu errado...",
          content:
            "Não foi possível aplicar seus benefícios de seguro. Seu plano de seguro será automaticamente não sincronizado a fim de continuar.",
          confirm: "Confirmar",
        },
        grayOutBannerMessage:
          "Algumas opções podem ser desativadas por não serem compatíveis com seus valores de receita.",
        proceedAsIs: "Continuar assim como está",
        yourSelections: "Suas seleções",
        editButton: "Modificar",
        error: {
          title: "Algo deu errado...",
          description:
            "Desculpe, estamos com algumas dificuldades técnicas. Enquanto isso, se você tiver alguma dúvida ou precisar de assistência, entre em <a href='#'>contato com nosso serviço de atendimento.</a>",
          buttonLabel: "Voltar",
        },
      },
    },
  };
}
